import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, List, Button, Typography, Popconfirm, Tabs, Form, Alert, Select, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { api } from 'api';
import { getErrorMessage } from 'helpers/feedbackMessageHelper';

const { Paragraph, Text } = Typography;

const matchTypes = [
    {
        'name': 'League',
        'value': 'league'
    },
    {
        'name': 'Cup',
        'value': 'cup'
    },
    {
        'name': 'Friendly',
        'value': 'friendly'
    }
];

const weekdays = [
    {
        'name': 'Sunday',
        'value': 0
    },
    {
        'name': 'Monday',
        'value': 1
    },
    {
        'name': 'Tuesday',
        'value': 2
    },
    {
        'name': 'Wednesday',
        'value': 3
    },
    {
        'name': 'Thursday',
        'value': 4
    },
    {
        'name': 'Friday',
        'value': 5
    },
    {
        'name': 'Saturday',
        'value': 6
    }
];

const MatchImportRules = ({isVisible, handleClose}) => {

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    const [activeTabKey, setActiveTabKey] = useState(1);

    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);

    const [chosenMatchType, setChosenMatchType] = useState("League");
    const [chosenWeekday, setChosenWeekday] = useState("Saturday");

    const [existingMatchImportRules, setExistingMatchImportRules] = useState([]);

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const onMatchTypeChange = (value) => {
        setChosenMatchType(capitalizeFirstLetter(value));
    }

    const onWeekDayChange = (value) => {
        setChosenWeekday(capitalizeFirstLetter(value));
    }

    const changeActiveTabKey = (tabKey) => {
        setActiveTabKey(tabKey);
    }

    const getMatchImportRules = async () => {
        try {
            const matchImportRules = await api.admin.getMatchImportRules();
            setExistingMatchImportRules(matchImportRules.data);
        } catch (error) {
            console.log('ERROR_420', error);
        }
    }

    const submitValues = async () => {
        form
            .validateFields()
            .then( async (values) => {

                setSubmitting(true);

                const { weekday, matchType } = values;

                try {
                    const response = await api.admin.addImportMatchRule({
                        weekday, 
                        matchType
                    });

                    const importRule = response.data;

                    form.resetFields();

                    const existingMatchImportRulesToEdit = [...existingMatchImportRules];
                    existingMatchImportRulesToEdit.push(importRule);
                    setExistingMatchImportRules(existingMatchImportRulesToEdit);

                    message.success("Import Rule successfully added");
                } catch (error) {
                    console.log('ERROR_58');
                    const errorMessage = error.response ? error.response.data.error : 'generic';
                    message.error(getErrorMessage(errorMessage));
                }

                setSubmitting(false);
                
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    const handleDeleteImportRule = async (matchImportRuleId) => {
        setSubmitting(true);

        try {
            await api.admin.deleteMatchImportRule({
                matchImportRuleId: matchImportRuleId
            });

            const existingMatchImportRulesToEdit = [...existingMatchImportRules];
            const index = existingMatchImportRulesToEdit.findIndex(item => item.id === matchImportRuleId);

            if (index > -1) {
                existingMatchImportRulesToEdit.splice(index, 1);
                setExistingMatchImportRules(existingMatchImportRulesToEdit);
            }
    
            message.success("Import Rule successfully removed");
        } catch (error) {
            console.log('ERROR_59');
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setSubmitting(false);
    }

    useEffect(() => {
        getMatchImportRules();
    }, []);

    return (
        <>
            <Modal 
                title="Existing Match Import Rules"
                open={isVisible}
                onCancel={handleClose}
                footer={activeTabKey === '2' && [
                    <Button
                        key="cancel"
                        disabled={submitting}
                        onClick={handleClose}
                        >
                        Cancel
                    </Button>,
                    <Button 
                        key="submit" 
                        type="primary" 
                        loading={submitting} 
                        onClick={submitValues}
                        >
                        Submit
                    </Button>
                ]}
                >

                <Tabs 
                    defaultActiveKey="1" 
                    onChange={changeActiveTabKey} 
                    items={[
                        {
                            key: '1',
                            label: 'Existing Match Import Rules',
                            children: <>
                                <Paragraph>Below is a list of all existing import rules. The system will not import any fixtures where these rules apply.</Paragraph>

                                <List
                                    dataSource={existingMatchImportRules}
                                    renderItem={item => (
                                        <List.Item 
                                            actions={[<Popconfirm 
                                                title="Are you sure you want to delete this rule?"
                                                okText="Yes" 
                                                cancelText="No" 
                                                onConfirm={() => handleDeleteImportRule(item.id)}
                                                >
                                                    <Button 
                                                        size="small" 
                                                        type="primary"
                                                        shape="circle" 
                                                        icon={<DeleteOutlined />}
                                                        danger
                                                        disabled={submitting}
                                                        />
                                                </Popconfirm>
                                            ]}>
                                            Do not import <Text underline>{item.matchType}</Text> matches played on a <Text underline>{item.weekday}</Text>
                                        </List.Item>
                                    )}
                                    />
                            </>,
                        },
                        {
                            key: '2',
                            label: 'Add New Import Rule',
                            children: 
                                <Form 
                                    {...layout} 
                                    initialValues={{ matchType: chosenMatchType, weekday: chosenWeekday }}
                                    form={form}
                                    >

                                    <Form.Item name="matchType" rules={[{ required: true, message: 'Please select a match type' }]} label="Match Type">
                                        <Select 
                                            placeholder="Match type" 
                                            disabled={submitting}
                                            onChange={onMatchTypeChange}
                                            >

                                            {matchTypes.map((matchType) => (
                                                <Select.Option 
                                                    key={matchType.value}
                                                    value={matchType.name}
                                                    >
                                                    {matchType.name}
                                                </Select.Option>
                                            ))}
                                            
                                        </Select>
                                    </Form.Item>

                                    <Form.Item name="weekday" rules={[{ required: true, message: 'Please select a week day' }]} label="Weekday">
                                        <Select 
                                            placeholder="Weekday" 
                                            disabled={submitting}
                                            onChange={onWeekDayChange}
                                            >

                                            {weekdays.map((weekday) => (
                                                <Select.Option 
                                                    key={weekday.value}
                                                    value={weekday.name}
                                                    >
                                                    {weekday.name}
                                                </Select.Option>
                                            ))}

                                        </Select>
                                    </Form.Item>

                                    <Alert 
                                        showIcon 
                                        message={`In future, do not import any ${chosenMatchType} matches played on a ${chosenWeekday}`} type="info" />
                                    
                                </Form>
                        }
                    ]}
                    >
                    
                
                </Tabs>
            </Modal>
        </>
    )
}

MatchImportRules.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default MatchImportRules