import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Row, Modal, Table,List } from 'antd';


import { CreateTeamContext } from './../../CreateTeam/index';
import { PlayerInfo } from 'components';

import useAdmin from "hooks/useAdmin";

const { Paragraph  } = Typography;

const ConfirmModal = ({ 
    isVisible, 
    handleCancel, 
    isLoading, 
    handleConfirm, 
    additional, 
    transferPenalty 
}) => {

    const { state } = useContext(CreateTeamContext);

    const { adminData } = useAdmin();

    const columns = [
        {
            title: state.requestedTransfers.length > 1 ? 'Players In' : 'Player In',
            dataIndex: 'playerIn',
            key: 'playerIn',
            render: (text, record) => { return (
                <>
                    <List.Item key={record.playerIn.id}>
                        <PlayerInfo 
                            key={record.playerIn.id} 
                            player={record.playerIn} 
                            showAvatar={adminData.sportIsCricket()}
                            />
                    </List.Item>
                </>
          )}
        },
        {
            title: state.requestedTransfers.length > 1 ? 'Players Out' : 'Player Out' ,
            dataIndex: 'playerOut', 
            key: 'playerOut',
            render: (text, record) => { return (
                <>
                    {record.playerOut.id && 
                        <List.Item key={record.playerOut.id}>
                            <PlayerInfo
                                key={record.playerOut.id}
                                player={record.playerOut}
                                showAvatar={adminData.sportIsCricket()}
                                />
                        </List.Item>
                    }
                </>
            )}
        }
    ];

    return (
        <Modal 
            title="Confirm Transfers" 
            open={isVisible} 
            onCancel={handleCancel}
            footer={
                <Row type='flex' justify='space-between' align='middle'>
                    {additional}
                    <Button 
                        key="submit" 
                        type="primary" 
                        loading={isLoading} 
                        onClick={handleConfirm}
                        >
                            {`Confirm (${transferPenalty()} points)`}
                    </Button>
                </Row>
            }
            >
            <Paragraph>You are about to make the following transfers:</Paragraph>
            <Table 
                rowKey={record => record.id} 
                dataSource={[...state.requestedTransfers]} 
                columns={columns} 
                pagination={false} 
                />
        </Modal>
    )
}

ConfirmModal.defaultProps = {
    isVisible: false,
    isLoading: false
}

ConfirmModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    handleConfirm: PropTypes.func.isRequired,
    additional: PropTypes.node,
    transferPenalty: PropTypes.func
}

export default ConfirmModal;