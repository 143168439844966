import axios from 'axios';
import Cookies from 'js-cookie';
import { buildEndpoint } from 'helpers/apiHelper';

const getRules = async () => {
    return axios.get(buildEndpoint('points/structure'));
}

const login = async (email, password) => {
    return axios.post(buildEndpoint('user/login'), { email, password })
}

const forgotPassword = async (email) => {
    return axios.patch(buildEndpoint('user/forgot-password'), { email })
}

const resetPassword = async (userId, newPassword, newPasswordHash) => {
    return axios.post(buildEndpoint('user/reset-password'), { userId, newPassword, newPasswordHash })
}

const getUser = async (userId) => {

    const endpoint = buildEndpoint(`user/${userId}`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.get(endpoint, { headers: headers });
}

const updateUserDetails = async (userId, values) => {

    const endpoint = buildEndpoint(`user/${userId}`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.post(endpoint, values, { headers: headers });
}

const createAccount = async ({ email, name, password, ecbOptIn}) => {

    return axios.post(
        buildEndpoint('user/register'),
        {
            email, 
            name, 
            password, 
            ecbOptIn
        }
    );
}

const deleteAccount = async (userId) => {
    const endpoint = buildEndpoint(`user/${userId}`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.delete(endpoint, { headers: headers });
}

export {
    getRules,
    login,
    forgotPassword,
    resetPassword,
    getUser,
    createAccount,
    updateUserDetails,
    deleteAccount
}