import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, Form, Input, message, Button } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";

import { getErrorMessage } from 'helpers/feedbackMessageHelper';
import { PageTitle } from 'components';
import { api } from 'api';

import { useChangePageTitle } from 'utils/useChangePageTitle';

const { Paragraph } = Typography;

const ResetPassword = () => {

    const changePageTitle = useChangePageTitle();

    let params = useParams();

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values) => {

        setLoading(true);

        try {
            await api.user.resetPassword(
                params.userId, 
                values.password, 
                params.newPasswordHash
            );
            message.success("Password successfully reset.");
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setLoading(false);
    }

    useEffect(() => {
        changePageTitle('Reset Password');
    }, []);

    return (
        <>
            <PageTitle title="Reset Password" />

            <Paragraph>
                Enter a new password and you'll be ready to go
            </Paragraph>

            <Form onFinish={(values) => handleSubmit(values)}>
                <Row type="flex">
                    <Col xs={24}>
                        <Form.Item name="password" rules={[{required: true, message: 'Please input a password'}]}>
                            <Input.Password
                                placeholder='Password'
                                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                disabled={loading}
                                />
                        </Form.Item>
                    </Col>
                </Row>
                <Row type="flex" justify='end'>
                    <Button loading={loading} type="primary" htmlType="submit">Submit</Button>
                </Row>
            </Form>
        </>
    )
}

export default ResetPassword;