import React, { useContext } from 'react';
import { Row, Col, Select } from 'antd';

import { CreateTeamContext } from '../../CreateTeam';
import { reducerActions } from '../reducer';

const { Option } = Select;

const ChooseCaptain = () => {

    const { state, dispatch } = useContext(CreateTeamContext);

    const handleChange = (value) => {
        dispatch({
            type: reducerActions.chooseCaptain, 
            payload: value
        });
    }

    return (
        <Row type="flex" align="middle">
            <Col xs={24}>
                <Select 
                    style={{ width: '100%' }} 
                    placeholder="Select a captain"
                    onChange={handleChange}
                    status={!state.captain ? "error" : "none"}
                    value={state.captain}
                    >
                    {state.selectedPlayers.map((player) => 
                        <Option value={player.id} key={player.id}>{player.name}</Option>
                    )}
                </Select>
            </Col>
        </Row>
    )
}

export default ChooseCaptain;