import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, Table, Radio, message, Button as AntButton, Popover, Space, Tooltip, theme } from 'antd';
import { CalendarOutlined, TrophyOutlined, InfoCircleOutlined, SearchOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";

import useAdmin from "hooks/useAdmin";
import useScreenSize from "hooks/useScreenSize";

import { Stat, Loading, PageTitle, PlayerValueSummary } from 'components';
import { api } from 'api';
import { getErrorMessage } from 'helpers/feedbackMessageHelper';
import { useChangePageTitle } from 'utils/useChangePageTitle';

import StrategyManager from 'pages/Admin/strategies/StrategyManager';

const { Title, Text, Link } = Typography;
const { Button, Group } = Radio;

const { useToken } = theme;

const Player = () => {

    let params = useParams();
    const changePageTitle = useChangePageTitle();
    const [{ isMobile }] = useScreenSize();

    const { adminData } = useAdmin();
    const { token } = useToken();

    const [year, setYear] = useState("current");
    const [archivePlayerStandingYears, setArchivePlayerStandingYears] = useState([]);

    const [playerData, setPlayerData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tableIsUpdating, setTableIsUpdating] = useState(false);
    const [columns, setColumns] = useState([]);
    const [playerGameweekData, setPlayerGameweekData] = useState([]);
    const [tableIsSticky, setTableIsSticky] = useState(false);

    const strategyManager = new StrategyManager();
    const strategyMatchData = strategyManager.getMatchDataStrategy(adminData.getSport(), adminData.hasAutoUpdate());

    const getArchivePlayerStandingYears = async () => {
        try {
            const results = await api.player.getArchivePlayerStandingYears(params.playerId);
            setArchivePlayerStandingYears(results.data);
        } catch (error) {
            console.log(error);
        }
    }

    const getPlayer = async () => {
        try {
            const response = await api.player.getPlayer(params.playerId);
            const data = await response.json();

            setPlayerData(data);
            changePageTitle(`Player: ${data.name}`);

            let columns = [
                {
                    title: '',
                    dataIndex: 'matchInfo',
                    key: 'match',
                    responsive: ['sm'],
                    render: (_, record) => {
                        if (! record.matchInfo) {
                            return;
                        }

                        let matchTitle = null;
                        if (record.matchInfo?.homeClub && record.matchInfo?.awayClub) {
                            matchTitle = `${record.matchInfo?.homeClub} vs ${record.matchInfo?.awayClub}`;
                        }

                        const matchDate = record.matchInfo?.matchDate ? new Date(record.matchInfo.matchDate) : null;
                        return (
                            <Popover 
                                placement="topLeft" 
                                title={matchTitle}
                                content={
                                    <Space direction="vertical" size={1}>
                                        <Text><CalendarOutlined style={{ marginRight: 8}} />{matchDate ? matchDate.toDateString() : 'Unknown'}</Text>
                                        <Text><TrophyOutlined style={{ marginRight: 8}} />{record.matchInfo.matchType}</Text>
                                        {record.matchInfo.matchPcId && (
                                            <>
                                                
                                                <Link
                                                    href={`https://${adminData.getPcSubdomain()}.play-cricket.com/website/results/${record.matchInfo.matchPcId}`}
                                                    target="_blank"
                                                    >
                                                    <SearchOutlined style={{ marginRight: 8}} />
                                                    View on play-cricket
                                                </Link>
                                            </>
                                        )}
                                    </Space>
                                } 
                                trigger="click"
                                >
                                <AntButton type="text" size="small" icon={<InfoCircleOutlined />} />
                            </Popover>
                        )
                    }
                },
                {
                    title: 'Gameweek',
                    dataIndex: 'gameweek',
                    key: 'gameweek'
                },
            ];

            strategyMatchData.getFieldsForUserDisplay().forEach(item => {

                const columnItem = {
                    title: item.label, // Not Out
                    dataIndex: item.name, // notOut
                    key: item.name
                };

                if (! adminData.sportIsCricket() && item.type === 'checkbox') {
                    columnItem.render = (item) => ( 
                        !!item ? 
                            <CheckOutlined style={{ color: token.colorSuccess }} /> :
                            <CloseOutlined style={{ color: token.colorError }} /> 
                        );
                }

                if (item.name === 'runs') {
                    columnItem.render = (runs, data)  => {
                        if (!!data.dnb) {
                            return 'dnb';
                        }
                        const notOut = !!data.notOut ? '*' : '';
                        return runs + notOut;
                    }
                }

                columns = [...columns, columnItem];
            });

            // add Total column
            columns = [
                ...columns,
                {
                    title: 'Total',
                    dataIndex: 'total',
                    key: 'total',
                }
            ];

            setColumns(columns);
            setPlayerGameweekData(data.gameweekData);
            setTableIsSticky(data.gameweekData.length > 0);
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }
        setLoading(false);
    };

    const changeYear = async (e) => {
        setTableIsUpdating(true);
        setPlayerGameweekData([]);
        setYear(e.target.value);

        try {
            const response = await api.player.getArchivePlayerGameweekData(params.playerId, e.target.value);

            setPlayerGameweekData(response.data);
            setTableIsSticky(response.data.length > 0);
            setTableIsUpdating(false);
        } catch (error) {
            message.error(getErrorMessage('generic'));
            setTableIsUpdating(false);
        }
    }

    useEffect(() => {
        getPlayer();
        getArchivePlayerStandingYears();
    }, []);

    const calculateSummary = (matchData) => {
        const fields = strategyMatchData.getFieldsForUserDisplay().map(item => {
            return { ...item, ...{ total: 0} }
        });
        
        matchData.forEach((match) => {

            fields.forEach(field => {
                let runningTotal = field.total;

                let matchTotalForField = match[field.name];

                if (field.type === 'checkbox') {
                    matchTotalForField = !! matchTotalForField ? 1 : 0;
                }

                field['total'] = runningTotal + matchTotalForField;
            });
        });
        
        return (
            <>
                <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={isMobile ? 1 : 2} index={0}><Text strong>Totals</Text></Table.Summary.Cell>

                    {fields.map((field, index) => {
                        return (
                            <Table.Summary.Cell key={field.name} index={index}>
                                <Text strong>{field.total}</Text>
                            </Table.Summary.Cell>
                        )
                    })}

                    <Table.Summary.Cell index={8}>
                        <Text strong>{playerData.totalPoints}</Text>
                    </Table.Summary.Cell>
                </Table.Summary.Row>
            </>
        );
    }

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <PageTitle 
                icon={adminData.sportIsCricket() ? playerData.positionId : null}
                title={playerData.name} 
                extra={[
                    <Title 
                        key="playerValueSummary"
                        style={{ margin: 0 }}
                        level={4}
                        >
                            {`${adminData.currency()}${playerData.adjustedValue}m`}
                            <PlayerValueSummary 
                                adjustedValue={playerData.adjustedValue}
                                value={playerData.value}
                                />
                    </Title>
                ]}
                />

            <Table 
                key={tableIsSticky}
                loading={tableIsUpdating}
                rowKey={record => record.id}
                columns={columns} 
                dataSource={playerGameweekData} 
                size="middle" 
                pagination={false} 
                scroll={{ x: true }}
                summary={calculateSummary}
                />

                {archivePlayerStandingYears.length > 0 && (
                    <Row justify="end">
                        <Col>
                            <Typography.Text>Previous Years: </Typography.Text>
                            <Group 
                                size="small" 
                                onChange={changeYear} 
                                defaultValue="current"
                                style={{ marginTop: 24 }}
                                >
                                <Button value="current">Current</Button>
                                {archivePlayerStandingYears.map(year => {
                                    return <Button key={year} value={year}>{year}</Button>
                                })}
                            </Group>
                        </Col>
                    </Row>
                )}

            {year === 'current' &&
                <Row type="flex" style={{ marginTop: 24 }}>
                    <Col xs={24} md={12} lg={6}>
                        <Stat type="blank" title="TOTAL POINTS" value={playerData.totalPoints} />
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <Stat type="blank" title="WEEK POINTS" value={playerData.weekPoints} />
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <Stat type="blank" title="SELECTED BY" value={`${playerData.selectedBy}%`} />
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <Stat type="blank" value={playerData.formAverage} title={
                            <Tooltip title="Value is calculuated as the average points scored in the previous (max 5) matches player has appeared in.">
                                <Typography.Text underline>WEEKLY FORM</Typography.Text>
                            </Tooltip>
                        } />
                    </Col>
                </Row>
            }
        </>
    )
}

export default Player;