import React from 'react';
import { Row, Spin } from 'antd';

const Loading = () => {
    return (
        <Row type="flex" justify="center" align="middle" style={{padding: '24px 0'}}>
            <Spin />
        </Row>
    )
}

export default Loading;