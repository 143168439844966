import React, { useState, useContext } from 'react';
import { Row, Col, Button, Select, Modal, Typography, message } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

import { reducerActions } from './reducer';
import { TeamContext } from './';
import { getErrorMessage } from 'helpers/feedbackMessageHelper';
import { api } from 'api';
import styles from './styles';

import useAdmin from "hooks/useAdmin";
import useScreenSize from "hooks/useScreenSize";

const { Option } = Select;
const { Text } = Typography;

const MyTeamBar = ({ teamId, gameweek }) => {

    const [modal, contextHolder] = Modal.useModal();

    const { state, dispatch } = useContext(TeamContext);

    const [wildcardIsUpdating, setWildcardIsUpdating] = useState(false);
    const [tripleCaptainIsUpdating, setTripleCaptainIsUpdating] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const { adminData } = useAdmin();
    const [{ isTablet, isMobile }] = useScreenSize();

    const nextGameweek = adminData.getGameweek() +1;

    const activateTripleCaptain = () => {
        modal.confirm({
            title: 'Confirm Triple Captain',
            content: `Are you sure you want to activate triple captain points for gameweek ${nextGameweek}?`,
            onOk: async () => {
                setTripleCaptainIsUpdating(true);

                try {
                    await api.team.activateTripleCaptain(teamId, nextGameweek);

                    message.success('Triple captain active!');

                    dispatch({
                        type: reducerActions.setTripleCaptainGameweek, 
                        payload: nextGameweek
                    });
                } catch (error) {
                    const errorMessage = error.response ? error.response.data.error : 'generic';
                    message.error(getErrorMessage(errorMessage));
                }

                setTripleCaptainIsUpdating(false);
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    }

    const activateWildcard = () => {

        if (adminData.seasonHasNotStarted()) {
            modal.error({
                title: 'Activate Wildcard',
                content: 'You cannot play your Wildcard until the season has started.'
            });
            return;
        }

        modal.confirm({
            title: 'Activate Wildcard',
            content: 'Are you sure you want to activate your wildcard?',
            onOk: async () => {
                setWildcardIsUpdating(true);

                try {
                    await api.team.setWildcardGameweek(teamId, nextGameweek);

                    message.success('Wildcard active!');
                    dispatch({
                        type: reducerActions.setWildcardGameweek, 
                        payload: nextGameweek
                    });


                } catch(error) {
                    const errorMessage = error.response ? error.response.data.error : 'generic';
                    message.error(getErrorMessage(errorMessage));
                }

                setWildcardIsUpdating(false);
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    }

    const changeCaptain = async (newCaptain) => {

        setIsUpdating(true);

        try {
            await api.team.changeCaptain(teamId, newCaptain);
            message.success('Captain successfully changed');

            // reflect change in the table ONLY if we're looking at nextGameweek
            if (gameweek === nextGameweek) {
                dispatch({
                    type: reducerActions.setNextCaptain, 
                    payload: newCaptain
                });
            }
            
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setIsUpdating(false);
    }

    return (
        <div style={{marginTop: 20}}>
            {contextHolder}
            <Row type="flex" justify="space-between" align="middle" wrap={isMobile || isTablet}>
                <Col style={styles.myTeamCTA(isTablet, isMobile)}>

                    {state.teamData.wildcardGameweek > 0 ? (
                        <Button 
                            ghost 
                            disabled 
                            style={styles.myTeamCTAButton(isTablet, isMobile)}
                            block={isTablet}
                            >
                            Wildcard activated for GW {state.teamData.wildcardGameweek}
                        </Button>
                    ) : (
                        <Button 
                            type="primary" 
                            ghost={wildcardIsUpdating} 
                            loading={wildcardIsUpdating} 
                            onClick={activateWildcard} 
                            style={styles.myTeamCTAButton(isTablet, isMobile)}
                            block={isTablet}
                            disabled={adminData.seasonHasNotStarted()}
                            >
                            Activate Wildcard
                        </Button>
                    )}

                    {state.teamData.tripleCaptainGameweek > 0 ? (
                        <Button 
                            ghost 
                            disabled 
                            style={styles.myTeamCTAButton(isTablet, isMobile)}
                            block={isTablet}
                            >
                            Triple captain activated for GW {state.teamData.tripleCaptainGameweek}
                        </Button>
                    ) : (
                        <Button 
                            type="primary" 
                            ghost={tripleCaptainIsUpdating} 
                            loading={tripleCaptainIsUpdating} 
                            onClick={activateTripleCaptain} 
                            style={styles.myTeamCTAButton(isTablet, isMobile)}
                            block={isTablet}
                            >
                            Play Triple Captain
                        </Button>
                    )}

                </Col>
                <Col style={styles.myTeamCTA(isTablet)}>
                    <Text>Captain: </Text>
                    <Select 
                        style={styles.captainSelect(isTablet)} 
                        defaultValue={state.nextCaptain}
                        onChange={changeCaptain}
                        loading={isUpdating}
                        disabled={!adminData.canEdit()}
                        >
                        {state.nextPlayers.map(player => 
                            <Option value={player.id} key={player.id}>{player.name}</Option>
                        )}
                    </Select>
                </Col>
            </Row>
            <Row>
                <Text type="warning" style={{marginTop: 16}}><WarningOutlined /> Deadline: {adminData.transferDeadline()}</Text>
            </Row>
        </div>
    )
}

export default MyTeamBar;