import React, { useContext } from 'react';
import { Alert, Typography } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';

import { ManualImportMatchContext } from '../../ManualImportMatch';

const { Text } = Typography;

const MatchInfoSummary = () => {

    const { state } = useContext(ManualImportMatchContext);

    return (
        <Alert
            message={<>
                <Text strong underline>{state.matchInfo.homeTeam}</Text> <Text>vs</Text> <Text strong underline>{state.matchInfo.awayTeam}</Text>
            </>}
            description={<><CalendarOutlined style={{ fontSize: 16 }} /> {state.matchInfo.matchDate.format('ddd D MMM YYYY')} | Gameweek {state.matchInfo.gameweek}</>}
            type="info"
            showIcon
            />
    )
}

export default MatchInfoSummary;