import React, { useState, useEffect } from 'react';
import { Row, Form, Input, message, Button, Divider, Result, Alert, Typography, Switch, Dropdown, Modal } from 'antd';
import { MailOutlined, UserOutlined, TeamOutlined, MoreOutlined, ExclamationCircleFilled } from '@ant-design/icons';

import { getErrorMessage } from 'helpers/feedbackMessageHelper';
import { PageTitle } from 'components';
import { api } from 'api';

import useScreenSize from "hooks/useScreenSize";
import useUser from "hooks/useUser";
import useAdmin from "hooks/useAdmin";

import { useChangePageTitle } from 'utils/useChangePageTitle';

const { Item } = Form;
const { Paragraph} = Typography;

const MyAccount = () => {

    const [modal, contextHolder] = Modal.useModal();
    const [{ isMobile }] = useScreenSize();

    const [isLoading, setIsLoading] = useState(false);
    const [resetPasswordIsLoading, setResetPasswordIsLoading] = useState(false);

    const changePageTitle = useChangePageTitle();
    const { userData, mutateUser, logout, loggedOut } = useUser();
    const { adminData } = useAdmin();

    const handleEditUserData = async (values) => {

        setIsLoading(true);

        try {
            await api.user.updateUserDetails(userData.id, values);

            if (values.teamName) {
                await api.team.changeTeamName(userData.teamId, values.teamName);
            }

            mutateUser({ ...userData, ...values });

            message.success("User details successfully updated.");
        } catch (error) {
            console.error("error", error);
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setIsLoading(false);
    }

    const handleForgotPassword = async () => {
        setResetPasswordIsLoading(true);

        try {
            await api.user.forgotPassword(userData.email);
            message.success('Instructions for creating a new password has been sent.', 6);
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setResetPasswordIsLoading(false);
    }

    const handleECBChange = async (checked, event) => {
        setIsLoading(true);
        try {
            await api.user.updateUserDetails(userData.id, { ecbOptIn: checked });

            mutateUser({ ...userData, ecbOptIn: checked });

            message.success("User preferences successfully updated.");
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }
        setIsLoading(false);
    }

    const handleDeleteAccount = async () => {
        setIsLoading(true);
        try {
            await api.user.deleteAccount(userData.id);

            logout();

            message.success("User account deleted.");
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }
        setIsLoading(false);
    }

    const menuItems = [
        {
            key: 'close-account',
            label: "Close Account",
            onClick: () => handleCloseAccount(),
            danger: true
        }
    ];

    const handleCloseAccount = async () => {
        modal.confirm({
            title: 'Are you sure you want to close your account?',
            content: 'This will permanently delete your team and user account.',
            icon: <ExclamationCircleFilled />,
            onOk: () => handleDeleteAccount(),
            okType: 'danger'
        });
    }

    useEffect( () => {
        changePageTitle('My Account');
    }, []);

    if (loggedOut) {
        return (
            <Result 
                title="Unauthorised Access"
                subTitle="You must be logged in to view this page" 
                status="warning" 
                showIcon 
            />
        )
    }

    return (
        <>
            {contextHolder}
            <PageTitle title="My Account" extra={[
                <Button 
                    key="resetPassword"
                    loading={resetPasswordIsLoading}
                    type="primary" 
                    size="small" 
                    disabled={resetPasswordIsLoading} 
                    onClick={handleForgotPassword}
                    >
                    Reset Password
                </Button>,
                <Dropdown
                    key="moreItems"
                    placement="bottomRight"
                    trigger="click"
                    menu={{ items: menuItems }}
                    >
                    <Button shape="circle" icon={<MoreOutlined />} />
                </Dropdown>
            ]} />

            <Form 
                onFinish={(values) => handleEditUserData(values)}
                initialValues={{ 
                    name: userData.name,
                    email: userData.email,
                    teamName: userData?.teamName
                }}
                >
                <Item 
                    name="name"
                    rules={[{ required: true, message: 'Please input your name' }]}
                    style={{ marginBottom: 0 }}
                    >
                    <Input
                        disabled={isLoading}
                        prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Name"
                        addonBefore={isMobile ? false : "Name"}
                        />
                </Item>

                <Divider />

                <Item 
                    name="email" 
                    rules={[
                        { required: true, message: 'Please input your e-mail' },
                        { type: 'email', message: 'Please supply a valid email address' }
                    ]}
                    style={{ marginBottom: 0 }}
                    >
                    <Input
                        disabled={isLoading}
                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Email Address"
                        addonBefore={isMobile ? false : "Email Address"}
                        />
                </Item>


                {userData?.teamName && (
                    <>
                        <Divider />
                        <Item 
                            name="teamName" 
                            rules={[
                                { required: true, message: 'Team name cannot be empty' },
                            ]}
                            style={{ marginBottom: 0 }}
                            >
                            <Input
                                disabled={isLoading}
                                prefix={<TeamOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Team Name"
                                addonBefore={isMobile ? false : "Team Name"}
                                />
                        </Item>
                    </>
                )}

                <Row justify="end">
                    <Button 
                        style={{ marginTop: 24 }} 
                        type="primary" 
                        htmlType="submit" 
                        disabled={isLoading} 
                        loading={isLoading}
                        >
                        Update
                    </Button>
                </Row>
            </Form>

            <Divider />

            {adminData.sportIsCricket() && (
                <Form initialValues={{ ecbOptIn: userData.ecbOptIn || false }}>
                    <Alert
                        message={
                            <>
                                <Paragraph>
                                    Choose YES to get information from the England and Wales Cricket Board (ECB) (such as tickets, offers, news, competitions, prize draws and other ways to get involved in cricket).
                                </Paragraph>
                                <Paragraph>
                                    If you choose 'YES' Fantasy Club Cricket will provide your registration informaton to the ECB. The ECB's privacy policy which explains how your personal data will be used is available at www.ecb.co.uk/privacy.
                                </Paragraph>
                                <Item name="ecbOptIn" valuePropName="checked" style={{margin: 0}}>
                                    <Switch
                                        disabled={isLoading}
                                        checkedChildren="YES"
                                        unCheckedChildren="NO"
                                        onChange={handleECBChange}
                                        />
                                </Item>
                            </>
                        }
                        type="info"
                        />
                </Form>
            )}
        </>
    )
}

export default MyAccount;