import React, { useState, useEffect, useCallback } from 'react';
import { Radio, Typography, Row, Col, Select } from 'antd';

import { api } from 'api';
import { Teamlist, PageTitle } from 'components';
import { useChangePageTitle } from 'utils/useChangePageTitle';
import globalStyles from 'styles/globalStyles';

import useScreenSize from "hooks/useScreenSize";

import { capitalize } from 'helpers/textHelper';

const { Group, Button } = Radio;
const { Title } = Typography;
const { Option, OptGroup } = Select;

const TeamStandings = () => {

    const [{ isTabletOrMobile }] = useScreenSize();

    const [teamlistData, setTeamlistData] = useState([]);
    const [months, setMonths] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortedValue, setSortedValue] = useState('totalPoints');

    const [year, setYear] = useState("current");
    const [archiveTeamStandingYears, setArchiveTeamStandingYears] = useState([]);

    const changePageTitle = useChangePageTitle();

    const changeYear = (e) => {
        setYear(e.target.value);
    }

    const getArchiveTeamStandingYears = async () => {
        try {
            const results = await api.team.getArchiveTeamStandingYears();
            setArchiveTeamStandingYears(results.data);
        } catch (error) {
            console.log(error);
        }
    }

    const getTeamListData = useCallback(async (year) => {
        setLoading(true);
        try {
            const results = await api.team.getTeamList(year);

            let teamList = [];
            let months = [];

            results.data.forEach(team => { 
                let teamForList = team;
                if (year === 'current') {

                    Object.keys(team.monthlyPoints).forEach(month => {
                        teamForList[month] = team.monthlyPoints[month];

                        if (months.indexOf(month) === -1) {
                            months = [...months, month];
                        }
                    });                    
                }

                teamList = [...teamList, teamForList];
            });

            setTeamlistData(teamList);
            setMonths(months);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }, [year]);

    function Dropdown() {
        return (
            <>
                {year === 'current' ? (
                    <Select
                        value={sortedValue}
                        style={{...globalStyles.dropdownWidth(isTabletOrMobile)}} 
                        onChange={(value) => setSortedValue(value)}
                        >
                        <Option value="totalPoints">Overall Total</Option>
                        <Option value="weekPoints">Week Total</Option>
                        <OptGroup label="Past Months">
                            {months.map(month => 
                                <Option key={month} value={month}>{capitalize(month)}</Option>  
                            )}
                        </OptGroup>
                    </Select>
                ) : (
                    <Title level={5} strong>Year: {year}</Title>
                )}
            </>
        )
    }

    useEffect(() => {
        getArchiveTeamStandingYears();
        getTeamListData(year);
        changePageTitle('Team Standings');
    }, [year]);

    return (
        <>
            <PageTitle title="Team Standings" extra={[<Dropdown key="dropdown" />]} />
            <Teamlist
                year={year}
                teamlistData={teamlistData}
                isLoading={loading}
                sortedValue={sortedValue}
                months={months}
                />

            {archiveTeamStandingYears.length > 0 && (
                <Row justify="end">
                    <Col>
                        <Typography.Text>Previous Years: </Typography.Text>
                        <Group 
                            size="small" 
                            onChange={changeYear} 
                            defaultValue="current"
                            style={{ marginTop: 24 }}
                            >
                            <Button value="current">Current</Button>
                            {archiveTeamStandingYears.map(year =>
                                <Button key={year} value={year}>{year}</Button>
                            )}
                        </Group>
                    </Col>
                </Row>
            )}
            
            
        </>
    )
}

export default TeamStandings;