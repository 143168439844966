import React, { useEffect, useState } from 'react';
import { Playerlist, PageTitle } from 'components';
import { useChangePageTitle } from 'utils/useChangePageTitle';

import { api } from 'api';

const PlayerStandings = () => {

    const [playerlistData, setPlayerlistData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getPlayerList = async () => {

        try {
            const results = await api.player.getPlayerList();
            const data = await results.json();

            setPlayerlistData(data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const changePageTitle = useChangePageTitle();

    useEffect(() => {
        getPlayerList();
        changePageTitle('Player Standings');
    }, []);

    return (
        <>
            <PageTitle title="Player Standings" />
            <Playerlist isLoading={loading} playerData={playerlistData} />
        </>
    )
}

export default PlayerStandings;