import React, { useState, useReducer, useEffect } from 'react';
import { Row, Button, Steps, Modal, Result, Affix, message } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

import { api } from 'api';

import { ChooseCaptain, TeamName, SelectTeam } from './components';
import { reducer, reducerActions, initialState } from './reducer';

import CardPayment from './partials/CardPayment';
import { FccSection, PageTitle } from 'components';
import { round } from 'helpers/mathsHelper';
import { getErrorMessage } from 'helpers/feedbackMessageHelper';

import useUser from "hooks/useUser";
import useAdmin from "hooks/useAdmin";

export const CreateTeamContext = React.createContext();

const CreateTeam = () => {

    const [modal, contextHolder] = Modal.useModal();

    const [state, dispatch] = useReducer(reducer, initialState);

    const [submitLoading, setSubmitLoading] = useState(false);
    const [stage, setStage] = useState(0);

    const { adminData } = useAdmin();
    const { userData, mutateUser, loggedOut } = useUser();
    const navigate = useNavigate();

    const submit = async () => {
        setSubmitLoading(true);

        const onOk = (teamId) => {
            navigate(`/team/${teamId}`);

            mutateUser({ 
                ...userData, 
                ...{ teamName: state.teamName, teamId: teamId } 
            });
        }

        const selectedPlayerIds = state.selectedPlayers.map(player => player.id);

        try {
            const response = await api.team.createTeam(
                selectedPlayerIds,
                state.captain,
                state.teamName
            );

            const teamId = response.data.id;

            modal.success({
                title: 'Success',
                content: 'Your team was successfully created',
                okText: 'My Team',
                onOk: () => onOk(teamId)
            });
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
            console.error('ERROR_4');
        }

        setSubmitLoading(false);
    }

    useEffect(() => {
        dispatch({
            type: reducerActions.setCashRemain, 
            payload: round(adminData.teamBudget(), 1)
        });

        dispatch({
            type: reducerActions.isReady,
            payload: true
        });
    }, []);

    const handleReset = () => {
        dispatch({
            type: reducerActions.selectPlayer, 
            payload: { selectedPlayers: [], selectedRowKeys: []}
        });
    }

    if (
        adminData.hasEntryFeeCollection() && 
        ! userData?.paymentReference &&
        userData?.id
    ) {
        return (
            <CardPayment />
        );
    }

    return (
            <>
            {contextHolder}
            <PageTitle title="Create Team" />

            {(! loggedOut && userData?.teamId) || loggedOut ? (
                <>
                {loggedOut ? (
                    <Result 
                    title="Unauthorised Access"
                    subTitle="You must log in to create a team" 
                    status="warning" 
                    />
                ) : (
                    <Result
                        status="warning"
                        title="You have already created a team"
                        subTitle="You may only create one team per account" 
                        />
                )}
                </>
            ) : (
                <CreateTeamContext.Provider value={{ state, dispatch }}>
                    <FccSection>
                        <Steps 
                            current={stage}
                            items={[
                                { title: 'Select Players' },
                                { title: 'Team Name' },
                                { title: 'Choose Captain' }
                            ]}
                            />
                    </FccSection>

                    <FccSection>
                        {stage === 0 && <SelectTeam />}
                        {stage === 1 && <TeamName />}
                        {stage === 2 && <ChooseCaptain />}
                    </FccSection>
                    <FccSection>
                        <Affix offsetBottom={32}>
                            <Row type="flex" justify="space-between" align="middle" className="affix-submit-save affix--shadow">
                                <div>
                                    {stage === 0 && (
                                        <Button
                                            danger
                                            onClick={handleReset}
                                            >
                                            Reset
                                        </Button>
                                    )}
                                </div>
                                <Button.Group>
                                    <Button 
                                        disabled={stage === 0 || submitLoading} 
                                        type="primary" 
                                        onClick={() => setStage(stage - 1)}
                                        ghost
                                        >
                                        <CaretLeftOutlined />Back
                                    </Button>

                                    <Button 
                                        disabled={
                                            submitLoading ||
                                            state.hasErrors || 
                                            (stage === 1 && !state.teamName.length) ||
                                            (stage === 2 && !state.captain)
                                        } 
                                        type="primary" 
                                        onClick={stage === 2 ? submit : () => setStage(stage + 1)} 
                                        loading={submitLoading}
                                        >
                                        {
                                        stage === 2 ? "Submit" : <>Next <CaretRightOutlined /></>}
                                    </Button>
                                    
                                </Button.Group>
                            </Row>
                        </Affix>
                    </FccSection>
                </CreateTeamContext.Provider>
            )}
        </>
    )
}

export default CreateTeam;