import React, { useState, useEffect } from 'react';
import { Modal, Table } from 'antd';

import { api } from 'api';

import { Loading } from 'components';

const TransferHistory = ({showModal, teamId, handleClose}) => {

    const [loading, setLoading] = useState(true);
    const [transferHistory, setTransferHistory] = useState([]);

    const columns = [
        {
            title: 'Active Gameweek',
            dataIndex: 'gameweek',
            key: 'gameweek',
            render: (text, record) => record.gameweek +1
        },
        {
            title: 'Player In',
            dataIndex: 'playerIn',
            key: 'playerIn',
            render: (text, record) => text.name
        },
        {
            title: 'Player Out',
            dataIndex: 'playerOut',
            key: 'playerIn',
            render: (text, record) => text.name
        },
        {
            title: 'Time',
            dataIndex: 'dateTime',
            key: 'dateTime',
            render: (text, record) => {
                const dateOfTransfer = new Date(text);
                return dateOfTransfer.toLocaleString('en-GB', {
                    dateStyle: "long",
                    timeStyle: "short",
                });
            }
        }
    ];

    const fetchTransferHistory = async () => {
        try {
            const result = await api.team.getNetTransfers(teamId);
            setTransferHistory(result.data);
            setLoading(false);
        } catch (error) {
            console.log('ERROR281', error);
        }
    }

    useEffect(() => {
        fetchTransferHistory();
    }, []);

    return (
        <Modal title="Transfer History" open={showModal} onCancel={handleClose} footer={null}>
            <Table
                dataSource={transferHistory}
                columns={columns}
                loading={loading} 
                size="small"
                />
        </Modal>
    )

}

export default TransferHistory;