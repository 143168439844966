import React, { useState, useEffect, useReducer } from 'react';
import { List, Button, message, Popconfirm, Dropdown, Space } from 'antd';
import { EditOutlined, DeleteOutlined, MoreOutlined, CalendarOutlined, TeamOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";

import { PageTitle, Loading } from 'components';
import { api } from 'api';

import { AdminBreadcrumbs } from './../components';
import EditMatchData from './partials/EditMatchData';
import AddPlayerToMatch from './partials/AddPlayerToMatch';
import PlayerPriceChanges from './partials/PlayerPriceChanges';

import { getErrorMessage } from 'helpers/feedbackMessageHelper';

import { reducer, reducerActions, initialState } from './reducer';

import useScreenSize from "hooks/useScreenSize";

export const EditPlayerMatchDataContext = React.createContext();

const EditPlayerMatchData = () => {

    let params = useParams();

    const [state, dispatch] = useReducer(reducer, initialState);

    const [playerPriceChangesIsOpen, setPlayerPriceChangesIsOpen] = useState(false);
    const [editMatchDataDrawerIsOpen, setEditMatchDataDrawerIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [addPlayerToMatchModalIsVisible, setAddPlayerToMatchModalIsVisible] = useState(false);

    const [{ isMobile }] = useScreenSize();

    const getPlayerMatchData = async () => {
        try {
            const player = await api.player.getPlayer(params.playerId);
            const playerData = await player.json();
            
            const playerPriceChanges = await api.player.getPlayerPriceChanges(params.playerId);

            dispatch({
                type: reducerActions.setGameweekData, 
                payload: playerData.gameweekData
            });

            dispatch({
                type: reducerActions.setPlayerData, 
                payload: playerData
            });

            dispatch({
                type: reducerActions.setPlayerPriceChangeData,
                payload: playerPriceChanges.data
            });
            setLoading(false);
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
            console.error('ERROR_7', error);
        }
    }

    useEffect(() => {
        getPlayerMatchData();
    }, []);

    const handleAddPlayerToMatch = (newMatch) => {
        const existingPlayerGameweekData = [...state.gameweekData, newMatch];

        dispatch({
            type: reducerActions.setGameweekData, 
            payload: existingPlayerGameweekData
        });

        setAddPlayerToMatchModalIsVisible(false);
    }

    const handleEditMatchData = (matchId) => {

        dispatch({
            type: reducerActions.setGameweekDataBeingEdited, 
            matchId: matchId
        });

        setEditMatchDataDrawerIsOpen(true);
    }

    const removePlayerFromMatch = async (gamePlayerId) => {
        try {
            await api.player.removePlayerFromMatch(params.playerId, gamePlayerId);

            const existingPlayerGameweekData = [...state.gameweekData];
            const index = existingPlayerGameweekData.findIndex(item => item.id === gamePlayerId);

            if (index > -1) {
                existingPlayerGameweekData.splice(index, 1);
                dispatch({
                    type: reducerActions.setGameweekData, 
                    payload: existingPlayerGameweekData
                });
            }

            message.success("Player successfully removed from match.");

        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
            console.log('ERROR_8');
        }
    }

    const menuItems = [
        {
            key: 'see-adjust-player-prices',
            label: "Weekly Player Price Changes",
            onClick: () => setPlayerPriceChangesIsOpen(true)
        }
    ];

    return (
        <>
            {loading ? <Loading /> : (
                <>
                    <EditPlayerMatchDataContext.Provider value={{ state, dispatch }}>
                        <PageTitle 
                            title={`Edit ${state.playerData.name} match data`} 
                            extra={[
                                <Button key="addPlayerToMatch" type="primary" onClick={() => setAddPlayerToMatchModalIsVisible(true)}>
                                    Add Player To Match
                                </Button>,
                                <Dropdown
                                    key="moreItems"
                                    placement="bottomRight"
                                    trigger="click"
                                    menu={{ items: menuItems }}
                                    >
                                    <Button shape="circle" icon={<MoreOutlined />} />
                                </Dropdown>
                            ]}
                            breadcrumbs={<AdminBreadcrumbs 
                                title="Edit player match data"
                                secondLevelTitle="Add/Edit Players"
                                secondLevelTitleLink="/admin/edit-players"
                                />
                            }
                            />
                    
                        <List
                            itemLayout={isMobile ? 'vertical' : 'horizontal'}
                            dataSource={state.gameweekData}
                            renderItem={item => {

                                const pluralPoints = item.total !== 1 ? 'points' : 'point';

                                const matchDate = item.matchInfo?.matchDate ? new Date(item.matchInfo.matchDate) : null;

                                let matchDescription = null;
                                if (item.matchInfo?.homeClub && item.matchInfo?.awayClub) {
                                    matchDescription = `${item.matchInfo?.homeClub} vs ${item.matchInfo?.awayClub}`;
                                }

                                return (
                                    <List.Item
                                        key={item.id}
                                        style={{padding: '12px 0'}}
                                        actions={[
                                            <div>{`Gameweek ${item.gameweek}`}</div>,
                                            <div>{`${item.total} ${pluralPoints}`}</div>,
                                            <div>
                                                <Button 
                                                    size="small" 
                                                    type="primary"
                                                    shape="circle" 
                                                    icon={<EditOutlined />} 
                                                    onClick={() => handleEditMatchData(item.matchInfo.id)}
                                                    />
                                            </div>,
                                            <div>
                                                <Popconfirm 
                                                    title="Are you sure you want to delete this player match?"
                                                    okText="Yes" 
                                                    cancelText="No" 
                                                    onConfirm={() => removePlayerFromMatch(item.id)}
                                                    >
                                                    <Button 
                                                        size="small" 
                                                        type="primary"
                                                        shape="circle" 
                                                        icon={<DeleteOutlined />}
                                                        danger
                                                        />
                                                </Popconfirm>
                                            </div>
                                        ]}
                                        >

                                        <List.Item.Meta
                                            title={<Space size={4}><CalendarOutlined /> {matchDate ? matchDate.toDateString() : 'Unknown'}</Space>}
                                            description={<Space size={4}><TeamOutlined />{matchDescription || 'Unknown'}</Space>}
                                            />
                                    </List.Item>
                                )
                            }}
                        />

                        <AddPlayerToMatch
                            isVisible={addPlayerToMatchModalIsVisible} 
                            handleConfirm={handleAddPlayerToMatch}
                            handleCancel={() => setAddPlayerToMatchModalIsVisible(false)}
                            playerId={state.playerData.id}
                            />

                        <EditMatchData 
                            isOpen={editMatchDataDrawerIsOpen} 
                            handleClose={() => setEditMatchDataDrawerIsOpen(false)}
                            />

                        <PlayerPriceChanges 
                            isVisible={playerPriceChangesIsOpen} 
                            handleClose={() => setPlayerPriceChangesIsOpen(false)} 
                            playerId={state.playerData.id}
                            />
                    </EditPlayerMatchDataContext.Provider>
                </>
            )}
        </>
    )

}

export default EditPlayerMatchData;