import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Statistic, Badge, theme } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import styles from './styles';

const { Title } = Typography;
const { useToken } = theme;

function Stat(props) {

    const { token } = useToken();

    const { value, title, type, loading, link, suffix } = props;
    
    return (
        <>
        {type === 'blank' ? (
            <div style={styles.statHolder}>
                <Statistic
                    value={value}
                    valueStyle={styles.stat}
                    loading={loading}
                    />
                <Title level={4} style={styles.title}>{title}</Title>
                {link && link}
            </div>
        ) : (
            <Badge 
                style={styles.badge} 
                count={
                    type === 'success' ? (
                        <CheckCircleFilled style={{...styles.icon, ...styles.iconSuccess(token.colorSuccess) }} />
                    ) : (
                        <CloseCircleFilled style={{...styles.icon, ...styles.iconFail(token.colorError) }} />
                    )
                
                }>
                    <div style={ type === 'success' ? styles.success(token.colorSuccess) : styles.fail(token.colorError)}>
                        <Statistic
                            value={value}
                            valueStyle={styles.stat}
                            suffix={suffix}
                            />
                        <Title level={4} style={styles.title}>{title}</Title>
                    </div>
            </Badge>
        )}
            
        </>
    )
}

Stat.propTypes = {
    type: PropTypes.oneOf(['success', 'blank']).isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default Stat;