import React, { useState } from 'react';
import { Typography, Modal, Row, Col, Select, Input, Form, message, Button } from 'antd';

import useAdmin from "hooks/useAdmin";

import { api } from 'api';
import { getErrorMessage } from 'helpers/feedbackMessageHelper';

const ImportScorecard = ({visible, handleClose, handleSuccess}) => {

    const { adminData } = useAdmin();

    const gameweeks = Array.from(Array(adminData.getGameweek()), (_, i) => i+1);

    const [form] = Form.useForm();
    const [scorecardIsImporting, setScorecardIsImporting] = useState(false);

    const handleSubmit = () => {

        setScorecardIsImporting(true);

        form
            .validateFields()
            .then( async (values) => {
                const { scorecardUrl, gameweek } = values;
                const matchId = scorecardUrl.match(/[0-9]+$/);

                try {
                    const response = await api.admin.importMatch(parseInt(matchId[0]), gameweek);
                    message.success("Scorecard Imported");

                    handleSuccess(response.data);
                    handleClose();
                } catch (errInfo) {
                    message.error(getErrorMessage(`${errInfo.response.data.error}`));
                }
                
                setScorecardIsImporting(false);
                
            })
            .catch((info) => {
                console.log('Validate Failed:', info);

                setScorecardIsImporting(false);
            });

    }

    return (
        <Modal 
            title="Import Scorecard" 
            open={visible} 
            onCancel={handleClose}
            footer={[
                <Button
                    key="cancel"
                    disabled={scorecardIsImporting}
                    onClick={handleClose}
                    >
                    Cancel
                </Button>,
                <Button 
                    key="submit" 
                    type="primary" 
                    loading={scorecardIsImporting} 
                    onClick={handleSubmit}
                    >
                    Submit
                </Button>,
            ]}
            >
                <Typography.Paragraph>You can import individual games from play-cricket by entering in the match scorecard URL for the game you want to import.</Typography.Paragraph>

                <Form form={form}>
                    <Row type="flex" gutter={{ md: 24 }} align="middle">
                        <Col xs={24} md={12}>
                            <Form.Item 
                                name="scorecardUrl"
                                rules={[{ required: true, message: 'Please provide a URL' }]} 
                                >
                                <Input placeholder="Scorecard URL" disabled={scorecardIsImporting} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item 
                                name="gameweek"
                                rules={[{ required: true, message: 'Please select a gameweek' }]} 
                                >
                                <Select 
                                    style={{width: '100%'}} 
                                    placeholder="Gameweek" 
                                    disabled={scorecardIsImporting}
                                    >
                                    {gameweeks.map(number => (
                                        <Select.Option key={number} value={number}>Gameweek {number}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                
        </Modal>
    );
    
}

export default ImportScorecard;