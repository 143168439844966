class RulesTextContent {

    constructor(adminHelper) {
        this.adminHelper = adminHelper;
    }

    getGeneralText() {
        return [
            'There can be only one team entered per registered user account.',
            `Each team shall have an initial value of no more than ${this.adminHelper.currency()}${this.adminHelper.teamBudget()} million.`,
            'Managers may enter a team at any point during the season, although in order to score points for the first gameweek, teams must be submitted before the Season Start date. Thereafter, entries will start scoring points from the gameweek after they were entered and points will not be backdated.'
        ];
    }

    getGameweeksText() {
        return [
            `The gameweek will advance every ${this.adminHelper.gameweekAdvanceDay()} at ${this.adminHelper.gameweekAdvanceTime()}; the same time as transfers and captain changes are processed.`,
            'For the purpose of viewing league standings by month, gameweeks that span two months will count for the month that they started in.'
        ];
    }

    getTeamsText() {
        return [
            'The players included on the selection list and their values will be determined by the League Administrator.',
            'Each team can select a captain who will earn double points each week. You may change your captain each week if you wish.',
            'Team managers will be able to boost their captain to receive triple points for one week only at any point during the season.',
            `Each team must contain ${this.adminHelper.getTeamSize()} players and the make-up of each team is flexible in terms of how many players can be selected from each position. Other than a compulsory keeper, team managers may select any number players up to a maximim of ${this.adminHelper.positionsAndLimits()}. Teams must select at least one player in each position.`
        ];
    }

    getTransfersText() {
        return [
            `Managers may alter their teams at any point during the Season. A total of 1 free transfer is available each week and additional transfers are permitted, but will incur a cost of ${this.adminHelper.getTransferDeduct()} points per transfer. When a captain is transferred out of a team, a new captain will be selected based on whoever was transferred in.`,
            'An unused transfer will roll over to the following gameweek, allowing a team the possibility of having a maximum of 2 free transfers to use.',
            `Transfers and captain changes may be submitted at any time but will not take effect until ${this.adminHelper.gameweekAdvanceTime()} the following ${this.adminHelper.gameweekAdvanceDay()}, so they are active for the upcoming gameweek.`,
            `Please note that transfers are free and unlimited up until ${this.adminHelper.gameweekAdvanceTime()} on the date of the first match.`,
            'Team managers will be able to play one transfer wildcard per season, which will allow them to make free, unlimited transfers for one week only.',
            'Should a players value increase at any point during the season, then teams who have selected that player shall recieve the difference as additional budget when making transfers. Players will be transferred out at the same value they were bought for, regardless of any price change.',
            'Should a players value decrease, teams will lose that amount from their transfer budget (as long as the budget remains above the original limit).'
        ];
    }
}



export default RulesTextContent;