import React, { useContext } from 'react';
import { Col, Row, Input, DatePicker, Select  } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { ManualImportMatchContext } from '../../ManualImportMatch';

import useAdmin from "hooks/useAdmin";

import { reducerActions } from '../reducer';

const { Option } = Select;

const MatchInfo = () => {

    const { state, dispatch } = useContext(ManualImportMatchContext);
    const { adminData } = useAdmin();

    const gameweeks = Array.from(Array(adminData.getGameweek()), (_, i) => i+1);

    const handleChange = (e) => {
        dispatch({
            type: reducerActions.setMatchInfo, 
            payload: { newValue: e.target.value, name: e.target.id }
        });
    }

    const handleDateChange = (date) => {
        dispatch({
            type: reducerActions.setMatchInfo, 
            payload: { newValue: date, name: 'matchDate' }
        });
    }

    const handleGameweekChange = (value) => {
        dispatch({
            type: reducerActions.setMatchInfo, 
            payload: { newValue: value, name: 'gameweek' }
        });
    }

    const handleMatchTypeChange = (value) => {
        dispatch({
            type: reducerActions.setMatchInfo, 
            payload: { newValue: value, name: 'matchType' }
        });
    }

    return (
        <Row type="flex" gutter={{ md: 24}}>
            <Col xs={24} md={12} style={{ marginBottom: 24 }}>
                <Input
                    prefix={<TeamOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Home Team"
                    onChange={handleChange}
                    value={state.matchInfo.homeTeam}
                    id="homeTeam"
                    />
            </Col>
            <Col xs={24} md={12} style={{ marginBottom: 24 }}>
                <Input
                    prefix={<TeamOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Away Team"
                    onChange={handleChange}
                    value={state.matchInfo.awayTeam}
                    id="awayTeam"
                    />
            </Col>
            <Col xs={24} md={8} style={{ marginBottom: 24 }}>
                <DatePicker
                    style={{ width: '100%' }} 
                    placeholder='Match Date' 
                    format='ddd DD MMM YYYY'
                    allowClear={false}
                    onChange={handleDateChange}
                    value={dayjs(state.matchInfo.matchDate, 'DD/MM/YYYY')}
                    id="matchDate"
                    />
            </Col>
            <Col xs={24} md={8} style={{ marginBottom: 24 }}>
                <Select 
                    style={{width: '100%'}} 
                    placeholder='Gameweek' 
                    onChange={handleGameweekChange}
                    value={state.matchInfo.gameweek}
                    id="gameweek"
                    >
                    {gameweeks.map(number => (
                        <Option key={number} value={number}>Gameweek {number}</Option>
                    ))}
                </Select>
            </Col>
            <Col xs={24} md={8} style={{ marginBottom: 24 }}>
                <Select 
                    style={{width: '100%'}} 
                    placeholder='Match Type' 
                    onChange={handleMatchTypeChange}
                    value={state.matchInfo.matchType}
                    id="matchType"
                    >
                    <Option key='League' value='League'>League</Option>
                    <Option key='Cup' value='Cup'>Cup</Option>
                    <Option key='Friendly' value='Friendly'>Friendly</Option>
                </Select>
            </Col>
        </Row>
    )
}

export default MatchInfo;