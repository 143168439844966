import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Dropdown, Badge, Typography, Col, Space } from 'antd';
import { 
    MenuOutlined,
    UserOutlined,
    MoreOutlined,
    HomeOutlined, 
    ControlOutlined,
    UserAddOutlined,
    SkinOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

import useAdmin from "hooks/useAdmin";

import useNavigation from "hooks/useNavigation";
import useUser from "hooks/useUser";

import UserAvatar from './UserAvatar';
import Login from './Login';

import navigationConsts from 'constants/navigationConsts';

const ButtonGroup = Button.Group;

const { Text } = Typography;

function TopBar({handleToggleDrawerNav, showToggle}) {

    const [activeNavItem,{ getAdminMenu }] = useNavigation();

    const { adminData } = useAdmin();

    const [loginIsVisible, setLoginIsVisible] = useState(false);

    const navigate = useNavigate();
    const { userData, logout, loggedOut } = useUser();

    const menuItems = [
        {
            key: navigationConsts.HOME,
            label: "Home",
            icon: <HomeOutlined />,
            visible: 1,
            onClick: () => navigate('/')
        },
        {
            key: navigationConsts.MY_TEAM,
            label: "My Team",
            icon: <SkinOutlined />,
            visible: userData?.hasOwnProperty('teamId') ? 1 : 0,
            onClick: () => navigate(`team/${userData.teamId}`)
        },
        {
            key: navigationConsts.MY_ACCOUNT,
            label: "My Account",
            icon: <ControlOutlined />,
            visible: userData && Object.keys(userData).length > 0 ? 1 : 0,
            onClick: () => navigate('my-account')
        },
        {
            key: navigationConsts.LOGOUT,
            label: "Logout",
            icon: <LogoutOutlined />,
            visible: userData && Object.keys(userData).length > 0 ? 1 : 0,
            onClick: () => logout()
        },
        {
            key: `topBar-${navigationConsts.ADMIN_HOME}`,
            type: 'group',
            label: "Admin",
            icon: <UserAddOutlined />,
            visible: userData?.admin ? 1 : 0,
            children: getAdminMenu({})
        }
    ].filter(item => item.visible);

    const UserMenu = () => {
        return (
            <Row 
                justify="end"
                align="middle" 
                gutter={8} 
                >   
                <Col>
                    <Space size={8}>
                        <UserOutlined style={{ color: 'white', marginRight: 4 }} />
                        <Text strong style={{ color: 'white' }}>{userData?.name}</Text>
                        <Dropdown 
                            placement="bottomRight"
                            trigger="click"
                            menu={{ items: menuItems, selectedKeys: activeNavItem }}
                            >
                            <Button type="primary" shape="circle" icon={<MoreOutlined />} />
                        </Dropdown>
                        
                    </Space>
                </Col>
                <Col>
                    <a onClick={() => navigate('/')}>
                        <Badge count={0}>
                            <UserAvatar 
                                userName={userData?.name} 
                                clubLogo={adminData.getLogo()}
                                />
                        </Badge>
                    </a>
                </Col>
            </Row>
        )
    }

    const VisitorMenu = () => {

        return (
            <Col>
                <ButtonGroup>
                    <Button style={{ color: 'white' }} size="small" type="text" onClick={() => setLoginIsVisible(true)}>Login</Button>
                    <Button style={{ color: 'white' }} size="small" type="text" onClick={() => navigate('new-user')}>Create Account</Button>
                </ButtonGroup>
            </Col>
        )
    }

    const handleCancel = () => {
        setLoginIsVisible(false);
    }

    return (
        <>
            <Row type="flex" justify="space-between" align="middle" style={{ height: '100%' }}>
                <Col>
                    {showToggle && (
                        <Button 
                            type="primary" 
                            icon={<MenuOutlined />} 
                            size="large" 
                            onClick={() => handleToggleDrawerNav()} 
                            />
                    )}
                </Col>
                <Col>
                    {! loggedOut && userData && Object.keys(userData).length ? <UserMenu /> : <VisitorMenu />}
                </Col>
            </Row>

            <Login visible={loginIsVisible} handleCancel={handleCancel} />
        </>
    )
}

TopBar.propTypes = {
    handleToggleDrawerNav: PropTypes.func.isRequired,
    showToggle: PropTypes.bool
}

export default TopBar;