import useSWR from "swr";
import Cookies from 'js-cookie';
import axios from 'axios';
import jwt_decode from "jwt-decode";

import { buildEndpoint } from 'helpers/apiHelper';

function useUser() {

    const fetcher = async () => {

        const token = Cookies.get('__kopek');

        if (! token) {
            const error = new Error("No cookie!");
            error.status = 403;
            throw error;
        }

        const decodedToken = jwt_decode(token);
        const headers = { Authorization: `Bearer ${token}` };
    
        try {
            const userResponse = await axios.get(
                buildEndpoint(`user/${decodedToken.user.id}`), 
                { headers: headers }
            );

            return userResponse.data;
        } catch (responseError) {
            const error = new Error("User fetch error");
            error.status = 403;
            throw error;
        }
    }

    const { data, error, isLoading, mutate } = useSWR(
        'user',
        fetcher
    );

    const logout = () => {
        Cookies.remove('__kopek');
        mutate(null);
    }

    const login = (token) => {
        Cookies.set('__kopek', token, { expires: 7 });
        mutate();
    }
  
    return {
        userData: data,
        loggedOut: error ? true : false,
        isLoading,
        logout,
        login,
        mutateUser: mutate
    }
}

export default useUser;