const styles = {
    success: (successColor) => ({
        padding: 20,
        borderColor: successColor,
        color: successColor,
        borderWidth: 2,
        borderStyle: 'solid',
        borderRadius: 4
    }),
    fail: (failColor) => ({
        padding: 20,
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: failColor,
        color: failColor,
        borderRadius: 4
    }),
    icon: {
        width: 'auto',
        fontSize: 30,
        backgroundColor: 'white'
    },
    iconFail: (failColor) => ({
        color: failColor,
    }),
    iconSuccess: (successColor) => ({
        color: successColor,
    }),
    badge: {
        backgroundColor: 'white'
    },
    stat: {
        fontSize: 32,
        textAlign: 'center',
    },
    title: {
        fontSize: 14,
        textAlign: 'center',
        marginBottom: 0,
        marginTop: 0
    },
    statHolder: {
        display: 'flex',
        flexFlow: 'column',
        height: '100%',
        boxShadow: '1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset',
        padding: 24
    }
}

export default styles;