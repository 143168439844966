import React, { useContext, useEffect, useState } from 'react';
import { Table, InputNumber, Switch, Checkbox, Space } from 'antd';

import { FccSection } from 'components';

import { ManualImportMatchContext } from '../../ManualImportMatch';

import StrategyManager from '../../strategies/StrategyManager';
import MatchInfoSummary from './MatchInfoSummary';
import { reducerActions } from '../reducer';

import useAdmin from "hooks/useAdmin";

const EnterPoints = () => {

    const { state, dispatch } = useContext(ManualImportMatchContext);

    const [columns, setColumns] = useState([]);

    const { adminData } = useAdmin();

    const strategyManager = new StrategyManager();
    const strategyMatchData = strategyManager.getMatchDataStrategy(adminData.getSport());

    const handleChange = (newValue, editedKey, playerId) => {

        let existingPlayerData = [...state.selectedPlayers];

        const playerIndex = existingPlayerData.findIndex(existingPlayer => existingPlayer.id === playerId);
        if (playerIndex !== -1) {
            const existingSelectedPlayer = existingPlayerData[playerIndex];

            existingSelectedPlayer[editedKey] = newValue;

            if (editedKey === 'runs') {
                if (newValue > -1) {
                    existingSelectedPlayer['dnb'] = false;
                }
            }

            existingPlayerData.splice(playerIndex, 1, existingSelectedPlayer);

            dispatch({
                type: reducerActions.selectPlayer, 
                payload: existingPlayerData
            });
        }
    }

    const handleApplyAll = (e, editedKey) => {
        let existingPlayerData = [...state.selectedPlayers];

        existingPlayerData.map(existingPlayer => {
            handleChange(e.target.checked, editedKey, existingPlayer.id)
        });
    }

    const setDisplayColumns = () => {

        let columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                fixed: 'left',
            },
        ];

        strategyMatchData.getFields().forEach(item => {
            columns.push({
                title: <Space size="small">{item.selectAll && <Checkbox onChange={(e) => handleApplyAll(e, item.name)} />}{item.label}</Space>, // Not Out
                dataIndex: item.name, // notOut
                key: item.name,
                render: (_, player) => {
                    const selectedPlayer = state.selectedPlayers.find(selectedPlayer => selectedPlayer.id === player.id);

                    return (
                        <>
                            {item.type === 'number' ? 
                                <InputNumber 
                                    disabled={state.isSaving} 
                                    onChange={(newValue) => handleChange(newValue, item.name, player.id)}
                                    defaultValue={selectedPlayer[item.name]}
                                    /> : 
                                <Switch 
                                    size='small' 
                                    disabled={state.isSaving} 
                                    onChange={(newValue) => handleChange(newValue, item.name, player.id)}
                                    defaultChecked={selectedPlayer[item.name]}
                                    checked={selectedPlayer[item.name]}
                                    checkedChildren="YES" 
                                    unCheckedChildren="NO"
                                    />
                            }
                        </>
                    )
                }
            });
        });

        setColumns(columns);
    }

    useEffect(() => {
        setDisplayColumns();
    }, []);

    return (
        <>
            <MatchInfoSummary />
            <FccSection>
                <Table 
                    rowKey={record => record.id} 
                    columns={columns} 
                    dataSource={[...state.selectedPlayers]} 
                    pagination={false}
                    scroll={{ x: true }}
                    sticky
                    />
            </FccSection>
        </>
    )
}

export default EnterPoints;