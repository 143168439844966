import axios from 'axios';
import Cookies from 'js-cookie';
import { buildEndpoint } from 'helpers/apiHelper';

const getPaymentToken = async () => {
    
    return axios.get(buildEndpoint('payment/token/generate'));
}

const generateTransaction = async (nonce) => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.post(
        buildEndpoint('payment'), 
        { nonce },
        { headers: headers }
    );
}

export {
    getPaymentToken,
    generateTransaction
}