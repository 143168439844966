import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

import { round } from 'helpers/mathsHelper';

const { useToken } = theme;

const PlayerValueSummary = ({ value, adjustedValue }) => {

    const { token } = useToken();

    if (adjustedValue === value) {
        return null;
    }

    return (
        <span 
            style={{ 
                fontSize: 12, 
                marginLeft: 4, 
                color: adjustedValue < value ? token.colorError : token.colorSuccess 
            }}
            >
            {adjustedValue < value ? 
                <ArrowDownOutlined /> : <ArrowUpOutlined />
            }
            {round(round(adjustedValue, 1) - round(value, 1), 1)}
        </span>
    )
}

PlayerValueSummary.propTypes = {
    value: PropTypes.number.isRequired,
    adjustedValue: PropTypes.number.isRequired
}

export default PlayerValueSummary;