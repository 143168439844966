import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Row, Avatar, Divider, Space, Col } from 'antd';

const { Text } = Typography;

const PageTitle = ({title, extra, icon, breadcrumbs}) => {
    return (
        <div style={{margin: '8px 0 32px'}}>
            <Row 
                type="flex" 
                align="middle" 
                justify="space-between" 
                style={{ margin: 0 }} 
                wrap={true}
                >
                <Col style={{ marginBottom: 8, display: 'flex', alignItems: 'center' }}>
                    {icon && <Avatar style={{ marginRight: 8 }} src={`/images/${icon}.svg`} />}
                    <Text level={3} style={{ margin: 0, fontWeight: 600, fontSize: 22 }}>{title}</Text>
                </Col>
                
                <Col style={{ marginBottom: 8 }}>
                    <Space size={8} direction='horizontal'>
                        {extra?.map(extraItem => { return extraItem })}
                    </Space>
                </Col>
                
            </Row>

            {breadcrumbs && (
                <Row>
                    {breadcrumbs}
                </Row>
            )}
            <Divider style={{ margin: '10px 0' }} />
        </div>
    )
}

PageTitle.propTypes = {
    icon: PropTypes.number,
    title: PropTypes.string.isRequired,
    extra: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
    breadcrumbs: PropTypes.node
}

export default PageTitle;