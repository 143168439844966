import React, { useContext, useEffect } from 'react';
import { Result } from 'antd';

import { useChangePageTitle } from 'utils/useChangePageTitle';

import useUser from "hooks/useUser";

const AdminAuth = (props) => {

    const { children } = props;

    const changePageTitle = useChangePageTitle();

    const { userData, loggedOut } = useUser();

    const userIsAdmin = userData?.admin;

    useEffect(() => {
        changePageTitle('Admin');
    }, []);

    const NotFound = () => {
        return (
            <Result
                status="warning"
                title="Unauthorised Access"
                subTitle="You're not authorised to view this page."
                />
        );
    }

    return (
        <>
            {userIsAdmin && ! loggedOut ? (
                <>{children}</>
            ) : (
                <NotFound />
            )}
        </>
    )

}

export default AdminAuth;