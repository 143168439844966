import CricketMatchData from "./CricketMatchData";
import FootballMatchData from "./FootballMatchData";
import HockeyMatchData from "./HockeyMatchData";

export default class StrategyManager {

    getMatchDataStrategy(sport, hasAutoUpdate = false)
    {
        switch(sport) {
            case "cricket":
                return new CricketMatchData(hasAutoUpdate);
            case "hockey":
                return new HockeyMatchData();
            case "football":
                return new FootballMatchData();
        }
    }

}