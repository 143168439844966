const styles = {
    welcome: (isTabletOrMobile, sport) => ({
        backgroundImage: `url(/images/${sport}/head--bg.jpg)`,
        backgroundPosition: 'bottom center',
        padding: isTabletOrMobile ? 12 : 18
    }),
    sider: {
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        background: 'white'
    },
    logoLink: { 
        padding: 8, 
        display: 'block', 
        marginBottom: 8 
    },
    title: { 
        color: 'white', 
        margin: 0, 
        background: 'rgba(0,0,0, .3)', 
        padding: 16,
        textAlign: 'center'
    },
    content: (isTabletOrMobile) => ({
        padding: isTabletOrMobile ? 12 : 24,
        minHeight: 280,
        background: "white",
        margin: isTabletOrMobile ? 0 : '24px 16px 0', 
        overflow: 'initial'
    }),
    topBarLinks: {
        color: 'white',
        margin: 0
    }
}

export default styles;