const styles = {
    marginTop: {
        marginTop: 30
    },
    selectStat: (isTabletOrMobile) =>  ({
        marginBottom: 16,
        padding: isTabletOrMobile ? 8 : 12
    }),
    selectFilter: {
        width: '100%', 
        marginBottom: 16
    }
}

export default styles;