import React, { useState, useEffect } from 'react';
import { Typography, Divider, List, Card } from 'antd';

import { Loading, PageTitle } from 'components';

import RulesTextContent from './RulesTextContent';
import { api } from 'api';
import { useChangePageTitle } from 'utils/useChangePageTitle';

import useAdmin from "hooks/useAdmin";

const { Text, Title } = Typography;

const Rules = () => {

    const { adminData } = useAdmin();

    const rules = new RulesTextContent(adminData);
    const changePageTitle = useChangePageTitle();

    const [loading, setLoading] = useState(true);
    const [pointsData, setPointsData] = useState([]);

    const getPointsData = async () => {
        try {
            const result = await api.user.getRules();
            setPointsData(result.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect( () => {
        getPointsData();
        setLoading(false);
        changePageTitle('Rules');
    }, []);

    return (
        <>
            <PageTitle title="Rules" />

            <Divider orientation="left">General</Divider>
            <List
                style={{marginBottom: 24}}
                bordered
                dataSource={rules.getGeneralText()}
                renderItem={item => (
                    <List.Item>
                        <Text>{item}</Text>
                    </List.Item>
                )}
            />

            <Divider orientation="left">Gameweeks</Divider>
            <List
                style={{marginBottom: 24}}
                bordered
                dataSource={rules.getGameweeksText()}
                renderItem={item => (
                    <List.Item>
                        <Text>{item}</Text>
                    </List.Item>
                )}
            />

            <Divider orientation="left">Transfers and Captain Changes</Divider>
            <List
                style={{marginBottom: 24}}
                bordered
                dataSource={rules.getTransfersText()}
                renderItem={item => (
                    <List.Item>
                        <Text>{item}</Text>
                    </List.Item>
                )}
            />

            <Divider orientation="left">Team Selection</Divider>
            <List
                style={{marginBottom: 24}}
                bordered
                dataSource={rules.getTeamsText()}
                renderItem={item => (
                    <List.Item>
                        <Text>{item}</Text>
                    </List.Item>
                )}
            />

            <PageTitle title="Points" />

            {loading ? <Loading /> : (
                <List
                    grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 2, xl: 4, xxl: 4 }}
                    dataSource={pointsData}
                    renderItem={item => (
                        <List.Item style={{padding: 0}}>
                            <Card title={item.positionName}>
                                <List
                                    size="small"
                                    dataSource={item.structure}
                                    renderItem={data => {
                                        const verb = data.points === 1 || data.points === -1 ? 'point' : 'points';
                                        return (
                                            <List.Item style={{padding: '8px 0'}}>
                                                <Text><Text strong>{data.points}</Text> {`${verb} per ${data.label}`}</Text>
                                            </List.Item>
                                        )
                                    }}
                                />

                                {adminData.sportIsCricket() && (
                                    <>
                                        <Title style={{marginTop: 16}} level={5}>Bonus Points</Title>

                                        <List
                                            size="small"
                                            dataSource={item.bonus}
                                            renderItem={data => {
                                                const verb = data.points > 1 ? 'points' : 'point';
                                                return (
                                                    <List.Item style={{padding: '8px 0'}}>
                                                        <Text><Text strong>{data.points}</Text> {`${verb} for ${data.label}`}</Text>
                                                    </List.Item>
                                                )
                                            }}
                                            />
                                    </>
                                )}
                                
                            </Card>
                        </List.Item>
                    )}
                />
            )}

            

            
        </>
    )
}

export default Rules;