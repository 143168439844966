const styles = {
    gameweekSelect: (isTablet) => ({
        textAlign: isTablet ? "left" : "right",
        margin: '12px 0'
    }),
    teamName: {
        marginBottom: 0
    },
    teamManagerWrap: {
        display: 'flex',
        alignItems: 'center'
    },
    myTeamCTA: (isTablet, isMobile) => (isTablet) ? {
        width: '100%',
        display: 'flex',
        columnGap: 8,
        alignItems: 'center',
        flexWrap: (isMobile) ? 'wrap' : 'nowrap'
    } : {},
    captainSelect: (isMobile) => ({
        width: isMobile ? '100%' : 250,
    }),
    myTeamCTAButton: (isTablet, isMobile) => ({
        marginRight: (isTablet) ? 0 : 8,
        marginBottom: (isMobile || isTablet) ? 8 : 0
    })
}

export default styles;