import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Select, Modal, InputNumber, List, Divider, Typography, Row, message, Space } from 'antd';
import { CalendarOutlined, TeamOutlined } from '@ant-design/icons';

import { api } from 'api';
import { PlayerValueSummary } from 'components';

import { EditPlayerMatchDataContext } from './../';
import { reducerActions } from './../reducer';

import useAdmin from "hooks/useAdmin";
import useScreenSize from "hooks/useScreenSize";

const { Item } = List;
const { Title } = Typography;

const PlayerPriceChanges = ({ isVisible, handleClose, playerId }) => {

    const [{ isMobile }] = useScreenSize();
    const { adminData } = useAdmin();

    const [loading, setLoading] = useState(false);
    const [gameweekData, setGameweekData] = useState([]);
    const [selectedGameweek, setSelectedGameweek] = useState(adminData.getGameweek());

    const [form] = Form.useForm();

    const { state, dispatch } = useContext(EditPlayerMatchDataContext);

    const gameweeks = Array.from(Array(adminData.getGameweek()), (_, i) => i+1);

    const handleGameweekChange = (gameweek) => {
        const filteredGameweekData = state.gameweekData.filter(match => match.gameweek === gameweek);
        setGameweekData(filteredGameweekData);
        setSelectedGameweek(gameweek);

        // set initial value
        const gameweekPriceChange = state.playerPriceChangeData.find(priceChange => priceChange.gameweek === gameweek);
        form.setFieldsValue({
            priceChange: gameweekPriceChange ? gameweekPriceChange.priceChange : 0
        });
    }

    const handleSubmit = async () => {
        setLoading(true);
        const formValues = form.getFieldsValue(true); // true gets all values

        try {
            await api.player.updatePlayerPriceChanges(playerId, selectedGameweek, formValues.priceChange);

            dispatch({
                type: reducerActions.updatePlayerPriceChangeData, 
                payload: {
                    gameweek: selectedGameweek,
                    priceChange: formValues.priceChange
                }
            });

            message.success('Successfully updated');

        } catch (error) {
            console.error('ERROR_72', error);
        }
        setLoading(false);
    }

    useEffect(() => {
        handleGameweekChange(adminData.getGameweek());
    }, [playerId]);

    return (
        <Modal 
            title="Edit Weekly Player Price Changes"
            open={isVisible}
            onCancel={handleClose}
            footer={null}
            >
            <>
                <Row type="flex" justify="space-between" align="middle">
                    <Title level={4} style={{ margin: '16px 0'}}>{state.playerData.name}</Title>
                    <span>
                        {`${adminData.currency()}${state.playerData.adjustedValue}m`}
                        <PlayerValueSummary 
                            adjustedValue={state.playerData.adjustedValue}
                            value={state.playerData.value}
                            />
                    </span>
                </Row>


                <Select 
                    style={{width: '100%'}} 
                    placeholder="Gameweek" 
                    onChange={handleGameweekChange}
                    defaultValue={adminData.getGameweek()}
                    disabled={loading}
                    >
                    {gameweeks.map(number => (
                        <Select.Option key={number} value={number}>Gameweek {number}</Select.Option>
                    ))}
                </Select>

                <Divider />

                <List
                    itemLayout="horizontal"
                    dataSource={gameweekData}
                    renderItem={(item, index) => {

                        const pluralPoints = item.total !== 1 ? 'points' : 'point';
                        const matchDate = item.matchInfo?.matchDate ? new Date(item.matchInfo.matchDate) : null;

                        let matchDescription = null;
                        if (item.matchInfo?.homeClub && item.matchInfo?.awayClub) {
                            matchDescription = `${item.matchInfo?.homeClub} vs ${item.matchInfo?.awayClub}`;
                        }

                        return (
                            <Item
                                key={index}
                                style={{padding: '12px 0'}}
                                actions={[
                                    <div>{`GW ${item.gameweek}`}</div>,
                                    <div>{`${item.total} ${pluralPoints}`}</div>
                                ]}
                                >
                                <List.Item.Meta
                                    title={<Space size={4}><CalendarOutlined /> {matchDate ? matchDate.toDateString() : 'Unknown'}</Space>}
                                    description={<Space size={4}><TeamOutlined />{matchDescription || 'Unknown'}</Space>}
                                    />
                            </Item>
                        )
                    }}
                    />

                    <Divider />

                <Form onFinish={(values) => handleSubmit(values)} form={form}>
                    <Space.Compact size={8} block direction={isMobile ? 'vertical' : 'horizontal'}>
                        <Form.Item 
                            name="priceChange" 
                            rules={[{required: true, message: 'Please input a price change'}]} 
                            style={{ marginBottom: 0, width: isMobile ? 'auto' : '80%' }}
                            >
                            <InputNumber
                                disabled={loading}
                                addonBefore="Price Change"
                                style={{ width: '100%' }}
                                placeholder='Price Change'
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                step={.1}
                                />
                        </Form.Item>
                        <Button 
                            style={{ width: isMobile ? 'auto' : '20%' }}
                            type="primary" 
                            htmlType="submit" 
                            block={isMobile} 
                            disabled={loading} 
                            loading={loading}
                            >
                            Update
                        </Button>
                    </Space.Compact>
                </Form>
            </>
        </Modal>
    )
}

PlayerPriceChanges.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    playerId: PropTypes.number.isRequired
}

export default PlayerPriceChanges;