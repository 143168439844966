import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Input, message } from 'antd';
import { TagOutlined } from '@ant-design/icons';

import { api } from 'api';
import { getErrorMessage } from 'helpers/feedbackMessageHelper';

import { reducerActions } from './../reducer';
import { TeamContext } from './../';

import useUser from "hooks/useUser";

const ChangeTeamName = ({isVisible, handleClose}) => {

    const { state, dispatch } = useContext(TeamContext);
    const [teamNameChangeIsLoading, setTeamNameChangeIsLoading] = useState(false);

    const { userData, mutateUser } = useUser();

    const handleTeamNameSubmit = async (values) => {

        setTeamNameChangeIsLoading(true);

        try {
            await api.team.changeTeamName(state.teamData.id, values.teamName);

            mutateUser({ 
                ...userData, 
                ...{ teamName: values.teamName } 
            });

            dispatch({
                type: reducerActions.changeTeamName, 
                payload: {
                    newTeamName: values.teamName
                }
            });

            message.success("Team name successfully changed.");
            handleClose();

        } catch (error) {
            console.error("error", error);
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setTeamNameChangeIsLoading(false);
    }

    return (
        <Modal
            title="Change Team Name"
            open={isVisible}
            onCancel={() => handleClose()}
            footer={null}
            >
            <Form 
                onFinish={(values) => handleTeamNameSubmit(values)}
                initialValues={{ teamName: state.teamData.name }}
                >
                <Form.Item name="teamName" rules={[{ required: true, message: 'Team Name cannot be blank' }]}>
                    <Input
                        prefix={<TagOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Team Name"
                        disabled={teamNameChangeIsLoading}
                        />
                </Form.Item>
                <Button 
                    loading={teamNameChangeIsLoading} 
                    type="primary" 
                    htmlType="submit"
                    >
                        Submit
                    </Button>
            </Form>
            
        </Modal>
    )
}

ChangeTeamName.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default ChangeTeamName;