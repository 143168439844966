import axios from 'axios';
import Cookies from 'js-cookie';
import { buildEndpoint } from 'helpers/apiHelper';

const getHomepageStats = async () => {
    return axios.get(buildEndpoint('stats'));
}

const getAdmin = async () => {
    return axios.get(buildEndpoint('admin'));
}

const getAdminForAdminPage = async () => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.get(buildEndpoint('admin/for-admin'), { headers: headers });
}

const updateAdmin = async ({
    introText, 
    primaryColor, 
    seasonStart, 
    transferWindow, 
    canEdit, 
    gameweekAdvanceDay, 
    gameweekAdvanceTime,
    autoPriceChange,
    transferDeduct
}) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.patch(
        buildEndpoint('admin/update'),
        {
            introText: introText, 
            primaryColor: primaryColor,
            seasonStart: seasonStart,
            transferWindow: transferWindow,
            canEdit: canEdit,
            gameweekAdvanceDay: gameweekAdvanceDay,
            gameweekAdvanceTime: gameweekAdvanceTime,
            autoPriceChange: autoPriceChange,
            transferDeduct: transferDeduct
        },
        { headers: headers }
    );
}

const getImportedMatches = async () => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.get(buildEndpoint('match/imported-matches'), { headers: headers });
}

const updateMatch = async (matchId, importMatch) => {
    const endpoint = buildEndpoint(`match/${matchId}`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.patch(
        endpoint,
        {
            import: importMatch
        },
        { headers: headers }
    );
}

const importMatch = async (matchId, gameweek) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.post(
        buildEndpoint('match/import'), 
        {
            gameweek: gameweek,
            matchId: matchId
        },
        { headers: headers }
    );
}

const deleteMatch = async (matchId) => {

    const endpoint = buildEndpoint(`match/${matchId}`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.delete(
        endpoint, 
        { headers: headers }
    );
}

const reImportMatch = async (matchPcId) => {

    const endpoint = buildEndpoint(`match/reimport/${matchPcId}`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.get(
        endpoint, 
        { headers: headers }
    );
}

const getUsers = async () => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.get(buildEndpoint('admin/users'), { headers: headers });
}

const updateUser = async (userId, userInfo) => {

    const endpoint = buildEndpoint(`admin/update-user/${userId}`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.patch(
        endpoint,
        userInfo,
        { headers: headers }
    );
}

const addPointsDeduction = async (postData) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.post(
        buildEndpoint('deduction/add'),
        postData,
        { headers: headers }
    );
}

const deletePointsDeduction = async (deductionId) => {

    const endpoint = buildEndpoint(`deduction/${deductionId}`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.delete(
        endpoint,
        { headers: headers }
    );
}

const getPlayersToImport = ({forceRefetch}) => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.get(
        buildEndpoint('match/import-players'), 
        { headers: headers, params: { forceRefetch } }
    );
}

const getMatchesWithoutPlayer = async (playerId) => {

    const endpoint = buildEndpoint(`match/without-player/${playerId}`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.get(endpoint, { headers: headers });
}

const uploadLogo = async ({logo}) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.post(
        buildEndpoint('admin/upload-logo'),
        { logo },
        { headers: headers }
    );
}

const deleteLogo = async () => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.delete(
        buildEndpoint('admin/delete-logo'),
        { headers: headers }
    );
}

const updatePointsStructure = async (pointsStructure) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.patch(
        buildEndpoint('points/structure'),
        { positions: pointsStructure },
        { headers: headers }
    );
}

const getEntryFundsSummary = async () => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.get(
        buildEndpoint('admin/entry-payment-summary'),
        { headers: headers }
    );
}

const addImportMatchRule = async ({weekday, matchType}) => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.post(
        buildEndpoint('match/add-import-rule'),
        { weekday, matchType },
        { headers: headers }
    );
}

const getMatchImportRules = async () => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.get(
        buildEndpoint('match/import-rules'),
        { headers: headers }
    );
}

const deleteMatchImportRule = async ({matchImportRuleId}) => {

    const endpoint = buildEndpoint(`match/import-rule/${matchImportRuleId}`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.delete(
        endpoint,
        { headers: headers }
    );
}

const getMatchData = async ({matchId}) => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.get(
        buildEndpoint(`match/${matchId}`),
        { headers: headers }
    );
}

const getLatestMatchesWithPlayers = async () => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.get(
        buildEndpoint('match/latest-with-players'),
        { headers: headers }
    );
}

const clearCache = async () => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.post(
        buildEndpoint(`admin/clear-cache`),
        {},
        { headers: headers }
    );
}

const endSeason = async () => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.post(
        buildEndpoint(`admin/end-season`),
        {},
        { headers: headers }
    );
}

const importManualMatch = async ({
    playerData, 
    homeTeam,
    awayTeam,
    matchDate,
    matchType,
    gameweek,
    matchId = null
}) => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.post(
        buildEndpoint('match/import/manual'),
        { 
            playerData,
            homeTeam,
            awayTeam,
            matchDate,
            matchType,
            gameweek,
            matchId
        },
        { headers: headers }
    );
}

const assignPlayerToMatchData = async (playerAndGamePlayerData) => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.put(
        buildEndpoint('match/assign-players-to-data'),
        { 
            updates: playerAndGamePlayerData
        },
        { headers: headers }
    );
}
export {
    getHomepageStats,
    getAdmin,
    updateAdmin,
    getAdminForAdminPage,
    getImportedMatches,
    updateMatch,
    importMatch,
    deleteMatch,
    reImportMatch,
    getUsers,
    updateUser,
    getPlayersToImport,
    getMatchesWithoutPlayer,
    uploadLogo,
    deleteLogo,
    addPointsDeduction,
    deletePointsDeduction,
    updatePointsStructure,
    getEntryFundsSummary,
    addImportMatchRule,
    getMatchImportRules,
    deleteMatchImportRule,
    getMatchData,
    clearCache,
    importManualMatch,
    assignPlayerToMatchData,
    endSeason,
    getLatestMatchesWithPlayers
}