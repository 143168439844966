import React, { useState, useEffect } from 'react';
import { InputNumber, Typography, Form, List, Card, Button, message, Affix, Row, Result } from 'antd';
import { LockOutlined } from '@ant-design/icons';

import { PageTitle, Loading } from 'components';
import { api } from 'api';

import AdminBreadcrumbs from './components/AdminBreadcrumbs';

import useAdmin from "hooks/useAdmin";

const PointsStructure = () => {

    const [loading, setLoading] = useState(true);
    const [pointsData, setPointsData] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const { adminData } = useAdmin();

    const [form] = Form.useForm();

    const getPointsData = async () => {
        try {
            const result = await api.user.getRules();
            setPointsData(result.data);

            const fieldValues = {};
            result.data.forEach(position => {

                const positionId = position.positionId;

                position.structure.forEach(item => {
                    // 1__run = 1;
                    const dataItem = positionId + "__" + item.name;
                    fieldValues[dataItem] = item.points;
                });

                position.bonus.forEach(item => {
                    // 1__centuryBonus = 50;
                    const dataItem = positionId + "__" + item.name;
                    fieldValues[dataItem] = item.points;

                    // 1__centuryBonusAmount
                    const dataItemAmount = positionId + "__" + item.name + "Amount";
                    fieldValues[dataItemAmount] = item.achievement;
                });

                
            });

            form.setFieldsValue(fieldValues);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect( () => {
        getPointsData();
    }, []);

    const submitValues = async () => {
        setIsSaving(true);

        try {

            const formValues = form.getFieldsValue(true); // true gets all values
            const formValuesArray = Array.from(Object.keys(formValues));

            const positionsToSend = [];

            [1,2,3,4].forEach(positionId => {

                const positionData = {};
                const positionKeys = formValuesArray.filter(key => parseInt(key.split('__')[0]) === positionId);

                positionKeys.map(value => {
                    const pointsValue = formValues[value];
                    const keyForSending = value.split('__')[1];
                    positionData[keyForSending] = pointsValue;
                });

                positionData['positionId'] = positionId;
                positionsToSend.push(positionData);
            });

            await api.admin.updatePointsStructure(positionsToSend);

            message.success("Points structure successfully updated");
        } catch (error) {
            message.error('Oops! Something went wrong.');
            console.log('ERROR_30');
        }

        setIsSaving(false);
    }

    return (
        <>
            <PageTitle title="Edit Points Structure" breadcrumbs={<AdminBreadcrumbs title="Edit Points Structure" />} />

            <Typography.Paragraph>
                It is possible to edit the points structure by entering in values into the form below. Negative values are allowed, just ensure to include a minus (-) before the number. You can remove any item by changing the amount of points awarded to 0. For example if you decide not to deduct points for scoring a duck, simply update the corresponding value to 0.
            </Typography.Paragraph>
            <Typography.Paragraph>
                Please note that you will not be able to edit values after the season has started.
            </Typography.Paragraph>

            {loading ? (
                <Loading />
            ) : (
                <>
                    {adminData.seasonHasStarted() ? (
                        <Result 
                            title="Unauthorised Access"
                            subTitle="You cannot change points structure once the season has started" 
                            status="warning" 
                            icon={<LockOutlined />}
                        />
                    ) : (
                        <Form
                            name="poinstStructure"
                            form={form}
                            >
                            <List
                                grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}
                                dataSource={pointsData}
                                renderItem={position => (
                                    <List.Item style={{ padding: '12px 0' }}>
                                        <Card title={
                                            <Typography.Title 
                                                level={5} 
                                                style={{ marginBottom: 0 }}
                                                >
                                                {`${position.positionName}s`}
                                            </Typography.Title>}
                                            >
                                            <List
                                                dataSource={position.structure}
                                                renderItem={item => (
                                                    <List.Item key={item.name} style={{ padding: '12px 0' }}>
                                                        <List.Item.Meta 
                                                            style={{textTransform: 'capitalize'}} 
                                                            title={`${item.label}:`} 
                                                            />
                                                        <Form.Item
                                                            key={item.name}
                                                            name={`${position.positionId}__${item.name}`}
                                                            style={{ marginBottom: 0 }}
                                                            >
                                                            <InputNumber disabled={isSaving} />
                                                        </Form.Item>
                                                    </List.Item>
                                                )}
                                            />

                                            {adminData.sportIsCricket() && (
                                                <List
                                                    header={
                                                        <Typography.Title 
                                                            level={5} 
                                                            style={{ marginBottom: 0 }}
                                                            >
                                                            Bonus Points
                                                        </Typography.Title>}
                                                    dataSource={position.bonus}
                                                    renderItem={item => (
                                                        <List.Item key={item.name} style={{ padding: '12px 0' }}>
                                                            <Form.Item
                                                                name={`${position.positionId}__${item.name}`}
                                                                style={{ marginBottom: 0 }}
                                                                >
                                                                <InputNumber bordered={true} disabled={isSaving} />
                                                            </Form.Item>
                                                            <span className="ant-form-text">points for {item.verb}</span>
                                                            {item.action && (
                                                                <>
                                                                    <Form.Item
                                                                        name={`${position.positionId}__${item.name}Amount`}
                                                                        style={{ marginBottom: 0 }}
                                                                        >
                                                                        <InputNumber bordered={true} disabled={isSaving} />
                                                                    </Form.Item>
                                                                    <span className="ant-form-text"> {item.action} </span>
                                                                </>
                                                            )}
                                                        </List.Item>
                                                    )}
                                                />
                                            )}
                                        </Card>
                                    </List.Item>
                                )}
                            />

                            <Affix offsetBottom={32}>
                                <Row justify="end" className="affix-submit-save affix--shadow">
                                    <Button 
                                        type="primary" 
                                        htmlType="submit" 
                                        onClick={submitValues}
                                        loading={isSaving}
                                        >
                                        Submit
                                    </Button>
                                </Row>
                            </Affix>
                        </Form>
                    )}
                </>
            )}
        </>
    )
}

export default PointsStructure;