import CricketPoints from "./CricketPoints";
import FootballPoints from "./FootballPoints";
import HockeyPoints from "./HockeyPoints";

export default class PointsHelperStrategyManager {

    getMatchDataStrategy(sport)
    {
        switch(sport) {
            case "cricket":
                return new CricketPoints();
            case "hockey":
                return new HockeyPoints();
            case "football":
                return new FootballPoints();
        }
    }

}