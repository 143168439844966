import React, { useState, useEffect } from 'react';
import { message, Tooltip, Button,  Switch, Dropdown, Table, Modal } from 'antd';
import { RedoOutlined, MoreOutlined, DeleteOutlined, EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

import { PageTitle, Loading } from 'components';
import { api } from 'api';
import { getErrorMessage } from 'helpers/feedbackMessageHelper';

import { EditControls } from 'components/FccTable/components';
import AdminBreadcrumbs from '../components/AdminBreadcrumbs';
import MatchImportRules from './partials/MatchImportRules';
import { ImportScorecard } from '../components';

import useAdmin from "hooks/useAdmin";

import styles from './styles';

function ImportedMatches() {

    const [modal, contextHolder] = Modal.useModal();

    const [loading, setLoading] = useState(true);
    const [displayData, setDisplayData] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    
    const [importScorecardModalOpen, setImportScorecardModalOpen] = useState(false);
    const [matchImportRulesModalOpen, setMatchImportRulesModalOpen] = useState(false);

    const { adminData } = useAdmin();
    const navigate = useNavigate();

    const getImportedMatches = async () => {
        try {
            const result = await api.admin.getImportedMatches();

            setDisplayData(result.data);
            
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setLoading(false);
    }

    const handleChange = async (recordId, checked) => {

        setIsUpdating(true);

        try {
            await api.admin.updateMatch(recordId, checked);

            const editedDataToUpdate = [...displayData];

            const index = editedDataToUpdate.findIndex(item => recordId === item.id);

            if (index > -1) {
                const item = editedDataToUpdate[index];
                const editedRow = { ...item, ...{ import: checked } };

                editedDataToUpdate.splice(index, 1, editedRow);
            }

            setDisplayData(editedDataToUpdate);

            message.success('Match successfully edited');
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setIsUpdating(false);
    }

    const handleDeleteMatchOnOK = async (matchId) =>  {
        setIsUpdating(true);

        try {
            await api.admin.deleteMatch(matchId);

            const deletedDataToUpdate = displayData;

            const index = deletedDataToUpdate.findIndex(item => matchId === item.id);

            if (index > -1) {
                deletedDataToUpdate.splice(index, 1);
            }

            setDisplayData(deletedDataToUpdate);

            message.success('Match deleted');
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setIsUpdating(false);
    }

    const handleDeleteModal = async (matchId) => {
        modal.confirm({
            title: 'Are you sure you want to delete this match?',
            icon: <ExclamationCircleFilled />,
            onOk: () => handleDeleteMatchOnOK(matchId),
            okType: 'danger'
        });
    }

    const handleImportMatchSuccess = (importedMatch) => {

        const displayDataToUpdate = displayData;

        displayDataToUpdate.push(importedMatch);
        setDisplayData(displayDataToUpdate);

        setImportScorecardModalOpen(false);
    }

    const handleReImportMatch = async (matchId) => {

        setIsUpdating(true);

        try {
            await api.admin.reImportMatch(matchId);

            message.success('Match successfully reimported');
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setIsUpdating(false);
    }

    const columns = [
        {
            title: 'Gameweek',
            dataIndex: 'gameweek',
            key: 'gameweek',
            render: (_, record) => {
                return record.import ? record.gameweek : <span style={styles.exclude}>{record.gameweek}</span>
            },
            width: 120
        },
        {
            title: 'Home Team',
            dataIndex: 'homeClub',
            key: 'homeClub',
            render: (_, record) => {
                return <span style={record.import ? {} : styles.exclude}>{record.homeClub ?? 'Unknown'}</span>
            }
        },
        {
            title: 'Away Team',
            dataIndex: 'awayClub',
            key: 'awayClub',
            render: (_, record) => {
                return <span style={record.import ? {} : styles.exclude}>{record.awayClub ?? 'Unknown'}</span>
            }
        },
        {
            title: 'Match Date',
            dataIndex: 'matchDate',
            key: 'matchDate',
            render: (_, record) => {
                const date = record.matchDate ? new Date(record.matchDate) : null;
                return <span style={record.import ? {} : styles.exclude}>{date ? date.toDateString() : 'Unknown'}</span>
            }
        },
        {
            title: 'Match Type',
            dataIndex: 'matchType',
            key: 'matchType',
            render: (_, record) => {
                return record.import ? record.matchType : <span style={styles.exclude}>{record.matchType}</span>
            }
        },
        {
            title: 'Included',
            dataIndex: 'import',
            key: 'import',
            render: (_, record) => {
                const includeExclude = record.import ? "exclude" : "include";
                return (
                    <Tooltip title={`Toggle to ${includeExclude} match`}>
                        <Switch 
                            loading={isUpdating}
                            checkedChildren="Included"
                            unCheckedChildren="Excluded"
                            size="small" 
                            style={{marginRight: 8}} 
                            defaultChecked={record.import} 
                            onChange={(checked) => handleChange(record.id, checked)} 
                            />
                    </Tooltip>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            render: (_, record) => (
                <EditControls 
                    isUpdating={isUpdating}
                    record={record} 
                    additionalActions={[
                        {
                            title: "Reimport match",
                            label: 'Reimport Match',
                            onClick: () => handleReImportMatch(record.matchPcId),
                            icon: <RedoOutlined />,
                            disabled: !record.import || ! adminData.hasAutoUpdate()
                        },
                        {
                            title: 'Edit Match',
                            label: 'Edit Match',
                            icon: <EditOutlined />,
                            disabled: ! record.import,
                            onClick: () => navigate( adminData.hasAutoUpdate() ? `/admin/edit-match-data/${record.id}` : `/admin/manual-import-match/${record.id}`),
                        },
                        {
                            title: 'Delete Match',
                            label: 'Delete Match',
                            icon: <DeleteOutlined />,
                            onClick: () => handleDeleteModal(record.id),
                            danger: true
                        }
                    ]}
                    />
            ),
            width: 100
        }
    ];

    const menuItems = [
        {
            key: 'see-rules',
            label: "Manage Import Rules",
            onClick: () => setMatchImportRulesModalOpen(true)
        }
    ];

    const getPageTitleExtras = () => {
        let extrasToReturn = [
            <Button 
                key="importScorecard"
                type="primary" 
                onClick={adminData.hasAutoUpdate() ? 
                    () => setImportScorecardModalOpen(true) :
                    () => navigate('/admin/manual-import-match')
                }
                disabled={adminData.seasonHasEnded()}
                >
                Import Match
            </Button>
        ];

        if (adminData.hasAutoUpdate()) {
            extrasToReturn.push(
                <Dropdown
                    key="moreItems"
                    placement="bottomRight"
                    trigger="click"
                    menu={{ items: menuItems }}
                    >
                    <Button shape="circle" icon={<MoreOutlined />} />
                </Dropdown>
            );
        }

        return extrasToReturn;
    }

    useEffect(() => {
        getImportedMatches();
    }, []);

    return (
        <>
            {contextHolder}
            <PageTitle 
                title="Imported Matches" 
                extra={getPageTitleExtras()}
                breadcrumbs={<AdminBreadcrumbs title="Imported Matches" />}
            />

            {loading ? <Loading /> : (
                <Table 
                    loading={isUpdating}
                    columns={columns} 
                    dataSource={[...displayData]} 
                    rowKey={record => record.id} 
                    pagination={{ pageSize: 20, size: "small" }}
                    bordered
                    scroll={{ x: true }}
                    showSorterTooltip={false}
                    sticky
                />
            )}

            <ImportScorecard 
                visible={importScorecardModalOpen} 
                handleSuccess={(importedMatch) => handleImportMatchSuccess(importedMatch)} 
                handleClose={() => setImportScorecardModalOpen(false)}
                />
            <MatchImportRules 
                isVisible={matchImportRulesModalOpen} 
                handleClose={() => setMatchImportRulesModalOpen(false)} 
                />
        </>
    )
}

export default ImportedMatches;