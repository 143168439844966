import React from 'react';
import { Row, Spin, Button, Result } from 'antd';
import { ConfigProvider } from 'antd';
import { StyleProvider } from '@ant-design/cssinjs';

import useAdmin from "hooks/useAdmin";
import useUser from "hooks/useUser";

import { newShade } from 'helpers/colourHelper';

const AppWrapper = ({ children }) => {

    const { adminData, adminLoading, adminError, adminRefetch } = useAdmin();
    const { userData, loggedOut } = useUser();

    if (adminError) {
        return (
            <>
                <Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}}>
                    <Result
                        status="error"
                        title="500"
                        subTitle="Sorry, something went wrong."
                        extra={<Button type="text" onClick={adminRefetch} loading={adminLoading}>Try Again</Button>}
                    />
                </Row>
            </>
        );
    }

    return (
        <>
            {adminLoading || (! loggedOut && ! userData) ? (
                <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
                    <Spin />
                </Row>
            ) : (
                <StyleProvider hashPriority='high'>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorPrimary: adminData.getColor(),
                                colorLink: adminData.getColor(),
                                colorLinkHover: newShade(adminData.getColor(), 30),
                                colorLinkActive: adminData.getColor(),
                                fontFamily: '"Lato", Arial, sans-serif'
                            }
                        }}
                        >
                        {children}
                    </ConfigProvider>
                </StyleProvider>
            )}
        </>
    )
}

export { AppWrapper };