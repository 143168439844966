import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Avatar, Typography } from 'antd';

import useAdmin from "hooks/useAdmin";

const { Text } = Typography;

const PlayerInfo = ({player, showAvatar, showSellingPrice}) => {

    const { adminData } = useAdmin();

    return (
        <Row align="middle" gutter={8}>
            <Col>
                {showAvatar && <Avatar src={`/images/${player.positionId}.svg`} size={32} />}
            </Col>
            <Col>
                <Text strong style={{ marginBottom: 0 }}>{player.name}</Text>
                <br />
                
                <Text type="warning">{adminData.currency()}{showSellingPrice ? player.sellingPrice : player.adjustedValue}m</Text><Text type="secondary"> | {player.positionName}</Text>
            </Col>
        </Row>
    )
}

PlayerInfo.defaultProps = {
    showAvatar: true,
    showSellingPrice: false
}

PlayerInfo.propTypes = {
    player: PropTypes.object.isRequired,
    showAvatar: PropTypes.bool,
    showSellingPrice: PropTypes.bool
}

export default PlayerInfo;