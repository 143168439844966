const initialState = {
    teamData: [],
    transfersIn: [],
    weekCaptain: '',
    nextCaptain: '',
    nextPlayers: []
}

const reducerActions = {
    setTeamData: 'SET_TEAM_DATA',
    setWildcardGameweek: 'SET_WILDCARD_GAMEWEEK',
    setNextPlayers: 'SET_NEXT_PLAYERS',
    setNextCaptain: 'SET_NEXT_CAPTAIN',
    setTripleCaptainGameweek: 'SET_TRIPLE_CAPTAIN_GAMEWEEK',
    changeTeamName: 'CHANGE_TEAM_NAME'
}

function reducer(state, action) {

    switch (action.type) {
        case 'SET_TEAM_DATA':

            return {
                ...state, 
                teamData: action.payload.teamData,
                transfersIn: action.payload.transfersIn,
                weekCaptain: action.payload.weekCaptain
            }
        
        case 'SET_WILDCARD_GAMEWEEK':
            state.teamData['wildcardGameweek'] = action.payload;
            return {
                ...state, 
                teamData: state.teamData
            }

        case 'SET_TRIPLE_CAPTAIN_GAMEWEEK':
            state.teamData['tripleCaptainGameweek'] = action.payload;
            return {
                ...state, 
                teamData: state.teamData
            }
        
        case 'SET_NEXT_CAPTAIN':
            const newTeamDataForChangingCaptain = state.teamData.teamPlayers;
            
            newTeamDataForChangingCaptain.map((player) => {
                player.captain = false;

                if (player.id === action.payload) {
                    player.captain = true;
                }
            });

            const newTeamData = {...state.teamData, ... newTeamDataForChangingCaptain}

            return {
                ...state, 
                teamData: newTeamData
            }

        case 'SET_NEXT_PLAYERS':
            let captain = null;

            action.payload.map((player) => {
                if (player.captain) {
                    captain = player.id;
                }
            });

            return {
                ...state, 
                nextPlayers: action.payload,
                nextCaptain: captain
            }

        case 'CHANGE_TEAM_NAME':

            const updatedTeamData = {...state.teamData, ...{name: action.payload.newTeamName}};

            return {
                ...state,
                teamData: updatedTeamData
            }

        default:
            return state;
    }
};

export { reducer, reducerActions, initialState };