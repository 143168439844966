import React, { useEffect, useState } from 'react';
import { Layout, Typography, Row, Col, Button, Drawer, theme, Menu, notification } from 'antd';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

import TopBar from './components/TopBar';

import { capitalize } from 'helpers/textHelper';

import useScreenSize from "hooks/useScreenSize";
import useNavigation from "hooks/useNavigation";
import useAdmin from "hooks/useAdmin";

import styles from './styles';

const { Content, Footer, Sider, Header } = Layout;
const { Title } = Typography;
const { useToken } = theme;

function MainLayout(props) {

    const [{ isMobile, isTabletOrMobile }] = useScreenSize();
    const [activeNavItem, { getUserMenu }] = useNavigation();

    const [api, contextHolder] = notification.useNotification();
    
    const { adminData } = useAdmin();
    const { token } = useToken();

    const [isCollapsed, setIsCollapsed] = useState(false);
    const [drawerIsVisible, setDrawerIsVisible] = useState(false);

    const handleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    }

    useEffect(() => {
        const showNotification = () => {
            const sportName = adminData.getSport();
            api['warning']({
                message: "Season Finshed!",
                description: <>Enjoyed using Fantasy Club {capitalize(sportName)}?</>,
                // closeIcon: true,
                duration: 12,
                btn: <Button type="link" href="https://www.fantasyclubsports.co.uk/sign-up" size='small'>Sign Up for next season</Button>,
                placement: 'bottomRight'
              });
        }

        if (adminData.seasonHasEnded()) {
            showNotification();
        }
        
    }, []);

    return (
        
        <Layout>
            {contextHolder}
            <Sider 
                width={isTabletOrMobile ? 0 : 200}
                collapsedWidth={isTabletOrMobile ? 0 : 80}
                collapsible={!isTabletOrMobile}
                onCollapse={handleCollapse} 
                theme="light"
                style={styles.sider}
                trigger={
                    <div style={{background: token.colorPrimary, color: 'white'}}>
                        {isCollapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
                    </div>
                }
                >
                <Link to="/" style={styles.logoLink}>
                    <img style={{ display: isCollapsed ? 'none' : 'block'}} src={`/images/${adminData.getSport()}/logo--header.png`} width='184px' height='53px' alt={`Fantasy Club ${capitalize(adminData.getSport())} logo`} />
                    <img style={{ display: isCollapsed ? 'block' : 'none'}} src={`/images/${adminData.getSport()}/logo--condensed.png`} width='64px' height='52px' alt={`Fantasy Club ${capitalize(adminData.getSport())} logo`} />
                </Link>
                <Menu 
                    mode="inline" 
                    selectedKeys={activeNavItem}
                    items={getUserMenu({})}
                />
            </Sider>
            <Drawer
                width={300}
                title={<img src={`/images/${adminData.getSport()}/logo--header.png`} width='160px' height='46px' alt={`Fantasy Club ${capitalize(adminData.getSport())} logo`} />}
                placement="left"
                onClose={() => setDrawerIsVisible(false)}
                open={drawerIsVisible}
                >
                <Menu 
                    mode="inline" 
                    selectedKeys={activeNavItem}
                    items={getUserMenu({ handleItemSelect: () => setDrawerIsVisible(false) })}
                />
            </Drawer>

            <Layout 
                className="site-layout"
                style={{ 
                    marginLeft: isTabletOrMobile ? 0 : isCollapsed ? 80 : 200, 
                    transition: 'all 0.2s' 
                }}
                >
                <Header 
                    className="header" 
                    style={{
                        padding: isTabletOrMobile ? '0 12px' : '0 32px',
                        background: token.colorPrimary
                    }}
                    >
                    <TopBar 
                        handleToggleDrawerNav={() => setDrawerIsVisible(!drawerIsVisible)} 
                        showToggle={isTabletOrMobile} 
                        />
                </Header>

                <div style={styles.welcome(isTabletOrMobile, adminData.getSport())}>

                    <Row type="flex" justify="center">
                        <Col>
                            <Title 
                                level={isTabletOrMobile ? 3 : 2} 
                                style={styles.title}
                                >
                                {`Welcome to the ${adminData.getClubName()} Fantasy League`}
                            </Title>
                        </Col>
                        
                    </Row>
                </div>

                <Content 
                    className="site-layout-background"
                    style={styles.content(isTabletOrMobile)}
                    >

                    <div style={{ maxWidth: 1200, margin: '0 auto' }}>
                        {props.children}
                    </div>
                </Content>

                <Footer>
                    <Row type="flex" justify="center" style={styles.terms}>
                        <Col>
                            <Button 
                                style={{ fontSize: 12 }}
                                size='small'
                                type="text" 
                                href="https://www.fantasyclubsports.co.uk/privacy"
                                target="_blank"
                                block={isMobile}
                                    >
                                    Privacy Policy
                            </Button>

                            <Button
                                style={{ fontSize: 12 }}
                                size='small'
                                type="text"
                                href="https://www.fantasyclubsports.co.uk/terms"
                                target="_blank"
                                block={isMobile}
                                    >
                                    Terms of Use
                            </Button> 

                            <Button
                                size='small'
                                style={{ fontSize: 12 }}
                                type="text"
                                href="https://www.fantasyclubsports.co.uk/"
                                target="_blank"
                                block={isMobile}
                                    >
                                    Fantasy Sports for your Club
                            </Button>
                        </Col>
                    </Row>
                </Footer>

            </Layout>
        </Layout>
    )
}

export default MainLayout;