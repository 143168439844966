const initialState = {
    playerData: {},
    playerPriceChangeData: {},
    gameweekData: [],
    gameweekDataBeingEdited: null
}

const reducerActions = {
    setPlayerData: 'SET_PLAYER_DATA',
    setPlayerPriceChangeData: 'SET_PLAYER_PRICE_CHANGE_DATA',
    setGameweekData: 'SET_GAMEWEEK_DATA',
    setGameweekDataBeingEdited: 'SET_GAMEWEEK_DATA_BEING_EDITED',
    updateEditedGameweekData: 'UPDATE_EDITED_GAMEWEEK_DATA',
    updatePlayerPriceChangeData: 'UPDATE_PLAYER_PRICE_CHANGE_DATA'
}

function reducer(state, action) {

    switch (action.type) {
        case 'SET_PLAYER_DATA':
            return {
                ...state, 
                playerData: action.payload
            }

        case 'SET_PLAYER_PRICE_CHANGE_DATA':
            return {
                ...state, 
                playerPriceChangeData: action.payload
            }

        case 'SET_GAMEWEEK_DATA':
            return {
                ...state, 
                gameweekData: action.payload
            }

        case 'SET_GAMEWEEK_DATA_BEING_EDITED':

            const gameweekDataBeingEdited = state.gameweekData.filter(match => match.matchInfo.id === action.matchId); 

            return {
                ...state, 
                gameweekDataBeingEdited: gameweekDataBeingEdited[0]
            }
        case 'UPDATE_EDITED_GAMEWEEK_DATA':

            const gameweekDataBeingEditedUpdated = {...state.gameweekDataBeingEdited, ...action.payload};
            const index = state.gameweekData.findIndex(match => state.gameweekDataBeingEdited.matchInfo.id === match.matchInfo.id);

            if (index > -1) {
                state.gameweekData.splice(index, 1, gameweekDataBeingEditedUpdated);
            }

            return {
                ...state
            }

        case 'UPDATE_PLAYER_PRICE_CHANGE_DATA':
            const playerPriceChangeDataUpdated = state.playerPriceChangeData.find(priceChange => priceChange.gameweek === action.payload.gameweek);
            const playerPriceChangeIndex = state.playerPriceChangeData.findIndex(priceChange => priceChange.gameweek === action.payload.gameweek);

            if (playerPriceChangeIndex > -1) {
                state.playerPriceChangeData.splice(playerPriceChangeIndex, 1, {...playerPriceChangeDataUpdated, ...{ priceChange: action.payload.priceChange }});
            } else {
                // it doesn't exist, add it
                state.playerPriceChangeData.push({
                    gameweek: action.payload.gameweek,
                    priceChange: action.payload.priceChange
                });
            }

            return {
                ...state
            }

    }
}

export { reducer, reducerActions, initialState };