import React, { useState, useEffect } from 'react';

import { api } from 'api';
import { Teamlist } from 'components';

const SLICE = 3;

const TeamStandings = () => {

    const [teamlistData, setTeamlistData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getTeamListData = async (year) => {
        try {
            const results = await api.team.getTeamList(year);
            const teamlist = results.data;

            const teamlistSorted = teamlist.sort((a, b) => b.totalPoints - a.totalPoints);
            const teamlistSliced = teamlistSorted.slice(0, SLICE);
            
            setTeamlistData(teamlistSliced);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        getTeamListData('current');
    }, []);

    return <Teamlist teamlistData={teamlistData} isLoading={loading} size='small' />
}

export default TeamStandings;