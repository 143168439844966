export default class CricketPoints {

    calculatePointsForPosition(pointsStructure, formData)
    {
        let total = 0;

        // Runs
        const pointsPerRun = pointsStructure.structure.filter(structure => structure.name === 'run')[0]['points'];
        const runPoints = pointsPerRun * formData.runs;
        total += runPoints;

        // Wickets
        const pointsPerWicket = pointsStructure.structure.filter(structure => structure.name === 'wicket')[0]['points'];
        const wicketPoints = pointsPerWicket * formData.wickets;
        total += wicketPoints;

        // Catches
        const pointsPerCatch = pointsStructure.structure.filter(structure => structure.name === 'catch')[0]['points'];
        const catchPoints = pointsPerCatch * formData.catches;
        total += catchPoints;

        // Run Outs
        const pointsPerRunOut = pointsStructure.structure.filter(structure => structure.name === 'runOut')[0]['points'];
        const runOutPoints = pointsPerRunOut * formData.runOuts;
        total += runOutPoints;

        // Stumpings
        const pointsPerStumping = pointsStructure.structure.filter(structure => structure.name === 'stumping')[0]['points'];
        const stumpingPoints = pointsPerStumping * formData.stumpings;
        total += stumpingPoints;

        // Maidens
        const pointsPerMaiden = pointsStructure.structure.filter(structure => structure.name === 'maiden')[0]['points'];
        const maidenPoints = pointsPerMaiden * formData.maidens;
        total += maidenPoints;

        // ----- ##### B.O.N.U.S P.O.I.N.T.S ##### -----

        // notOut
        const pointsPerNotOut = pointsStructure.structure.filter(structure => structure.name === 'notOut')[0]['points'];
        const notOutPoints = formData.notOut ? pointsPerNotOut : 0;
        total += notOutPoints;

        // battingBonuses
        const centuryBonus = pointsStructure.bonus.filter(bonus => bonus.name === 'centuryBonus')[0];
        const halfCenturyBonus = pointsStructure.bonus.filter(bonus => bonus.name === 'halfCenturyBonus')[0];

        if (formData.runs >= centuryBonus.achievement) {
            const centuryBonusPoints = centuryBonus.points;
            total += centuryBonusPoints;
        } else if (formData.runs >= 50) {
            const halfCenturyBonusPoints = halfCenturyBonus.points;
            total += halfCenturyBonusPoints;
        } else if (formData.runs === 0 && (!formData.notOut && !formData.dnb)) {
            const duckBonusPoints = pointsStructure.bonus.filter(bonus => bonus.name === 'duckBonus')[0]['points'];
            total += duckBonusPoints;
        }

        // bowlingBonuses
        const fiftyRunsAgainstBonus = pointsStructure.bonus.filter(bonus => bonus.name === 'fiftyRunsAgainstBonus')[0];
        const fiveWicketsBonus = pointsStructure.bonus.filter(bonus => bonus.name === 'fiveWicketsBonus')[0];

        if (formData.runsAgainst >= fiftyRunsAgainstBonus.achievement && formData.wickets === 0) {
            const fiftyRunsAgainstBonusPoints = fiftyRunsAgainstBonus.points;
            total += fiftyRunsAgainstBonusPoints;
        }

        if (formData.wickets >= fiveWicketsBonus.achievement) {
            const fiveWicketsBonusPoints = fiveWicketsBonus.points;
            total += fiveWicketsBonusPoints;
        }

        return total;
    }
}