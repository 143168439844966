import React, { useState, useEffect } from 'react';
import { Button, Typography, Result, message, Row } from 'antd';

import dropin from "braintree-web-drop-in";

import { Loading, PageTitle } from 'components';
import { api } from 'api';
import { getErrorMessage } from 'helpers/feedbackMessageHelper';

import useUser from "hooks/useUser";
import useAdmin from "hooks/useAdmin";

const CardPayment = (handleCompletedPayment) => {

    const { adminData } = useAdmin();

    const [braintreeInstance, setBraintreeInstance] = useState(undefined);
    const [somethingIsWrong, setSomethingIsWrong] = useState(false);
    const [loading, setLoading] = useState(true);
    const [paymentProcessing, setPaymentProcessing] = useState(false);

    const { userData, mutateUser } = useUser();

    useEffect(() => {
        const initializeBraintree = async () => {

            try {
                const result = await api.payment.getPaymentToken();

                dropin.create({
                    authorization: result.data.token, 
                    container: '#braintree-drop-in-div',
                    threeDSecure: true
                }, function (error, instance) {
                    if (error) {
                        console.error('ERROR_6', error);
                        setSomethingIsWrong(true);
                    } else {
                        setLoading(false);
                        setBraintreeInstance(instance);
                    }
                        
                });

            } catch(error) {
                console.error('ERROR_5');
                setSomethingIsWrong(true);
                setLoading(false);
            }
        }

        if (braintreeInstance) {
            braintreeInstance
                .teardown()
                .then(() => {
                    initializeBraintree();
                });
        } else {
            initializeBraintree();
        }
    }, []);

    const handlePayment = () => {
        if (braintreeInstance) {
            setPaymentProcessing(true);

            braintreeInstance.requestPaymentMethod({
                threeDSecure: {
                    amount: adminData.getEntryFee(),
                    email: userData.email
                }
            }, async (error, payload) => {
                if (error) {
                    console.error('ERROR_3');

                    message.error("Error: " + error);
                    setPaymentProcessing(false);
                } else {
                    const paymentMethodNonce = payload.nonce;

                    try {
                        const result = await api.payment.generateTransaction(paymentMethodNonce);

                        mutateUser({ 
                            ...userData, 
                            ...{ paymentReference: result.data.paymentReference } 
                        });

                        message.success("Payment successfully made. Thank You.");

                    } catch(error) {
                        console.log('ERROR_13', error);
                        const errorMessage = error.response ? error.response.data.error : 'generic';
                        message.error(getErrorMessage(errorMessage));
                    }

                    setPaymentProcessing(false);
                }
            })

        }
    }

    return (
        <>
            <PageTitle title="Entry Fee Payment" />
            <Typography.Paragraph>
                You must submit payment online before you can enter a team into the {adminData.getClubName()} fantasy league. Team entry fee has been set at <strong>£{adminData.getEntryFee()}</strong>, please pay using the form below:
            </Typography.Paragraph>

            <div id="braintree-drop-in-div" />

            {loading ? (
                <Loading />
            ) : (
                <>
                {somethingIsWrong ? (
                    <Result
                        status="warning"
                        title="Something went wrong"
                        />
                    ) : (
                        <Row type='flex' justify='end'>
                            <Button
                                type="primary"
                                onClick={handlePayment}
                                loading={paymentProcessing}
                                >
                                Submit
                            </Button>
                        </Row>
                    )}
                </>
            )}
        </>
    )
}

export default CardPayment;