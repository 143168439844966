import 'utils/polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactGA from "react-ga4";

import reportWebVitals from './reportWebVitals';

import App from './App';

Sentry.init({
  dsn: "https://03c44169ddab4a669f1294e9aa782cea@o1358751.ingest.sentry.io/4504418120892416",
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactGA.initialize("G-6D96X1GSYQ", { testMode: process.env.NODE_ENV === 'development' });

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
