import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Row, Col, Table, List, Button, Select, Avatar, Modal, Alert, theme, Flex, Space } from 'antd';
import { DeleteOutlined, PlusOutlined, SwapOutlined } from '@ant-design/icons';

import useAdmin from "hooks/useAdmin";

import { PlayerInfo } from 'components';

import useScreenSize from "hooks/useScreenSize";

import styles from './styles';

const { Title, Text } = Typography;
const { Option } = Select;

const { useToken } = theme;

const SelectTeam = ({ 
    playerList,
    handleChange,
    alreadySelectedPlayers,
    errorMessages,
    footer,
    showSellingPrice,  
    latestMatchesWithPlayers
    }) => {

    const { token } = useToken();

    const [{ isMobile }] = useScreenSize();

    const [initiallySelectedPlayers, setInitiallySelectedPlayers] = useState([]);
    const [selectedPlayersIsVisible, setSelectedPlayersIsVisible] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [columns, setColumns] = useState([]);
    const [filteredInfo, setFilteredInfo] = useState({positionName: []});
    const [sortedInfo, setSortedInfo] = useState("value");
    const [maxFilteredPrice, setMaxFilteredPrice] = useState("Unlimited");
    const [maxPlayerValue, setMaxPlayerValue] = useState(10);

    const { adminData } = useAdmin();

    const positions = adminData.getPositionNames();

    const calculateColumns = (sortedValue, filteredValue, maxFilteredPrice) => {

        setColumns([
            {
                title: '',
                dataIndex: 'positionName',
                key: 'position',
                filteredValue: filteredValue.positionName || null,
                onFilter: (value, record) => record.positionName.indexOf(value) === 0,
                render: (_, record) => adminData.sportIsCricket() ? 
                    <Avatar src={`/images/${record.positionId}.svg`} size="small" /> :
                    record.positionName,
                align: 'right',
                shouldCellUpdate: () => false
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => a.lastName.localeCompare(b.lastName),
                sortOrder: sortedValue === 'lastName' ? 'ascend' : false,
                render: (_, item) => (
                    <PlayerInfo player={item} showAvatar={false} />
                ),
                shouldCellUpdate: () => false
            },
            {
                title: 'Value',
                dataIndex: 'adjustedValue',
                key: 'adjustedValue',
                sorter: (a, b) => a.adjustedValue - b.adjustedValue,
                filteredValue: maxFilteredPrice === 'Unlimited' ? null : [maxFilteredPrice],
                onFilter: (value, record) => value !== 'Unlimited' && record.adjustedValue <= value,
                sortOrder: sortedValue === 'value' ? 'descend' : false,
                render: (text) => (`${adminData.currency()}${text}m`),
                shouldCellUpdate: () => false,
            },
            {
                title: 'Value',
                dataIndex: 'adjustedValue',
                key: 'adjustedValue',
                render: (text) => (`${adminData.currency()}${text}m`),
                hidden: sortedValue !== 'value',
                shouldCellUpdate: () => false,
            },
            {
                title: 'Selected By (%)',
                dataIndex: 'selectedBy',
                key: 'selectedBy',
                sorter: (a, b) => a.selectedBy - b.selectedBy,
                sortOrder: sortedValue === 'selectedBy' ? 'descend' : false,
                render: (number) => (`${number}%`),
                hidden: sortedValue !== 'selectedBy',
                shouldCellUpdate: () => false
            },
            {
                title: 'Form',
                dataIndex: 'formAverage',
                key: 'formAverage',
                sorter: (a, b) => a.formAverage - b.formAverage,
                sortOrder: sortedValue === 'formAverage' ? 'descend' : false,
                hidden: sortedValue !== 'formAverage',
                shouldCellUpdate: () => false
            },
            {
                title: 'Total Points',
                dataIndex: 'totalPoints',
                key: 'totalPoints',
                sorter: (a, b) => a.totalPoints - b.totalPoints,
                sortOrder: sortedValue === 'totalPoints' ? 'descend' : false,
                hidden: sortedValue !== 'totalPoints',
                shouldCellUpdate: () => false
            },
            {
                title: 'Week Points',
                dataIndex: 'weekPoints',
                key: 'weekPoints',
                sorter: (a, b) => a.weekPoints - b.weekPoints,
                sortOrder: sortedValue === 'weekPoints' ? 'descend' : false,
                hidden: sortedValue !== 'weekPoints',
                shouldCellUpdate: () => false
            }
        ].filter(item => !item.hidden));
    };

    useMemo(() => calculateColumns(
        sortedInfo, 
        { positionName: filteredInfo },
        maxFilteredPrice
    ), [sortedInfo, filteredInfo, maxFilteredPrice]);

    const handlePlayerRemove = (playerId) => {
        const selectedPlayersUpdated = alreadySelectedPlayers.filter(player => player.id !== playerId);
        handleChange(selectedPlayersUpdated);
    };

    const handlePlayerAdd = (record) => {
        const playerIsNextPlayer = initiallySelectedPlayers.find(player => player.id === record.id);
        const recordToAdd = typeof playerIsNextPlayer !== 'undefined' ? playerIsNextPlayer : record;

        const selectedPlayersCopy = [...alreadySelectedPlayers, recordToAdd];
        handleChange(selectedPlayersCopy);
    }

    const calculatePriceBrackets = () => {

        let maxValue = 0;

        Object.values(playerList).map((el) => {
            const valueFromObject = el.adjustedValue;
            maxValue = Math.max(maxValue, valueFromObject);
        });

        setMaxPlayerValue(maxValue);
    }

    useEffect(() => {
        setInitiallySelectedPlayers(alreadySelectedPlayers);
        calculateColumns('value', [], 'Unlimited');
        calculatePriceBrackets();
    }, []);

    useEffect(() => {
        setSelectedRowKeys(Array.from(alreadySelectedPlayers).map(player => player.id));
    }, [alreadySelectedPlayers]);

    const handleClickRow = (record, index) => {
        return {
            onClick: (event) => {
                let selectedPlayersCopy = alreadySelectedPlayers;
                const currentIndex = selectedPlayersCopy.findIndex(player => player.id === record.id);

                currentIndex !== -1 ? handlePlayerRemove(record.id) : handlePlayerAdd(record);
            }
        }
    }

    const handleClickAddPlayer = (positionName) => {
        setFilteredInfo(Array.of(positionName));
        setSelectedPlayersIsVisible(true);
    }

    const handleQuickSelect = (selected) => {
        const selectedPlayersFromList = latestMatchesWithPlayers.find(latestMatch => latestMatch.match.id === selected.value);
        handleChange(selectedPlayersFromList.players);
    }

    return (
        <>
            {errorMessages?.length > 0 && (
                <Col xs={24}>
                    <Alert 
                        key={errorMessages[0].key}
                        style={{ marginBottom: 16 }}
                        message={errorMessages[0].message} 
                        type="error" 
                        showIcon 
                        closable
                        />
                </Col>
            )}
            <Col xs={24} style={{ marginBottom: 16 }}>
                <Flex
                    gap="middle"
                    vertical={isMobile}
                    justify="space-between"
                    align={isMobile ? "unset" : "center"}
                    >
                    <Title level={4} style={{ marginBottom: 0 }}>Selected Players:</Title>
                    {latestMatchesWithPlayers && (
                        <Space.Compact size="small" direction="vertical">
                            <Text>Quick select:</Text>
                            <Select
                                placeholder="Quick select from match..."
                                labelInValue={true}
                                onChange={handleQuickSelect}
                                options={latestMatchesWithPlayers.map(latestMatch => (
                                    {
                                        'key': latestMatch.match.id,
                                        'value': latestMatch.match.id,
                                        'label': `${latestMatch.match.homeClub} vs ${latestMatch.match.awayClub}`
                                    }
                                ))}
                                />
                        </Space.Compact>
                    )}
                </Flex>
                
                {positions.map((position) => 
                    <>
                        <List
                            key={position}
                            size="small"
                            header={<Title level={5}>{position}s</Title>}
                            itemLayout="horizontal"
                            dataSource={alreadySelectedPlayers.filter(player => player.positionName === position)}
                            renderItem={player => {

                                const playerIsNotInitallySelected = initiallySelectedPlayers.findIndex(initiallySelectedPlayer => initiallySelectedPlayer.id === player.id) === -1;
                                let actions = [
                                    <Button 
                                        key='deletePlayer'
                                        danger={true}
                                        type="text" 
                                        size="small" 
                                        icon={<DeleteOutlined />} 
                                        onClick={() => handlePlayerRemove(player.id)}
                                        />
                                ];

                                if (playerIsNotInitallySelected) {
                                    actions = [
                                        <Button 
                                            key='transferredIn'
                                            style={{ color: token.colorSuccess }}
                                            type="text" 
                                            size="small" 
                                            icon={<SwapOutlined />} 
                                            />,
                                        ...actions
                                    ];
                                }

                                return (
                                    <>
                                        <List.Item
                                            key={player.id}
                                            actions={actions}
                                            >
                                            <PlayerInfo 
                                                player={player}
                                                showAvatar={adminData.sportIsCricket()}
                                                showSellingPrice={showSellingPrice && ! playerIsNotInitallySelected} 
                                                />
                                        </List.Item>
                                    </>
                                )
                            }}
                        />
                        <Button 
                            onClick={() => handleClickAddPlayer(position)} 
                            icon={<PlusOutlined />}
                            type="dashed"
                            block
                            >
                            Add Player
                        </Button>
                    </>
                )}
                
            </Col>

            <Modal 
                title="Select Players" 
                open={selectedPlayersIsVisible} 
                onCancel={() => setSelectedPlayersIsVisible(false)}
                onOk={() => setSelectedPlayersIsVisible(false)}
                width={650}
                centered={true}
                footer={
                    <Row type='flex' justify='space-between' align='middle'>
                        { footer ? footer : <div></div> }
                        <Button type='primary' onClick={() => setSelectedPlayersIsVisible(false)}>Done</Button>
                    </Row>
                }
                >
                <Row gutter={16}>
                    {errorMessages?.length > 0 && (
                        <Col xs={24}>
                            <Alert 
                                key={errorMessages[0].key}
                                style={{ marginBottom: 16 }}
                                message={errorMessages[0].message} 
                                type="error" 
                                showIcon 
                                closable
                                />
                        </Col>
                    )}
                                   
                    <Col xs={8}>
                        <label><Text type="secondary">Show Me:</Text></label>
                        <Select 
                            style={styles.selectFilter}
                            placeholder="Show Me" 
                            onChange={(value) => setFilteredInfo(value)}
                            value={filteredInfo}
                            mode="multiple"
                            allowClear
                            >
                                {positions.map(positionName => (
                                    <Option 
                                        key={positionName} 
                                        value={positionName}
                                        >
                                        {`${positionName}s`}
                                    </Option>
                                ))}
                        </Select>
                    </Col>
                    <Col xs={8}>
                        <label><Text type="secondary">Sorted By:</Text></label>
                        <Select 
                            style={styles.selectFilter}
                            placeholder="Sorted By" 
                            onChange={(value) => setSortedInfo(value)}
                            >
                            <Option key="value" value="value">Value</Option>
                            <Option key="selectedBy" value="selectedBy">% Selected</Option>
                            <Option key="formAverage" value="formAverage">Form</Option>
                            <Option key="totalPoints" value="totalPoints">Total Points</Option>
                            <Option key="weekPoints" value="weekPoints">Week Points</Option>
                            <Option key="lastName" value="lastName">Last Name</Option>
                        </Select>
                    </Col>
                    <Col xs={8}>
                        <label><Text type="secondary">Max Price:</Text></label>
                        <Select 
                            style={styles.selectFilter}
                            placeholder="Unlimited" 
                            onChange={(value) => setMaxFilteredPrice(value)}
                            >
                                <Option key="unlimited" value="Unlimited">Unlimited</Option>
                                {Array.from(Array(parseInt(maxPlayerValue +2)).keys()).reverse().map(priceValue => (
                                    <Option key={priceValue} value={priceValue}>{`${adminData.currency()}${priceValue}m`}</Option>
                                ))}
                        </Select>
                    </Col>
                </Row>
                
                <Table
                    className="hide-table-sort"
                    rowClassName={record => selectedRowKeys.includes(record.id) ? "select-team-row ant-table-row-selected" : "select-team-row"}
                    dataSource={[...playerList]}
                    columns={columns}
                    scroll={{ y: 400, x: true }}
                    pagination={false} 
                    rowKey={record => record.id}
                    size="small"
                    showSorterTooltip={false}
                    onRow={handleClickRow}
                    />
            </Modal>
        </>
    )
}

SelectTeam.defaultProps = {
    alreadySelectedPlayers: [],
    showSellingPrice: false,
    latestMatchesWithPlayers: null
}

SelectTeam.propTypes = {
    playerList: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    alreadySelectedPlayers: PropTypes.array,
    errorMessages: PropTypes.array,
    footer: PropTypes.node,
    showSellingPrice: PropTypes.bool,
    latestMatchesWithPlayers: PropTypes.array
}

export default SelectTeam;