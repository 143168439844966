export default class FootballMatchData {

    getPositions()
    {
        return ['Goalkeeper', 'Defender', 'Midfielder', 'Striker'];
    }

    getFields()
    {
        return [
            {
                name: "matchStarted",
                label: "Match Started",
                type: "checkbox",
                initialValue: false,
                selectAll: true
            },
            {
                name: "subAppearance",
                label: "Sub Appearance",
                type: "checkbox",
                initialValue: false,
                selectAll: false
            },
            {
                name: "yellowCard",
                label: "Yellow Card",
                type: "checkbox",
                initialValue: false,
                selectAll: false
            },
            {
                name: "redCard",
                label: "Red Card",
                type: "checkbox",
                initialValue: false,
                selectAll: false
            },
            {
                name: "goalsScored",
                label: "Goals Scored",
                type: "number",
                initialValue: null,
                selectAll: false
            },
            {
                name: "assists",
                label: "Assists",
                type: "number",
                initialValue: null,
                selectAll: false
            },
            {
                name: "goalsConceded",
                label: "Goals Conceded",
                type: "number",
                initialValue: null,
                selectAll: false
            },
            {
                name: "cleanSheet",
                label: "Clean Sheet",
                type: "checkbox",
                initialValue: false,
                selectAll: true
            },
            {
                name: "mom",
                label: "Player of the Match",
                type: "checkbox",
                initialValue: false,
                selectAll: false
            },
        ]
    }

    getFieldsForUserDisplay() {
        let getFieldsForUserDisplay = [
            {
                name: "matchStarted",
                label: "Match Started",
                labelPlural: "Matches Started",
                type: "checkbox"
            },
            {
                name: "subAppearance",
                label: "Sub Appearance",
                labelPlural: "Sub Appearances",
                type: "checkbox"
            },
            {
                name: "yellowCard",
                label: "Yellow Card",
                labelPlural: "Yellow Cards",
                type: "checkbox"
            },
            {
                name: "redCard",
                label: "Red Card",
                labelPlural: "Red Cards",
                type: "checkbox"
            },
            {
                name: "goalsScored",
                label: "Goals Scored",
                labelPlural: "Goals Scored",
                type: "number"
            },
            {
                name: "assists",
                label: "Assists",
                labelPlural: "Assists",
                type: "number"
            },
            {
                name: "goalsConceded",
                label: "Goals Conceded",
                labelPlural: "Goals Conceded",
                type: "number"
            },
            {
                name: "cleanSheet",
                label: "Clean Sheet",
                labelPlural: "Clean Sheets",
                type: "checkbox"
            },
            {
                name: "mom",
                label: "Player of the Match",
                labelPlural: "POM Awards",
                type: "checkbox"
            },
        ];

        return getFieldsForUserDisplay;
    }
}