import breakpoints from 'constants/breakpoints';
import { useMediaQuery } from 'react-responsive';

const useScreenSize = () => {

    const isTabletOrMobile = useMediaQuery({ maxWidth: breakpoints.LARGE });
    const isMobile = useMediaQuery({ maxWidth: breakpoints.SMALL });
    const isTablet = useMediaQuery({ maxWidth: breakpoints.MEDIUM });

    return [
        {
            isTabletOrMobile,
            isMobile,
            isTablet
        }
    ];
}

export default useScreenSize;