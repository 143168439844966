const styles = {
    card: {
        marginBottom: 16
    },
    destructiveButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    breadcrumb: {
        marginBottom: 0
    }
}

export default styles;