const initialState = {
    selectedPlayers: [],
    playerData: [],
    cashRemain: 0,
    profitLoss: 0,
    teamName: '',
    captain: null,
    hasErrors: true,
    nextPlayers: [],
    requestedTransfers: [],
    transfersAvailable: 0,
    transfersIncurringPenalties: 0,
    wildcardGameweek: null,
    forTransfers: false
}

const reducerActions = {
    selectPlayer: 'SELECT_PLAYER',
    setPlayerData: 'SET_PLAYER_DATA',
    setCashRemain: 'SET_CASH_REMAIN',
    setProfitLoss: 'SET_PROFIT_LOSS',
    chooseCaptain: 'CHOOSE_CAPTAIN',
    setHasErrors: 'SET_HAS_ERRORS',
    setTeamName: 'SET_TEAM_NAME',
    setNextPlayers: 'SET_NEXT_PLAYERS', // current players set in team for next game week,
    isReady: 'IS_READY',
    setTransfersAvailable: 'SET_TRANSFERS_AVAILABLE',
    setWildcardGameweek: 'SET_WILDCARD_GAMEWEEK',
    setForTransfers: 'SET_FOR_TRANSFERS'
}

function reducer(state, action) {

    switch (action.type) {
        case 'SELECT_PLAYER':

            // work out how many are different to 'nextPlayers'
            const playersIn = action.payload.selectedPlayers.filter(player => { 
                return !state.nextPlayers.some(nextPlayer => nextPlayer.id === player.id); 
            });

            const playersOut = state.nextPlayers.filter(nextPlayer => { 
                return !action.payload.selectedPlayers.some(player => player.id === nextPlayer.id); 
            });

            const requestedTransfers = playersIn.map((player, index) => {
                return {
                    'playerIn': {
                        'name': player.name,
                        'positionId': player.positionId,
                        'adjustedValue': player.adjustedValue,
                        'id': player.id,
                        'positionName': player.positionName
                    },
                    'playerOut': {
                        'name': playersOut[index]?.name,
                        'positionId': playersOut[index]?.positionId,
                        'adjustedValue': playersOut[index]?.adjustedValue,
                        'id': playersOut[index]?.id,
                        'positionName': playersOut[index]?.positionName
                    }
                }
            });

            const transfersIncurringPenalties = state.transfersAvailable === 'Unlimited' ? 0 : requestedTransfers.length - state.transfersAvailable;

            return { 
                ...state,
                selectedPlayers: action.payload.selectedPlayers,
                requestedTransfers: requestedTransfers,
                transfersIncurringPenalties: transfersIncurringPenalties
            }

        case 'SET_PLAYER_DATA':
            return {
                ...state, 
                playerData: action.payload
            }

        case 'SET_NEXT_PLAYERS':
            return {
                ...state,
                nextPlayers: action.payload
            }

        case 'SET_CASH_REMAIN':
            return {
                ...state,
                cashRemain: action.payload
            }

        case 'SET_PROFIT_LOSS':
            return {
                ...state,
                profitLoss: action.payload
            }

        case 'CHOOSE_CAPTAIN':
            return {
                ...state,
                captain: action.payload
            }

        case 'SET_TEAM_NAME':
            return {
                ...state,
                teamName: action.payload
            }

        case 'SET_HAS_ERRORS':
            return {
                ...state,
                hasErrors: action.payload
            }

        case 'IS_READY':
            return {
                ...state,
                isReady: action.payload
            }

        case 'SET_TRANSFERS_AVAILABLE':
            return {
                ...state,
                transfersAvailable: action.payload
            }

        case 'SET_WILDCARD_GAMEWEEK':
            return {
                ...state,
                wildcardGameweek: action.payload
            }

        case 'SET_FOR_TRANSFERS':
            return {
                ...state,
                forTransfers: action.payload
            }

        default:
            return state;
    }
};

export { reducer, reducerActions, initialState };