import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Typography, Button, List, message, Row } from 'antd';

import { api } from 'api';
import { Loading } from 'components';
import { getErrorMessage } from 'helpers/feedbackMessageHelper';

const ImportedFixtures = () => {

    const [loading, setLoading] = useState(true);
    const [importedMatchesData, setImportedMatchesData] = useState([]);

    const getImportedMatches = async () => {
        try {
            const result = await api.admin.getImportedMatches();
            setImportedMatchesData(result.data.filter((match) => match.import).slice(0, 5));
        } catch (error) {
            console.error(error);
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }
        
        setLoading(false);
    }

    useEffect(() => {
        getImportedMatches();
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <List
                bordered={false}
                dataSource={importedMatchesData}
                renderItem={item => {

                    const date = new Date(item.matchDate);

                    return (
                        <List.Item style={{ padding: '12px 0' }}>
                            <List.Item.Meta title={item.homeClub ?? 'Unknown'} description={`vs ${item.awayClub ?? 'Unknown'}`} />
                            <Typography.Text>{date.toDateString()}</Typography.Text>
                        </List.Item>
                    )
                }}
                />
            <Row type='flex' justify='end'>
                <Link to="/admin/imported-matches">
                    <Button style={{ marginTop: 12}} type="primary">View All</Button>
                </Link>
            </Row>
        </>
    )
}

export default ImportedFixtures;