import React, { useContext, useEffect, useState } from 'react';

import { FccSection, SelectTeam } from 'components';
import MatchInfoSummary from './MatchInfoSummary';

import { ManualImportMatchContext } from '../../ManualImportMatch';

import { reducerActions } from 'pages/CreateTeam/reducer';

import { getErrorMessage } from 'helpers/feedbackMessageHelper';

import { api } from 'api';

const SelectPlayers = () => {

    const [latestMatches, setLatestMatches] = useState(null);

    const { state, dispatch } = useContext(ManualImportMatchContext);

    const handleChange = (selectedPlayers) => {
        dispatch({
            type: reducerActions.selectPlayer, 
            payload: selectedPlayers
        });
    }

    const fetchLatestMatches = async () => {
        try {
            const latestMatches = await api.admin.getLatestMatchesWithPlayers();
            setLatestMatches(latestMatches.data);
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            console.error(getErrorMessage(errorMessage));
        }
    }

    useEffect(() => {
        fetchLatestMatches();
    }, []);

    return (
        <>
            <MatchInfoSummary />
            <FccSection>
                <SelectTeam 
                    playerList={state.playerData} 
                    handleChange={handleChange} 
                    alreadySelectedPlayers={state.selectedPlayers}
                    latestMatchesWithPlayers={latestMatches}
                    />
            </FccSection>
        </>
    )
}

export default SelectPlayers;