import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Table, Card, Skeleton, Typography, Affix, Row, Button, Result, message } from 'antd';
import { useParams } from "react-router-dom";

import { PageTitle } from 'components';
import { api } from 'api';
import { getErrorMessage } from 'helpers/feedbackMessageHelper';

import StrategyManager from './strategies/StrategyManager';

import { AdminBreadcrumbs, PlayerPcIdLabel } from './components/';
import { EditableCell, EditableRow } from 'components/FccTable/components';

import useAdmin from "hooks/useAdmin";

const { Paragraph } = Typography;

const EditMatch = () => {

    const [isUpdating, setIsUpdating] = useState(false);
    const [loading, setLoading] = useState(true);
    const [matchGamePlayerData, setMatchGamePlayerData] = useState([]);
    const [matchData, setMatchData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [autocompleteData, setAutocompleteData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const { adminData } = useAdmin();

    const strategyManager = new StrategyManager();
    const strategyMatchData = strategyManager.getMatchDataStrategy(adminData.getSport());

    let params = useParams();

    const calculateColumns = () => {
        const columnsToSet = [{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            onCell: (record) => ({
                record,
                inputType: 'autoComplete',
                dataIndex: 'name',
                required: false,
                editable: true,
                handleSave: savePlayer,
                autocompleteData: autocompleteData
            })
        }];

        strategyMatchData.getFieldsForUserDisplay().forEach(item => {

            const columnItem = {
                title: item.label, // Not Out
                dataIndex: item.name, // notOut
                key: item.name,
            };

            if (item.name === 'runs') {
                columnItem.render = (runs, data)  => {
                    const notOut = !!data.notOut ? '*' : '';
                    return runs + notOut;
                }
            }

            columnsToSet.push(columnItem);
        });

        setColumns(columnsToSet);
    }

    useMemo(() => calculateColumns(), [autocompleteData, matchGamePlayerData, selectedRowKeys]);

    const getMatchData = async () => {
        try {
            const matchData = await api.admin.getMatchData({matchId: params.matchId});

            const { gamePlayers, ...rest } = matchData.data;

            const matchGamePlayerData = gamePlayers.map(gamePlayer => {
                return {
                    ...gamePlayer.matchData,
                    name: gamePlayer?.player?.name,
                    playerId: gamePlayer?.player?.id
                }
            });

            setMatchGamePlayerData(matchGamePlayerData);
            setMatchData(rest);
            
            setLoading(false);
        } catch (error) {
            console.error('ERROR_54', error);
        }
    }

    const getPlayerList = async () => {

        if (! adminData.hasAutoUpdate()) {
            return;
        }

        try {
            const results = await api.player.getPlayerList();
            const data = await results.json();

            let allPlayerData = [];

            data.forEach(position => {
                position.players?.forEach(player => {
                    const playerData = {
                        id: player.id,
                        value: player.id,
                        valueForSearch: player.name,
                        label: <PlayerPcIdLabel playerName={player.name} playerPcId={player.pcId} />
                    }
    
                    allPlayerData.push(playerData);
                });
            });

            setAutocompleteData(allPlayerData);
        } catch (error) {
            console.log('error_849', error);
        }
    }

    const savePlayer = useCallback((editedId, editedData) => {

        setIsUpdating(true);

        // it'll come back as 'name' (cos that's the index)
        const selectedPlayerId = editedData.name;

        // get the name
        const selectedPlayer = autocompleteData.find(player => player.value === selectedPlayerId);

        if (typeof selectedPlayer !== 'undefined') {

            // find gamePlayer
            const gamePlayerToEditIndex = matchGamePlayerData.findIndex(gamePlayer => gamePlayer.id === editedData.id);

            if (gamePlayerToEditIndex > -1) {
                const matchGamePlayerDataToEdit = [...matchGamePlayerData];

                let gamePlayerToEdit = matchGamePlayerData[gamePlayerToEditIndex];
                gamePlayerToEdit['name'] = selectedPlayer.valueForSearch;
                gamePlayerToEdit['playerId'] = selectedPlayerId;

                matchGamePlayerDataToEdit.splice(gamePlayerToEditIndex, 1, gamePlayerToEdit);

                setMatchGamePlayerData(matchGamePlayerDataToEdit);
                
                let updatedSelectedRowKeys = [...selectedRowKeys];

                if (updatedSelectedRowKeys.indexOf(gamePlayerToEdit.id) === -1) {
                    updatedSelectedRowKeys = [...updatedSelectedRowKeys, gamePlayerToEdit.id];
                }
                
                setSelectedRowKeys(updatedSelectedRowKeys);
            }
        }

        setIsUpdating(false);
    });

    const savePendingEdits = async () => {
        setIsUpdating(true);
        const editedRows = matchGamePlayerData.filter(gamePlayer => selectedRowKeys.includes(gamePlayer.id));

        const dataToSend = editedRows.map(editedGamePlayer => {
            return {
                gamePlayerId: editedGamePlayer.id,
                playerId: editedGamePlayer.playerId
            }
        });

        try {
            await api.admin.assignPlayerToMatchData(dataToSend);

            message.success('Players successfully edited');

            setSelectedRowKeys([]);
            setIsUpdating(false);
        } catch (error) {
            console.error('error_74', error);

            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));

            setIsUpdating(false);
        }
    }

    useEffect(() => {
        calculateColumns();
        getPlayerList();
        getMatchData();
    }, []);

    return (
        <>
            <PageTitle title="Edit Match" breadcrumbs={
                <AdminBreadcrumbs 
                    title="Edit Match" 
                    secondLevelTitle="Imported Matches"
                    secondLevelTitleLink="/admin/imported-matches"
                    />
                } 
                />

            {! adminData.hasAutoUpdate() ? (
                <Result 
                    title="Unauthorised Access"
                    subTitle="You do not subscribe to play-cricket integration" 
                    status="warning" 
                    />
            ) : (
                <>
                    <Paragraph>
                        If there's any unknown players in the below match data, you can assign a row to any player from your database. Click in the name field to select to a player.
                    </Paragraph>

                    <Card
                        title={
                            loading ? 
                            <Skeleton 
                                paragraph={false} 
                                active={true} 
                                /> : 
                            `${matchData.homeClub ?? 'Unknown'} vs ${matchData.awayClub ?? 'Unknown'}`
                        }
                        extra={
                            loading ? 
                            <Skeleton 
                                paragraph={false} 
                                active={true} 
                                /> : 
                                matchData?.matchDate ? new Date(matchData.matchDate).toDateString() : null
                        }
                        >

                        <Table 
                            key={isUpdating}
                            components={{
                                body: {
                                    cell: EditableCell,
                                    row: EditableRow
                                },
                            }}
                            loading={loading}
                            rowKey={record => record.id}
                            columns={columns} 
                            dataSource={[...matchGamePlayerData]} 
                            size="middle" 
                            pagination={false} 
                            scroll={{ x: true }}
                            rowClassName={record => selectedRowKeys.includes(record.id) && "ant-table-row-selected"}
                            />
                        <div style={{marginTop: 30 }}>
                            <Affix offsetBottom={32}>
                                <Row justify="end" className="affix-submit-save affix--shadow">
                                    <Button 
                                        type="primary" 
                                        onClick={savePendingEdits} 
                                        disabled={isUpdating || !selectedRowKeys.length}
                                        loading={isUpdating}
                                        >
                                        Save Changes
                                    </Button>
                                </Row>
                            </Affix>
                        </div>
                    </Card>
                </>
            )}
        </>
    )
}

export default EditMatch;