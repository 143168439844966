export default class HockeyPoints {

    calculatePointsForPosition(pointsStructure, formData)
    {
        let total = 0;

        // Match Started
        if (formData.matchStarted) {
            const pointsPerMatchStarted = pointsStructure.structure.filter(structure => structure.name === 'matchStarted')[0]['points'];
            total += pointsPerMatchStarted;
        }

        // Sub Appearance
        if (formData.subAppearance) {
            const pointsPerSubAppearance = pointsStructure.structure.filter(structure => structure.name === 'subAppearance')[0]['points'];
            total += pointsPerSubAppearance;
        }

        // Green Card
        if (formData.greenCard) {
            const pointsPerGreenCard = pointsStructure.structure.filter(structure => structure.name === 'greenCard')[0]['points'];
            total += pointsPerGreenCard;
        }

        // Yellow Card
        if (formData.yellowCard) {
            const pointsPerYellowCard = pointsStructure.structure.filter(structure => structure.name === 'yellowCard')[0]['points'];
            total += pointsPerYellowCard;
        }

        // Red Card
        if (formData.redCard) {
            const pointsPerRedCard = pointsStructure.structure.filter(structure => structure.name === 'redCard')[0]['points'];
            total += pointsPerRedCard;
        }

        // Clean Sheet
        if (formData.cleanSheet) {
            const pointsPerCleanSheet = pointsStructure.structure.filter(structure => structure.name === 'cleanSheet')[0]['points'];
            total += pointsPerCleanSheet;
        }

        // Goals Scored
        const pointsPerGoalScored = pointsStructure.structure.filter(structure => structure.name === 'goalsScored')[0]['points'];
        const goalsScoredPoints = pointsPerGoalScored * formData.goalsScored;
        total += goalsScoredPoints;

        // Assists
        const pointsPerAssist = pointsStructure.structure.filter(structure => structure.name === 'assists')[0]['points'];
        const assistsPoints = pointsPerAssist * formData.assists;
        total += assistsPoints;

        // Goals Conceded
        const pointsPerGoalConceded = pointsStructure.structure.filter(structure => structure.name === 'goalsConceded')[0]['points'];
        const goalsConcededAltered = Math.floor(formData.goalsConceded /2);
        const goalsConcededPoints = pointsPerGoalConceded * goalsConcededAltered;
        total += goalsConcededPoints;

        // Clean Sheet
        if (formData.mom) {
            const pointsPerMom = pointsStructure.structure.filter(structure => structure.name === 'mom')[0]['points'];
            total += pointsPerMom;
        }

        return total;
    }
}