import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Drawer, Button, Space, InputNumber, Form, Switch, message, Divider } from 'antd';
import PropTypes from 'prop-types';

import StrategyManager from '../../strategies/StrategyManager';
import { api } from 'api';
import { EditPlayerMatchDataContext } from './../';
import { getErrorMessage } from 'helpers/feedbackMessageHelper';

import useAdmin from "hooks/useAdmin";

import { reducerActions } from './../reducer';
import PointsHelperStrategyManager from '../helpers/PointsHelperStrategyManager';

const EditMatchData = ({isOpen, handleClose}) => {

    const { state, dispatch } = useContext(EditPlayerMatchDataContext);

    const [form] = Form.useForm();
    const [pointsData, setPointsData] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const { adminData } = useAdmin();

    const strategyManager = new StrategyManager();
    const strategyMatchData = strategyManager.getMatchDataStrategy(adminData.getSport());

    const pointsHelperStrategyManager = new PointsHelperStrategyManager();
    const pointsHelper = pointsHelperStrategyManager.getMatchDataStrategy(adminData.getSport());

    const getPointsStructure = useMemo(() => () => {
        return new Promise(async (resolve, reject) => {

            if (pointsData) {
                resolve(pointsData);
                return;
            };
    
            try {
                const result = await api.user.getRules();
                
                resolve(result.data)
            } catch (error) {
                console.log('ERROR_11');
                reject(error);
            }
        })
    })

    const handlePointsCalculation = async (changedValues, allValues) => {

        if (adminData.sportIsCricket()) {
            // can't have dnb if runs > 0
            if (allValues.runs > 0) {
                form.setFieldsValue({
                    dnb: false
                });
            }

            // can't be notOut if you didn't bat
            if (allValues.dnb && allValues.runs === 0) {
                form.setFieldsValue({
                    notOut: false
                });
            }
        }

        getPointsStructure().then(pointsStructureData => {
            const calculatedTotalPoints = pointsHelper.calculatePointsForPosition(
                pointsStructureData.filter(position => position.positionId === state.playerData.positionId)[0],
                allValues
            );

            setPointsData(pointsStructureData);

            form.setFieldsValue({
                total: calculatedTotalPoints
            });
        });
    }

    const getInitialValues = () => {
        if (!state.gameweekDataBeingEdited) {
            return null;
        }
        const fieldValues = {};
        strategyMatchData.getFields().forEach(item => {
            const dataItem = item.name;
            fieldValues[dataItem] = state.gameweekDataBeingEdited[dataItem];
        });

        fieldValues['total'] = state.gameweekDataBeingEdited.total;

        form.setFieldsValue(fieldValues);
    };

    useEffect(() => {
        getInitialValues();
    }, [state.gameweekDataBeingEdited]);

    const handleSave = async() => {
        setIsSaving(true);
        try {

            const formValues = form.getFieldsValue(true); // true gets all values

            await api.player.editPlayerMatchData(
                state.playerData.id,
                state.gameweekDataBeingEdited.id,
                formValues
            );

            message.success("Match successfully updated.");
            
            dispatch({
                type: reducerActions.updateEditedGameweekData, 
                payload: formValues
            });

            handleClose();

        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));

            console.log('ERROR_12');
        }

        setIsSaving(false);
    }

    return (
        <>
            {state.gameweekDataBeingEdited && (
                <Drawer
                    title={`${state.gameweekDataBeingEdited.matchInfo.homeClub} vs ${state.gameweekDataBeingEdited.matchInfo.awayClub}`}
                    placement="right"
                    onClose={handleClose}
                    open={isOpen}
                    footer={
                        <Space>
                            <Button onClick={handleClose} disabled={isSaving}>Cancel</Button>
                            <Button type="primary" onClick={handleSave} loading={isSaving}>Save</Button>
                        </Space>
                    }
                    >
                    <Form 
                        form={form}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        onValuesChange={handlePointsCalculation}
                        >

                        {strategyMatchData.getFields().map(item => {

                            switch(item.type) {
                                case "number":
                                    return (
                                        <Form.Item key={item.name} label={item.label} name={item.name}>
                                            <InputNumber placeholder={item.label} disabled={isSaving} />
                                        </Form.Item>
                                    );
                                case "checkbox":
                                    return (
                                        <Form.Item key={item.name} label={item.label} name={item.name} valuePropName="checked">
                                            <Switch disabled={isSaving} />
                                        </Form.Item>
                                    );
                            }
                            
                        })}

                        <Divider />

                        <Form.Item label="Total" name="total">
                            <InputNumber placeholder="Total" readOnly />
                        </Form.Item>

                    </Form>
                </Drawer>
            )}
        </>
    )
}

EditMatchData.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default EditMatchData;