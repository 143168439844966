import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown } from 'antd';

import { MoreOutlined } from '@ant-design/icons';

const EditControls = ({
    isUpdating,
    additionalActions
}) => {

    return (
        <>
            {additionalActions && (
                <Dropdown
                    size="small"
                    trigger={['click']}
                    menu={{
                        items: additionalActions,
                    }}
                    loading={isUpdating}
                >
                    <Button size='small' shape='circle' icon={<MoreOutlined />} loading={isUpdating} />
                </Dropdown>
            )}
        </>
    );
}

EditControls.defaultProps = {
    isUpdating: false,
}

EditControls.propTypes = {
    isUpdating: PropTypes.bool,
    additionalActions: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        onClick: PropTypes.func,
        icon: PropTypes.node,
        disabled: PropTypes.bool,
        label: PropTypes.string,
        danger: PropTypes.bool
      }))
}

export default EditControls;

