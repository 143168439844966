import axios from 'axios';
import Cookies from 'js-cookie';
import { buildEndpoint } from 'helpers/apiHelper';

const changeCaptain = async (teamId, newCaptainId) => {

    const endpoint = buildEndpoint(`team/${teamId}/change-captain`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    return axios.patch(
        endpoint, 
        { captain: newCaptainId },
        {
            headers: { Authorization: `Bearer ${token}` }
        });
}

const getTransferStatus = async (teamId) => {

    const endpoint = buildEndpoint(`team/${teamId}/transfer-status`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.get(endpoint, { headers: headers });
}

const setWildcardGameweek = async (teamId, wildcardGameweek) => {

    const endpoint = buildEndpoint(`team/${teamId}/activate-wildcard`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.post(
        endpoint, 
        { gameweek: wildcardGameweek },
        { headers: headers }
    );
}

const getTeamData = async (teamId, gameweek) => {

    const endpoint = buildEndpoint(`team/${teamId}`);
    const token = Cookies.get('__kopek');
    let headers = {};

    if (token) {
        headers = { Authorization: `Bearer ${token}` };
    }

    return axios.get(
        endpoint, 
        { params: { gameweek: gameweek }, headers: headers }
    );
}

const activateTripleCaptain = async (teamId, gameweek) => {

    const endpoint = buildEndpoint(`team/${teamId}/activate-triple-captain`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.post(
        endpoint, 
        { gameweek: gameweek },
        { headers: headers }
    );
}

const makeTransfers = async (teamId, nextPlayerIds) => {

    const endpoint = buildEndpoint(`team/${teamId}/transfers`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.post(
        endpoint, 
        { 
            nextPlayers: nextPlayerIds
        },
        { headers: headers }
    );
}

const getTeamList = async (year) => {
    const endpoint = buildEndpoint(`team/all/${year}`);
    return axios.get(endpoint);
}

const deleteTeam = async (userId) => {

    const endpoint = buildEndpoint(`team/delete-by-user-id/${userId}`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.delete(
        endpoint,
        { headers: headers }
    );
}

const createTeam = async (teamPlayers, teamCaptain, teamName) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.post(
        buildEndpoint('team/create'),
        {
            selectedPlayers: teamPlayers,
            captain: teamCaptain,
            teamName: teamName
        },
        { headers: headers }
    );
}

const changeTeamName = async (teamId, teamName) => {

    const endpoint = buildEndpoint(`team/${teamId}/change-team-name`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.post(
        endpoint,
        {
            teamName: teamName
        },
        { headers: headers }
    );
}

const getArchiveTeamStandingYears = async () => {
    return axios.get(buildEndpoint('team/archive-tables'));
}

const getNetTransfers = async (teamId) => {
    const endpoint = buildEndpoint(`team/${teamId}/net-transfers`);
    return axios.get(endpoint);
}

export { 
    changeCaptain, 
    getTransferStatus, 
    setWildcardGameweek,
    getTeamData,
    activateTripleCaptain,
    makeTransfers,
    getTeamList,
    deleteTeam,
    createTeam,
    getArchiveTeamStandingYears,
    changeTeamName,
    getNetTransfers
};
