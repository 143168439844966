import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';

function UserAvatar({userName, clubLogo}) {

    const workOutUserInitials = () => {

        if (! userName) {
            return;
        }

        var matches = userName.match(/\b(\w)/g); // ['J','S','O','N']
        return matches.join(''); // JSON
    };

    return <Avatar 
        src={clubLogo}
        size={44}
        shape="square"
        gap={8}
        >
            {workOutUserInitials()}
        </Avatar>
};

UserAvatar.propTypes = {
    userName: PropTypes.string,
    clubLogo: PropTypes.string
}

export default memo(UserAvatar);