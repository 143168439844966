import React from 'react';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';

const PlayerPcIdLabel = ({playerName, playerPcId}) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
            >
            {playerName.substr(0, playerName.indexOf(' ')) + ' ' + playerName.substr(playerName.indexOf(' ') +1)}
            <span>
                <UserOutlined /> {playerPcId}
            </span>
        </div>
    );
}

PlayerPcIdLabel.propTypes = {
    playerName: PropTypes.string.isRequired,
    playerPcId: PropTypes.number.isRequired,
}

export default PlayerPcIdLabel;

