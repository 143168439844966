import React, { useState, useReducer, useEffect, useMemo } from 'react';
import { Row, Affix, Button, Steps, message, Modal,  Result, theme } from 'antd';
import { useNavigate, useParams } from "react-router-dom";
import { CaretLeftOutlined, CaretRightOutlined, CheckCircleOutlined, LockOutlined } from '@ant-design/icons';

import { PageTitle, Loading, FccSection } from 'components';
import { api } from 'api';
import { getErrorMessage } from 'helpers/feedbackMessageHelper';

import { reducer, reducerActions, initialState } from './reducer';

import StrategyManager from '../strategies/StrategyManager';

import AdminBreadcrumbs from '../components/AdminBreadcrumbs';
import MatchInfo from './partials/MatchInfo';
import SelectPlayers from './partials/SelectPlayers';
import EnterPoints from './partials/EnterPoints';

import useAdmin from "hooks/useAdmin";

const { Group } = Button;

const { useToken } = theme;

export const ManualImportMatchContext = React.createContext();

const ManualImportMatch = () => {
    
    const { token } = useToken();
    const { adminData } = useAdmin();
    const navigate = useNavigate();

    let params = useParams();

    const [modal, contextHolder] = Modal.useModal();

    const [state, dispatch] = useReducer(reducer, initialState);
    const [loading, setLoading] = useState(true);
    const [stage, setStage] = useState(0);
    const [isEditing, setIsEditing] = useState(false);

    const strategyManager = new StrategyManager();
    const strategyMatchData = strategyManager.getMatchDataStrategy(adminData.getSport());

    const handleResetForm = () => {
        setStage(0);

        dispatch({ 
            type: reducerActions.resetState,
            payload: { gameweek: adminData.getGameweek() }
        });
    }

    const handleSubmit = async () => {

        dispatch({
            type: reducerActions.setIsSaving, 
            payload: { isSaving: true } 
        });

        try {
            await api.admin.importManualMatch({
                playerData: state.selectedPlayers,
                homeTeam: state.matchInfo.homeTeam,
                awayTeam: state.matchInfo.awayTeam,
                matchDate: state.matchInfo.matchDate.format('YYYY-MM-DD'),
                gameweek: state.matchInfo.gameweek,
                matchType: state.matchInfo.matchType,
                matchId: isEditing ? params.matchId : null
            });

            modal.confirm({
                title: 'Success',
                content: isEditing ? 'Match data successfully updated' : 'Match data successfully added',
                okText: 'Admin Home',
                cancelText: 'Import another',
                onOk: () => navigate('/admin'),
                onCancel: handleResetForm,
                icon: <CheckCircleOutlined style={{ color: token.colorSuccess }} />
            });

            dispatch({
                type: reducerActions.setIsSaving, 
                payload: { isSaving: false } 
            });

        } catch (error) {
            console.log('error45', error);
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));

            dispatch({
                type: reducerActions.setIsSaving, 
                payload: { isSaving: false } 
            });
        }
    }

    const getFieldsForPointsEntry = useMemo(() => () => {
        let fields = {};

        strategyMatchData.getFields().forEach(field => {
            const fieldName = field.name;
            fields[fieldName] = field.initialValue
        });

        return fields;
    });

    const loadPlayersAndInit = async () => {
        try {
            const results = await api.player.getPlayerList();
            const data = await results.json();
            
            let playerData = [];

            const fieldsForPointsEntry = getFieldsForPointsEntry();

            data.map(position => {
                position.players.map((player, i) => {

                    const playerWithPointsFields = {
                        ...player, 
                        ...fieldsForPointsEntry
                    }

                    playerData = [...playerData, playerWithPointsFields];
                });
            });

            dispatch({
                type: reducerActions.setPlayerData, 
                payload: playerData 
            });

            await handleIsEditing();

            setLoading(false);

        } catch (error) {
            console.log(error);
        }
        
    };

    const handleIsEditing = async () => {
        if (! params.matchId) {
            return;
        }

        setIsEditing(true);

        try {
            const matchData = await api.admin.getMatchData({matchId: params.matchId});

            dispatch({
                type: reducerActions.setInitialMatchInfo, 
                payload: matchData.data
            });

            const selectedPlayers = matchData.data.gamePlayers.filter(player => !!player.player).map(player => {
                return {
                    ...player.matchData,
                    ...player.player
                }
            });

            dispatch({
                type: reducerActions.selectPlayer, 
                payload: selectedPlayers
            });

        } catch (error) {
            console.error('ERROR_84', error);
        }
    }
    
    useEffect(() => {
        loadPlayersAndInit();
        handleResetForm();
    }, []);

    return (
        <>
            {contextHolder}
            <PageTitle title={isEditing ? 'Edit Match' : 'Add Match'} breadcrumbs={<AdminBreadcrumbs title={isEditing ? 'Edit Match' : 'Add Match'} />} />

            {adminData.seasonHasNotStarted() || adminData.seasonHasEnded() ? (
                <Result 
                    title="Unauthorised Access"
                    subTitle="You cannot enter points until the season has started" 
                    status="warning" 
                    icon={<LockOutlined />}
                />
            ) : (
                <ManualImportMatchContext.Provider value={{ state, dispatch }}>
                    {loading ? <Loading /> : (
                        <>
                            <Steps 
                                current={stage}
                                items={[
                                    { title: 'Match Info' },
                                    { title: 'Select Players' },
                                    { title: 'Enter Match Data' }
                                ]}
                                />
                        
                            <FccSection>
                                {stage === 0 && <MatchInfo />}
                                {stage === 1 && <SelectPlayers />}
                                {stage === 2 && <EnterPoints />}
                            </FccSection>

                            <FccSection>
                                <Affix offsetBottom={32}>
                                    <Row 
                                        type='flex'
                                        justify='end'
                                        className='affix-submit-save affix--shadow'
                                        >
                                        <Group>
                                            <Button 
                                                disabled={stage === 0 || state.isSaving} 
                                                type="primary"
                                                ghost 
                                                onClick={() => setStage(stage - 1)}
                                                >
                                                <CaretLeftOutlined />Back
                                            </Button>

                                            <Button 
                                                disabled={
                                                    (
                                                        (stage === 0 && state.matchInfoSubmitIsDisabled) ||
                                                        (stage === 1 && state.selectPlayersSubmitIsDisabled)
                                                    ) || 
                                                    state.isSaving
                                                }
                                                type="primary" 
                                                onClick={stage === 2 ? handleSubmit : () => setStage(stage + 1)} 
                                                loading={state.isSaving}
                                                >
                                                {stage === 2 ? "Submit" : <>Next <CaretRightOutlined /></>}
                                            </Button>
                                        </Group>
                                    </Row>
                                </Affix>
                            </FccSection>
                        </>
                    )}
                    
                </ManualImportMatchContext.Provider>
            )}
        </>
    )
}

export default ManualImportMatch;