import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";
import { Result, Button } from 'antd';

import MainLayout from './layouts/MainLayout';
import HomePage from './pages/HomePage';
import CreateAccount from './pages/CreateAccount';
import CreateTeam from './pages/CreateTeam/index';
import Team from './pages/Team/index';
import Player from './pages/Player';
import TeamStandings from './pages/TeamStandings';
import PlayerStandings from './pages/PlayerStandings';
import Rules from './pages/Rules/index';
import Transfers from './pages/Transfers';
import ResetPassword from './pages/ResetPassword';
import MyAccount from './pages/MyAccount';

import Admin from './pages/Admin/Home';
import EditPlayers from './pages/Admin/EditPlayers';
import ImportedMatches from './pages/Admin/ImportedMatches';
import ManageUsers from './pages/Admin/ManageUsers/index';
import ImportPlayers from './pages/Admin/ImportPlayers';
import EditPlayerMatchData from './pages/Admin/EditPlayerMatchData/index';
import PointsStructure from './pages/Admin/PointsStructure';
import EditMatch from 'pages/Admin/EditMatch';
import ManualImportMatch from './pages/Admin/ManualImportMatch';

import AdminAuth from './pages/Admin/wrappers/AdminAuth';

import { AppWrapper } from 'wrappers/AppWrapper';

import './App.css';

function App() {

    function NotFound() {
        return (
            <Result
                status="warning"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Link to="/"><Button type="primary">Back Home</Button></Link>}
                />
        );
    }

    return (
        <>
            <Router>
                <AppWrapper>
                    <MainLayout>
                        <Routes>
                            <Route exact path="/" element={<HomePage />} />
                            <Route path="/new-user" element={<CreateAccount />} />
                            <Route path="/create-team" element={<CreateTeam />} />
                            <Route path="/team/:teamId" element={<Team />} />
                            <Route path="/player/:playerId" element={<Player />} />
                            <Route path="/team-standings" element={<TeamStandings />} />
                            <Route path="/player-standings" element={<PlayerStandings />} />
                            <Route path="/rules" element={<Rules />} />
                            <Route path="/transfers" element={<Transfers />} />
                            <Route path="/my-account" element={<MyAccount />} />
                            <Route path="/reset-password/:userId/:newPasswordHash" element={<ResetPassword />} />
                            <Route path="/admin/edit-players" element={<AdminAuth><EditPlayers /></AdminAuth>} />
                            <Route path="/admin/imported-matches" element={<AdminAuth><ImportedMatches /></AdminAuth>} />
                            <Route path="/admin/manage-users" element={<AdminAuth><ManageUsers /></AdminAuth>} />
                            <Route path="/admin/import-players" element={<AdminAuth><ImportPlayers /></AdminAuth>} />
                            <Route path="/admin/edit-player/:playerId" element={<AdminAuth><EditPlayerMatchData /></AdminAuth>} />
                            <Route path="/admin/edit-points/" element={<AdminAuth><PointsStructure /></AdminAuth>} />
                            <Route path="/admin/edit-match-data/:matchId" element={<AdminAuth><EditMatch /></AdminAuth>} />
                            <Route path="/admin/manual-import-match/:matchId?" element={<AdminAuth><ManualImportMatch /></AdminAuth>} />
                            <Route exact path="/admin" element={<AdminAuth><Admin /></AdminAuth>} />
                            <Route path="*" render={<NotFound />}/>
                        </Routes>
                    </MainLayout>
                </AppWrapper>
            </Router>
        </>
    );
}

export default App;
