import dayjs from 'dayjs';

const initialState = {
    selectedPlayers: [],
    playerData: [],
    matchInfo: {
        homeTeam: '',
        awayTeam: '',
        gameweek: null,
        matchDate: dayjs(),
        matchType: 'League'
    },
    matchInfoSubmitIsDisabled: true,
    selectPlayersSubmitIsDisabled: true,
    isSaving: false
}

const reducerActions = {
    selectPlayer: 'SELECT_PLAYER',
    setPlayerData: 'SET_PLAYER_DATA',
    setMatchInfo: 'SET_MATCH_INFO',
    editItem: 'EDIT_ITEM',
    setIsSaving: 'SET_IS_SAVING',
    resetState: 'RESET_STATE',
    setInitialMatchInfo: 'SET_INITAL_MATCH_INFO'
}

function reducer(state, action) {

    switch (action.type) {
        case 'SET_PLAYER_DATA':
            return {
                ...state, 
                playerData: action.payload
            }

        case 'SELECT_PLAYER':

            const selectPlayersSubmitIsDisabled = !action.payload.length;

            return { 
                ...state,
                selectedPlayers: action.payload,
                selectPlayersSubmitIsDisabled: selectPlayersSubmitIsDisabled
            }

        case 'SET_MATCH_INFO':

            let currentMatchInfo = state.matchInfo;
            currentMatchInfo[action.payload.name] = action.payload.newValue;

            const matchInfoSubmitIsDisabled = Array.from(Object.values(currentMatchInfo)).some(item => !item);

            return {
                ...state,
                matchInfo: currentMatchInfo,
                matchInfoSubmitIsDisabled: matchInfoSubmitIsDisabled
            }

        case 'RESET_STATE':
            return {
                ...state,
                matchInfo: {
                    homeTeam: '',
                    awayTeam: '',
                    gameweek: action.payload.gameweek,
                    matchDate: dayjs(),
                    matchType: 'League'
                },
                selectedPlayers: [],
                matchInfoSubmitIsDisabled: true,
                selectPlayersSubmitIsDisabled: true,
            }

        case 'EDIT_ITEM':
            const editedDataToUpdate = state.selectedPlayers;

            const index = editedDataToUpdate.findIndex(item => action.payload.id === item.id);

            if (index > -1) {
                const item = editedDataToUpdate[index];
                const editedRow = { ...item, ...action.payload.editedData };

                editedDataToUpdate.splice(index, 1, editedRow);
            }

            return { 
                ...state,
                selectedPlayers: editedDataToUpdate
            }

        case 'SET_IS_SAVING':
            return { 
                ...state,
                isSaving: action.payload.isSaving
            }

        case 'SET_INITAL_MATCH_INFO':

            const date = new Date(action.payload.matchDate);

            return {
                ...state,
                matchInfoSubmitIsDisabled: false,
                matchInfo: {
                    homeTeam: action.payload.homeClub,
                    awayTeam: action.payload.awayClub,
                    gameweek: action.payload.gameweek,
                    matchDate: dayjs(date.toLocaleString().slice(0, 10), 'DD/MM/YYYY'),
                    matchType: action.payload.matchType,
                },
            }
    }
}

export { reducer, reducerActions, initialState };