import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Select, Modal, message } from 'antd';

import { api } from 'api';
import { getErrorMessage } from 'helpers/feedbackMessageHelper';
import { Loading } from 'components';

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

const AddPlayerToMatch = ({isVisible, handleConfirm, handleCancel, playerId}) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [matchData, setMatchData] = useState(null);

    const getMatchData = async () => {
        try {
            const matches = await api.admin.getMatchesWithoutPlayer(playerId);
            setMatchData(matches.data);
            setLoading(false);
        } catch (error) {
            console.log('ERROR_9');
        }
    }

    useEffect(() => {
        getMatchData();
    }, []);

    const submitValues = async () => {
        form
            .validateFields()
            .then( async (values) => {

                setSubmitting(true);

                const { matchId } = values;

                try {
                    const response = await api.player.addPlayerToMatch({
                        playerId, 
                        matchId
                    });

                    const newMatch = response.data;

                    form.resetFields();
                    handleConfirm(newMatch);

                    // remove match from list
                    const newMatchData = matchData.filter(match => match.id !== matchId);
                    setMatchData(newMatchData);

                    message.success("Player successfully added to match");
                } catch (error) {
                    console.log('ERROR_10');
                    const errorMessage = error.response ? error.response.data.error : 'generic';
                    message.error(getErrorMessage(errorMessage));
                }

                setSubmitting(false);
                
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }
   

    return (
        <Modal 
            title="Add Player To Match"
            open={isVisible}
            onCancel={handleCancel}
            okText="Submit"
            cancelText="Close"
            footer={[
                <Button
                    key="cancel"
                    disabled={submitting}
                    onClick={handleCancel}
                    >
                    Cancel
                </Button>,
                <Button 
                    key="submit" 
                    type="primary" 
                    loading={submitting} 
                    onClick={submitValues}
                    >
                    Submit
                </Button>,
            ]}
            >

            {loading ? <Loading /> : (
                <Form {...layout} form={form}>

                    <Form.Item name="matchId" rules={[{ required: true, message: 'Please select a match' }]} label="Match">
                        <Select placeholder="Match" disabled={loading}>
                            {matchData?.map(match => {
                                const matchDate = new Date(match.matchDate);
                                return (
                                    <Select.Option 
                                        key={match.id}
                                        value={match.id}
                                        >
                                        {`${matchDate.toDateString()} - ${match.homeClub} vs ${match.awayClub}`}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Form>
            )}
        </Modal>
    )
}

AddPlayerToMatch.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    playerId: PropTypes.number.isRequired
}

export default AddPlayerToMatch;