import useAdmin from "hooks/useAdmin";
import { capitalize } from "helpers/textHelper";

// https://stackoverflow.com/questions/46160461/how-do-you-set-the-document-title-in-react
export function useChangePageTitle() {

    const { adminData } = useAdmin();

    const changePageTitle = (newText) => {
        const prevTitle = document.title;
        const capitalizeSportName = capitalize(adminData.getSport());
        
        document.title = `${adminData.getClubName()} Fantasy ${capitalizeSportName} League | ${newText}`;
        return () => {
            document.title = prevTitle;
        }
    }

    return changePageTitle; 
}