const styles = {
    marginTop: {
        marginTop: 30
    },
    selectStat: (isTabletOrMobile) =>  ({
        marginBottom: 16,
        padding: isTabletOrMobile ? 8 : 12
    }),
    selectFilter: {
        width: '100%', 
        marginBottom: 16
    },
    gridStyle: (isTabletOrMobile) => ({
        width: isTabletOrMobile ? '50%' : '25%',
        textAlign: 'center',
        padding: 4
    })
}

export default styles;