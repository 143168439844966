const navigationConsts = {
    HOME: '',
    MY_TEAM: 'my-team',
    TEAM_STANDINGS: 'team-standings',
    PLAYER_STANDINGS: 'player-standings',
    TRANSFERS: 'transfers',
    ADMIN: 'admin',
    NONE: 'none!',
    CREATE_ACCOUNT: 'new-user',
    CREATE_TEAM: 'create-team',
    RULES: 'rules',
    LOGIN: 'login',
    MY_ACCOUNT: 'my-account',
    ADMIN_POINTS_STRUCTURE: 'admin/edit-points',
    ADMIN_ADD_EDIT_PLAYERS: 'admin/edit-players',
    ADMIN_MANAGE_USERS: 'admin/manage-users',
    ADMIN_IMPORTED_MATCHES: 'admin/imported-matches',
    ADMIN_HOME: 'admin',
    ADMIN_IMPORT_PLAYERS: 'admin/import-players',
    ADMIN_MANUAL_IMPORT_MATCH: 'admin/manual-import-match'
}

export default navigationConsts;