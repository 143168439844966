import React, { useState, useContext, useEffect } from 'react';
import { Typography, Row, Col, Form, Input, message, Button, Alert, Switch, Modal, Result } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

import { getErrorMessage } from 'helpers/feedbackMessageHelper';
import { PageTitle } from 'components';
import { api } from 'api';

import { useChangePageTitle } from 'utils/useChangePageTitle';

import useUser from "hooks/useUser";
import useAdmin from "hooks/useAdmin";

const { Paragraph } = Typography;
const { Item } = Form;

const CreateAccount = () => {

    const [modal, contextHolder] = Modal.useModal();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const changePageTitle = useChangePageTitle();
    const navigate = useNavigate();

    const { login } = useUser();
    const { adminData } = useAdmin();

    const handleSubmit = async (values) => {

        setLoading(true);

        try {
            const response = await api.user.createAccount(values);
            login(response.data);

            modal.success({
                title: 'Success',
                content: 'Thank you, you have successfully created an account',
                okText: 'Create Team',
                onOk: () => navigate('/create-team')
            });

            setSuccess(true);
        } catch (error) {
            console.error("error_57", error);
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setLoading(false);
    }

    useEffect( () => {
        changePageTitle('Create Account');
    }, []);

    return (
        <>
        {contextHolder}
            <PageTitle title="Create Account" />

            {success ? (
                <>
                    <Paragraph>
                        Thank you, you have successfully created an account.
                    </Paragraph>
                </>
            ) : (
                <>
                    <Paragraph>
                        To create an account, please fill out the form below. Once an account has been successfully created, you will be able to create a team and compete in the Fantasy League. Please note you can only create one team per email address.
                    </Paragraph>
                    <Form onFinish={(values) => handleSubmit(values)}>
                        <Row type="flex" gutter={{ md: 24}}>
                            <Col xs={24} md={8}>
                                <Item name="name" rules={[{ required: true, message: 'Please input your name' }]}>
                                    <Input
                                        prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        placeholder="Name"
                                        disabled={loading}
                                        />
                                </Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Item name="email" rules={[
                                    { required: true, message: 'Please input your e-mail' },
                                    { type: 'email', message: 'Please supply a valid email address' }
                                ]}>
                                    <Input 
                                        placeholder='Email Address'
                                        prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        disabled={loading}
                                        />
                                </Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Item name="password" rules={[{required: true, message: 'Please input a password'}]}>
                                    <Input.Password
                                        placeholder='Password'
                                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        disabled={loading}
                                        />
                                </Item>
                            </Col>
                            {adminData.sportIsCricket() && (
                                <Col xs={24}>
                                    <Alert 
                                        style={{marginBottom: 16}}
                                        message={
                                            <>
                                                <Typography.Paragraph>
                                                    Choose YES to get information from the England and Wales Cricket Board (ECB) (such as tickets, offers, news, competitions, prize draws and other ways to get involved in cricket).
                                                </Typography.Paragraph>
                                                <Typography.Paragraph>
                                                    If you choose 'YES' Fantasy Club Cricket will provide your registration informaton to the ECB. The ECB's privacy policy which explains how your personal data will be used is available at www.ecb.co.uk/privacy.
                                                </Typography.Paragraph>
                                                <Item name="ecbOptIn" valuePropName="checked" style={{margin: 0}}>
                                                    <Switch
                                                        disabled={loading}
                                                        checkedChildren='YES'
                                                        unCheckedChildren='NO'
                                                        />
                                                </Item>
                                            </>
                                        }
                                        type="info"
                                        />
                                </Col>
                            )}
                            
                        </Row>

                        <Row type='flex' justify='end'>
                            <Button loading={loading} type="primary" htmlType="submit">Submit</Button>
                        </Row>
                    </Form>
                </>
            )}
        </>
    )
}

export default CreateAccount;