import axios from 'axios';
import useSWR, { useSWRConfig } from "swr";

import { buildEndpoint } from 'helpers/apiHelper';
import adminHelper from 'helpers/adminHelper';

const endpoint = buildEndpoint('admin');

function useAdmin() {

    const { mutate } = useSWRConfig();

    const fetcher = async () => {
        const adminResponse = await axios.get(endpoint);
        return adminResponse.data;
    }

    const { data, error, isLoading, mutate: mutateAdminRawData } = useSWR(endpoint, fetcher, { refreshInterval: 60000 }); // 1min

    const adminRefetch = () => {
        mutate(endpoint);
    }

    const mutateAdmin = (newData, revalidate = true) => {
        mutateAdminRawData(
            { ...data, ...newData },
            { revalidate: revalidate }
        );
    }

    return {
        adminData: new adminHelper(data),
        adminLoading: isLoading,
        adminError: error,
        adminRefetch,
        mutateAdmin,
    }
}

export default useAdmin;