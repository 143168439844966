import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, message, Upload, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';

import { api } from 'api';
import { buildEndpoint } from 'helpers/apiHelper';
import { getErrorMessage } from 'helpers/feedbackMessageHelper';

import useAdmin from "hooks/useAdmin";

const UploadLogo = ({isSaving, initialLogo}) => {

    const { adminData, mutateAdmin } = useAdmin();

    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    const getToken = () => {
        const token = Cookies.get('__kopek');
        return token;
    }

    const getDefaultFileList = () => {
        if (!initialLogo) {
            return;
        }

        setFileList([
            {
                uid: -1,
                name: 'Club Logo',
                status: 'done',
                url: initialLogo,
                thumbUrl: initialLogo
            }
        ]);
    }

    const removeFile = async () => {

        try {
            await api.admin.deleteLogo();

            mutateAdmin({ logo: null });

        } catch (error) {
            
        }
    }

    const handlePreview = async (file) => {
        setPreviewImage(file.url);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
      };

    const handleChange = ({file, fileList}) => {
        if (file.status === 'done') {

            mutateAdmin({ logo: file.response['logo-url'] });

            file.name = 'Club Logo';
            file.url = file.response['logo-url'];

            setFileList([file]);

            return;
        }

        if (file.response && file.response.error) {
            message.error(getErrorMessage(`${file.response.error}`));
        }

        setFileList(fileList);
    };

    useEffect(() => {
        getDefaultFileList();
    }, []);

    const uploadLogoProps = {
        name: 'logo',
        action: buildEndpoint('admin/upload-logo'),
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
        accept: ".jpg, .png",
        onChange: handleChange,
        onPreview: handlePreview,
        onRemove: removeFile
    }

    return (
        <>
            <Upload {...uploadLogoProps} fileList={fileList}>
                <Button icon={<UploadOutlined />} disabled={isSaving}>Upload</Button>
            </Upload>

            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
                <img
                    alt="Club Logo"
                    style={{ width: '100%' }}
                    src={previewImage}
                    />
            </Modal>
        </>
    );

}

UploadLogo.defaultProps = {
    isSaving: false,
    initialLogo: null
}

UploadLogo.propTypes = {
    isSaving: PropTypes.bool,
    initialLogo: PropTypes.string
}

export default UploadLogo;