import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Table, Tabs } from 'antd';

import useAdmin from "hooks/useAdmin";
import { Loading } from 'components';

import StrategyManager from 'pages/Admin/strategies/StrategyManager';

const Playerlist = ({tabPosition, size, playerData, isLoading}) => {

    const { adminData } = useAdmin();

    const strategyManager = new StrategyManager();
    const strategyMatchData = strategyManager.getMatchDataStrategy(adminData.getSport(), adminData.hasAutoUpdate());

    const [columns, setColumns] = useState([]);

    const calculateColumns = () => {
        
        let columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => <Link to={`/player/${record.id}`}>{text}</Link>,
                fixed: true
            },
            {
                title: 'Value',
                dataIndex: 'adjustedValue',
                key: 'value',
                render: (text) => (`${adminData.currency()}${text}m`),
                sorter: size !== 'small' ? (a, b) => a.adjustedValue - b.adjustedValue : false,
                width: 100
            }
        ];

        if (size !== 'small') {
            strategyMatchData.getFieldsForUserDisplay().forEach(item => {

                const columnItem = {
                    title: item.labelPlural, // Not Out
                    dataIndex: item.name, // notOut
                    key: item.name,
                    render: (text, record) => record?.summary[item.name] || 0,
                    sorter: size !== 'small' ? (a, b) => a?.summary[item.name] - b?.summary[item.name] : false,
                    sortDirections: ['descend', 'ascend', 'descend']
                };
    
                columns = [...columns, columnItem];
            });
        }

        columns = [
            ...columns,
            {
                title: 'Week Points',
                dataIndex: 'weekPoints',
                key: 'weekPoints',
                render: (text, record) => record.weekPoints ? record.weekPoints : 0,
                sorter: size !== 'small' ? (a, b) => a.weekPoints - b.weekPoints : false,
                sortDirections: ['descend', 'ascend', 'descend']
            },
            {
                title: 'Total Points',
                dataIndex: 'totalPoints',
                key: 'totalPoints',
                render: (text, record) => record.totalPoints ? record.totalPoints : 0,
                sorter: size !== 'small' ? (a, b) => a.totalPoints - b.totalPoints : false,
                sortDirections: ['descend', 'ascend', 'descend']
            }
        ]

        setColumns(columns);
    }

    useEffect(() => {
        calculateColumns();
    }, []);

    if (isLoading) {
        return <Loading />
    }

    return (
        <Tabs 
            defaultActiveKey="1" 
            tabPosition={tabPosition}
            items={playerData.map(position => {
                return {
                    key: position.positionName,
                    label: position.positionName,
                    children: <Table 
                        columns={columns} 
                        dataSource={position.players}
                        rowKey={record => record.id} 
                        size={size} 
                        pagination={false} 
                        scroll={{ x: 1024 }}
                        loading={isLoading}
                        showSorterTooltip={false}
                        sticky={size !== 'small'}
                        />
                }
            })}
            />
    )
}

Playerlist.defaultProps = {
    size: 'middle',
    tabPosition: 'top',
    isLoading: false,
}

Playerlist.propTypes = {
    size: PropTypes.oneOf(['middle', 'small']),
    tabPosition: PropTypes.oneOf(['top', 'left', 'right']),
    playerData: PropTypes.array.isRequired,
    isLoading: PropTypes.bool
}

export default Playerlist;