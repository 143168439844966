import React, { useState, useEffect } from 'react';
import { Button, Row, Modal, Typography, Input, Col, message, Form } from 'antd';
import { LockOutlined, MailOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

import { getErrorMessage } from 'helpers/feedbackMessageHelper';
import { capitalize } from 'helpers/textHelper';

import { api } from 'api';

import useUser from "hooks/useUser";
import useAdmin from "hooks/useAdmin";

const { Paragraph } = Typography;
const { Item } = Form;

const types = {
    FORGOT_PASSWORD: 'forgot-password',
    LOGIN: 'login'
}

function Login({handleCancel, visible}) {

    const [loading, setLoading] = useState(false);
    const [currentType, setCurrentType] = useState(types.LOGIN);

    const { loggedOut, userData, login } = useUser();
    const { adminData } = useAdmin();

    const navigate = useNavigate();

    const handleLogin = async (formValues) => {

        setLoading(true);

        try {
            const response = await api.user.login(formValues.email, formValues.password);

            login(response.data);
            
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
            setLoading(false);
        }
    }

    const handleForgotPassword = async (formValues) => {
        setLoading(true);

        try {
            await api.user.forgotPassword(formValues.forgotEmail);
            message.success('If that email address exists, then instructions for creating a new password has been sent.', 6);
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setLoading(false);
    }

    const handleCreateAccount = () => {
        handleCancel();
        navigate('new-user');
    }

    const modalTitle = () => {
        if (currentType === types.LOGIN) {
            return "Login";
        }
        return (
            <>
                <ArrowLeftOutlined style={{ marginRight: 8 }} onClick={() => setCurrentType(types.LOGIN)} />Forgot Password
            </>
        );
    }

    // if logged in, redirect to team page
    useEffect(() => {
        if (userData && ! loggedOut && visible) {
            handleCancel();
            setLoading(false);
    
            if (userData?.teamId) {
                navigate(`/team/${userData.teamId}`); 
            }
        }
        
    }, [loggedOut, userData]);

    return (
        
        <Modal
            title={modalTitle()}
            open={visible}
            width={320}
            footer={null}
            onCancel={handleCancel}
            >

            <Col xs={24} style={{ textAlign: "center" }}>
                <img 
                    alt={`Fantasy Club ${capitalize(adminData.getSport())} logo`}
                    src={`/images/${adminData.getSport()}/logo--header.png`} 
                    width="180" 
                    style={{ marginBottom: 32 }} 
                    />
            </Col>

            {currentType === types.LOGIN ? (
                <Form onFinish={handleLogin}>
                    <Row type="flex" align="middle" justify="center">
                        
                        <Col xs={24}>
                            <Item 
                                name="email" 
                                rules={[
                                    { required: true, message: 'Please input your e-mail' },
                                    { type: 'email', message: 'Please supply a valid email address' }
                                    ]}
                                >
                                <Input
                                    prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="Email"
                                    disabled={loading}
                                    />
                            </Item>
                        </Col>
                        <Col xs={24}>
                            <Item 
                                name="password" 
                                rules={[{required: true, message: 'Please input a password'}]}
                                >
                                <Input.Password 
                                    placeholder="Password" 
                                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    disabled={loading}
                                    />
                            </Item>
                        </Col>

                        <Col xs={24}>
                            <Button 
                                htmlType="submit"
                                type="primary" 
                                loading={loading} 
                                block
                                >Login
                            </Button>
                        </Col>
                    </Row>
                </Form>
            ) : (
                <Form onFinish={handleForgotPassword}>
                    <Paragraph>
                        Please enter your email address below and we will send you an email with instructions on how to set up new password.
                    </Paragraph>
                    <Item 
                        name="forgotEmail" 
                        rules={[
                            { required: true, message: 'Please input your e-mail' },
                            { type: 'email', message: 'Please supply a valid email address' }
                        ]}>
                        <Input
                            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Email"
                            disabled={loading}
                        />
                    </Item>

                    <Col xs={24}>
                        <Button 
                            htmlType="submit"
                            type="primary" 
                            loading={loading} 
                            block
                            >Submit
                        </Button>
                    </Col>
                </Form>
            )}

            <Row type="flex" justify="end" style={{ marginTop: 24 }}>
                <Button.Group>   
                    <Button 
                        size="small" 
                        style={{fontSize: 10}} 
                        onClick={() => setCurrentType(types.FORGOT_PASSWORD)}
                        >
                        Forgot Password
                    </Button>
                    <Button 
                        size="small" 
                        style={{fontSize: 10}} 
                        onClick={handleCreateAccount}
                        >
                        Create Account
                    </Button>
                </Button.Group>
            </Row>
        </Modal>
    );

}

export default Login;