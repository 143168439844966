import React, { useState, useEffect, useRef } from 'react';
import { message, Button, Switch, Result, Affix, Row, Table, Typography, notification } from 'antd';
import uuid from 'react-uuid';


import { PageTitle, Loading, FccSection } from 'components';

import { api as fccApi } from 'api';
import { getErrorMessage } from 'helpers/feedbackMessageHelper';

import { GetColumnSearchProps, EditableCell, EditableRow } from 'components/FccTable/components';
import AdminBreadcrumbs from './components/AdminBreadcrumbs';

import useAdmin from "hooks/useAdmin";

const { Paragraph } = Typography;

function ImportPlayers() {

    const [api, contextHolder] = notification.useNotification();

    const { adminData } = useAdmin();

    const [loading, setLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [playerData, setPlayerData] = useState([]);
    const [hasSelectedImportPlayers, setHasSelectedImportPlayers] = useState(false);

    const searchInput = useRef(null);

    const importPlayers = async () => {

        setIsUpdating(true);

        const selectedPlayersFromState = playerData.filter(player => player.selected === true);

        if (!selectedPlayersFromState.length) {
            message.error("Nobody selected for import");

            setIsUpdating(false);
            return;
        }

        try {
            await fccApi.player.importMultiplePlayers(selectedPlayersFromState);

            const playerDataToUpdate = playerData;

            // Remove all selected players and delete from list
            selectedPlayersFromState.map(player => {
                const index = playerDataToUpdate.findIndex(item => player.id === item.id);

                if (index > -1) {
                    playerDataToUpdate.splice(index, 1);
                }
            });

            setPlayerData(playerDataToUpdate);
            setHasSelectedImportPlayers(false);

            message.success("Players successfully imported");
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setIsUpdating(false);
    }

    const savePlayer = async (playerId, editedRowData) => {

        setIsUpdating(true);

        const editedDataToUpdate = [...playerData];
        const index = editedDataToUpdate.findIndex(item => playerId == item.id);

        if (index > -1) {

            const item = editedDataToUpdate[index];

            const editedRow = { ...item, ...editedRowData };

            editedDataToUpdate.splice(index, 1, editedRow);
            setPlayerData(editedDataToUpdate);
            setHasSelectedImportPlayers(editedDataToUpdate.filter(player => player.selected).length > 0);
        }

        setIsUpdating(false);
    }

    const toggleImport = (playerId, isToggled) => {
        setIsUpdating(true);

        const playerDataToUpdate = playerData;

        const index = playerDataToUpdate.findIndex(item => playerId == item.id);

        if (index > -1) {

            const item = playerDataToUpdate[index];

            if (item.value === 0) {
                api.warning({
                    message: "Warning",
                    description: 'You must provide a value',
                    key: item.id,
                    duration: 5, // don't close automatically
                });
            }

            const editedRow = { ...item, ...{ selected: isToggled } };

            playerDataToUpdate.splice(index, 1, editedRow);
            setPlayerData(playerDataToUpdate);
            setHasSelectedImportPlayers(playerDataToUpdate.filter(player => player.selected).length > 0);
        }

        setIsUpdating(false);
    }

    const columns = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: (a, b) => a.firstName.localeCompare(b.firstName),
            onCell: (record) => ({
                record,
                inputType: 'text',
                dataIndex: 'firstName',
                editable: true,
                handleSave: savePlayer
            }),
            ...GetColumnSearchProps({
                dataIndex: 'firstName',
                label: 'First Name',
                searchInput: searchInput,
                handleReset: (clearFilters) => handleReset(clearFilters),
            })
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: (a, b) => a.lastName.localeCompare(b.lastName),
            onCell: (record) => ({
                record,
                inputType: 'text',
                dataIndex: 'lastName',
                editable: true,
                handleSave: savePlayer
            }),
            ...GetColumnSearchProps({
                dataIndex: 'lastName',
                label: 'Last Name',
                searchInput: searchInput,
                handleReset: (clearFilters) => handleReset(clearFilters),
            })
        },
        {
            title: 'Play Cricket ID',
            dataIndex: 'pcId',
            key: 'pcId',
            onCell: (record) => ({
                record,
                inputType: 'number',
                dataIndex: 'pcId',
                editable: true,
                handleSave: savePlayer
            }),
        },
        {
            title: 'Position',
            dataIndex: 'positionName',
            key: 'positionName',
            onFilter: (value, record) => record.positionName.indexOf(value) === 0,
            onCell: (record) => ({
                record,
                inputType: 'select',
                dataIndex: 'positionName',
                editable: true,
                handleSave: savePlayer
            }),
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            onCell: (record) => ({
                record,
                inputType: 'number',
                dataIndex: 'value',
                editable: true,
                handleSave: savePlayer
            }),
        },
        {
            title: 'Import',
            dataIndex: 'selected',
            key: 'selected',
            render: (_, record) => {
                return (
                    <Switch 
                        size='small' 
                        id={record.id} 
                        checkedChildren="Import" 
                        unCheckedChildren="Don't Import" 
                        // checked={record.selected} 
                        onChange={(checked) => toggleImport(record.id, checked)}
                        />
                )
            },
        },
    ];

    const getPlayersToImport = async ({forceRefetch}) => {

        if (! adminData.hasAutoUpdate()) {
            setLoading(false);
            return;
        }
        
        try {
            const result = await fccApi.admin.getPlayersToImport({forceRefetch});

            let allPlayerData = [];
            
            result.data.map((player) => {
                const playerData = {
                    firstName: player.name.substr(0, player.name.indexOf(' ')),
                    lastName: player.name.substr(player.name.indexOf(' ') +1),
                    pcId: player.pcId,
                    positionName: adminData.getPositionNames()[0],
                    value: 0,
                    id: uuid()
                }

                allPlayerData.push(playerData);
            });

            setPlayerData(allPlayerData);

        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setLoading(false);
    }

    const handleReset = (clearFilters) => {
        clearFilters();
    };

    const handleRefetchPlayers = () => {
        setLoading(true);
        getPlayersToImport({forceRefetch: true});
    }

    useEffect(() => {
        getPlayersToImport({forceRefetch: false});
    }, []);

    return (
        <>
            {contextHolder}
            <PageTitle 
                title="Import Players"
                breadcrumbs={<AdminBreadcrumbs title="Import Players" />} 
                extra={[
                    <Button 
                        key="refetch"
                        disabled={loading}
                        loading={loading}
                        onClick={handleRefetchPlayers}
                        >
                        Refetch players
                    </Button>
                ]}
                />
            <Paragraph>
                Below is a list of players who have appeared in a Play-Cricket scorecard from last season who have not yet been entered into your player database.
            </Paragraph>
            <Paragraph>
                If you're loading this page for the first time, please be patient - it can take a few minutes.
            </Paragraph>
            <Paragraph>
                Please select the players you wish to import, assign them a position and value and click Submit at the bottom. You can import as many players as you wish from this page.
            </Paragraph>

            {! adminData.hasAutoUpdate() ? (
                <Result 
                    title="Unauthorised Access"
                    subTitle="You do not subscribe to play-cricket integration" 
                    status="warning" 
                    />
            ) : (
                <>
                    {loading ? <Loading /> : (
                        <FccSection>
                            <Table 
                                components={{
                                    body: {
                                        cell: EditableCell,
                                        row: EditableRow
                                    },
                                }}
                                columns={columns} 
                                dataSource={[...playerData]} 
                                rowKey={record => record.id} 
                                pagination={{ pageSize: 50, size: "small" }}
                                bordered
                                scroll={{ x: true }}
                                showSorterTooltip={false}
                                sticky
                                />
                            <div style={{marginTop: 30 }}>
                                <Affix offsetBottom={32}>
                                    <Row justify="end" className="affix-submit-save">
                                        <Button 
                                            type="primary" 
                                            onClick={importPlayers}
                                            disabled={
                                                isUpdating || 
                                                ! hasSelectedImportPlayers
                                            }
                                            loading={isUpdating}
                                            >
                                            Import Selected Players
                                        </Button>
                                    </Row>
                                </Affix>
                            </div>
                        </FccSection>
                    )}
                </>
            )}
        </>
    )
}

export default ImportPlayers;