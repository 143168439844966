import React from 'react';

const styles = {
    container: {
        marginTop: 50
    }
}

const FccSection = (props) => {

    const { children } = props;

    return (
        <div style={styles.container}>
            {children}
        </div>
    )
}

export default FccSection;