export default class CricketMatchData {

    constructor(hasAutoUpdate = false) {
        this.hasAutoUpdate = hasAutoUpdate;
    }

    getPositions()
    {
        return ['Batter', 'Bowler', 'All Rounder', 'Keeper'];
    }

    getFields()
    {
        return [
            {
                name: "runs",
                label: "Runs Scored",
                type: "number",
                initialValue: null,
                selectAll: false
            },
            {
                name: "notOut",
                label: "Not Out",
                type: "checkbox",
                initialValue: false,
                selectAll: false
            },
            {
                name: "dnb",
                label: "Dnb",
                type: "checkbox",
                initialValue: true,
                selectAll: false
            },
            {
                name: "catches",
                label: "Catches",
                type: "number",
                initialValue: null,
                selectAll: false
            },
            {
                name: "runOuts",
                label: "Run Outs",
                type: "number",
                initialValue: null,
                selectAll: false
            },
            {
                name: "stumpings",
                label: "Stumpings",
                type: "number",
                initialValue: null,
                selectAll: false
            },
            {
                name: "wickets",
                label: "Wickets",
                type: "number",
                initialValue: null,
                selectAll: false
            },
            {
                name: "runsAgainst",
                label: "Runs Against",
                type: "number",
                initialValue: null,
                selectAll: false
            },
            {
                name: "maidens",
                label: "Maidens",
                type: "number",
                initialValue: null,
                selectAll: false
            },
        ]
    }

    getFieldsForUserDisplay() {
        let getFieldsForUserDisplay = [
            {
                name: "runs",
                label: "Runs Scored",
                labelPlural: "Runs Scored",
                type: "number"
            },
            {
                name: "catches",
                label: "Catches",
                labelPlural: "Catches",
                type: "number"
            },
            {
                name: "runOuts",
                label: "Run Outs",
                labelPlural: "Run Outs",
                type: "number"
            },
            {
                name: "stumpings",
                label: "Stumpings",
                labelPlural: "Stumpings",
                type: "number"
            },
            {
                name: "wickets",
                label: "Wickets",
                labelPlural: "Wickets",
                type: "number"
            },
            {
                name: "runsAgainst",
                label: "Runs Against",
                labelPlural: "Runs Against",
                type: "number"
            },
            {
                name: "maidens",
                label: "Maidens",
                labelPlural: "Maidens",
                type: "number"
            },
        ];

        return getFieldsForUserDisplay;
    }
}