import React, { useContext, useState, useRef, useEffect } from 'react';
import { Input, Select, InputNumber, Form, AutoComplete, Switch } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

import { EditableContext } from './../components';

import useAdmin from "hooks/useAdmin";

const EditableCell = ({
    editable,
    dataIndex,
    inputType,
    record,
    children,
    handleSave,
    autocompleteData,
    ...restProps
}) => {

    const { adminData } = useAdmin();

    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);

    const form = useContext(EditableContext);

    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);

    const toggleEdit = async () => {
        setEditing(!editing);

        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };

    const handleBlur = async () => {

        try {
            const values = await form.validateFields();

            const editedId = record.id;
            const editedData = {...record, ...values};

            toggleEdit();
            handleSave(editedId, editedData);        
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let width;
    let inputNode;
    let childNode = children;
    
    switch (inputType) {
        case 'number':
            inputNode = <InputNumber 
                onBlur={handleBlur} 
                ref={inputRef} 
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                step={.1}
                style={{ width: 74 }}
                onPressEnter={handleBlur} 
                />;
            width = 74;
            break;
        
        case 'select':
            inputNode = <Select ref={inputRef} onBlur={handleBlur} style={{ width: 140 }}>
                {adminData.getPositions().map(position => {
                    const positionName = position.positionName;
                    return <Select.Option key={positionName} value={positionName}>{positionName}</Select.Option>
                })}
            </Select>;
            width = 140;
            break;

        case 'switch':
            inputNode = <Switch 
                ref={inputRef} 
                onBlur={handleBlur}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                valuePropName="checked"
                />
            width = 45;
            break;

        case 'autoComplete':
            inputNode = <AutoComplete
                ref={inputRef} 
                onSelect={handleBlur}
                onBlur={handleBlur}
                dropdownMatchSelectWidth={320}
                style={{ width: 220 }}
                options={autocompleteData}
                filterOption={(inputValue, option) => {
                    return (
                        option.valueForSearch.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 ||
                        option.value.toString().indexOf(inputValue.toString()) !== -1
                    )
                }}
                >
                <Input.Search placeholder="Input here" />
            </AutoComplete>
            width = 220;
            break;

        default:
            inputNode = <Input 
                onBlur={handleBlur} 
                ref={inputRef} 
                onPressEnter={handleBlur} 
                style={{ width: 140 }}
                />;
            width = 140;
    }

    if (editable)  {
        childNode = editing ? (
            <Form.Item name={dataIndex} style={{ margin: 0 }} initialValue={true}>
                {inputNode}
            </Form.Item>
        ) : (
            <div 
                onClick={toggleEdit} 
                style={{ width }}
                className="editable-cell"
                >
                {children}
            </div>
        )
    }

    return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;