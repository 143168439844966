import React, { useState , useMemo, useEffect } from 'react';
import { Typography, List } from 'antd';

import { api } from 'api';
import { Loading } from 'components';

import useAdmin from "hooks/useAdmin";

const TeamEntryFunds = () => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [rowsForEntryPayment, setRowsForEntryPayment] = useState([]);

    const { adminData } = useAdmin();

    const getEntryFundsSummary = useMemo(() => async () => {

        try {
            const results = await api.admin.getEntryFundsSummary();

            const rowsForEntryPayment = [
                {
                    key: 1,
                    name: 'Teams Entered',
                    value: results.data.teamsEntered,
                },
                {
                    key: 2,
                    name: 'Teams Paid',
                    value: results.data.teamsPaid,
                },
                {
                    key: 3,
                    name: 'Funds',
                    value: `${adminData.currency()}${results.data.totalFundsRaised}`,
                },
                {
                    key: 4,
                    name: 'Processing Fee (15%)',
                    value: `${adminData.currency()}${results.data.adminFee}`
                },
                {
                    key: 5,
                    name: "Total",
                    value: `${adminData.currency()}${results.data.takeHomeAmount}`
                }
            ];

            setRowsForEntryPayment(rowsForEntryPayment);

            setData(results.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    });

    useEffect(() => {
        getEntryFundsSummary();
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <List
            bordered={false}
            dataSource={rowsForEntryPayment}
            renderItem={fundItem => {
                return (
                    <List.Item style={{ padding: '12px 0' }}>
                        <List.Item.Meta title={fundItem.name}  />
                        <Typography.Text>{fundItem.value}</Typography.Text>
                    </List.Item>
                )
            }}
            />
    )
}

export default TeamEntryFunds;