import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Table } from 'antd';

import useUser from "hooks/useUser";
import { capitalize } from 'helpers/textHelper';

const PAGE_SIZE = 30;

const Teamlist = ({size, year, teamlistData, isLoading, sortedValue, months}) => {

    const [columns, setColumns] = useState([]);
    const [myTeamId, setMyTeamId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const { userData, loggedOut } = useUser();

    useEffect(() => {
        const myTeamId = year === 'current' && ! loggedOut ? userData?.teamId : null;
        setMyTeamId(myTeamId);
        calculateColumns('totalPoints');
    }, [year]);

    const calculateColumns = (selectedIndex) => {
        const tableColumns = [
            {
                title: 'Rank',
                key: 'rank',
                render: (text, record, index) => {
                    const currentViewingPage = currentPage -1;
                    return index + 1 + (currentViewingPage*PAGE_SIZE);
                }
            },
            {
                key: 'teamName',
                title: 'Team Name',
                dataIndex: 'name',
                render: (text, record) => {
                    return year === 'current' ? 
                        <Link to={`/team/${record.id}`}>{record.name}</Link> :
                        record.name
                }
            },
            {
                key: 'manager',
                title: 'Manager',
                dataIndex: 'managerName',
                render: (text, record) => record.manager.name,
            },
            {
                key: 'weekTotal',
                title: 'Week Points',
                dataIndex: 'weekPoints',
                sorter: (a, b) => a.weekPoints - b.weekPoints,
                sortOrder: selectedIndex === 'weekPoints' ? 'descend' : false,
                hidden: size === 'small' || selectedIndex !== 'weekPoints',
                sortDirections: ['ascend', 'descend', 'ascend']
            },
            {
                key: 'total',
                title: 'Total Points',
                dataIndex: 'totalPoints',
                sorter: (a, b) => a.totalPoints - b.totalPoints,
                sortOrder: selectedIndex === 'totalPoints' ? 'descend' : false,
                hidden: selectedIndex !== 'totalPoints',
                sortDirections: ['ascend', 'descend', 'ascend']
            }
        ];

        if (size !== 'small') {
            months?.forEach(month => {
                tableColumns.push({
                    key: month,
                    title: capitalize(month) + ' Points',
                    dataIndex: month,
                    sorter: (a, b) => a[month] - b[month],
                    sortOrder: selectedIndex === month ? 'descend' : false,
                    hidden: selectedIndex !== month,
                    sortDirections: ['ascend', 'descend', 'ascend']
                });
            })
        }

        setColumns(tableColumns.filter(item => !item.hidden));
    }

    useMemo(() => calculateColumns(sortedValue), [sortedValue, currentPage]);

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
    }

    return (
            
        <Table 
            className="hide-table-sort"
            columns={columns} 
            dataSource={[...teamlistData]} 
            size={size} 
            pagination={size !== 'small' && { pageSize: PAGE_SIZE, onChange: handlePageChange }}
            rowKey={record => record.id}  
            loading={isLoading}
            rowClassName={record => record.id === myTeamId && "ant-table-row-selected"}
            showSorterTooltip={false}
            sticky={size !== 'small'}
            />
    )
}

Teamlist.defaultProps = {
    size: 'middle',
    year: 'current',
    isLoading: true,
    sortedValue: 'overallTotal',
    months: []
}

Teamlist.propTypes = {
    size: PropTypes.oneOf(['middle', 'small']),
    year: PropTypes.oneOf(['current', '2019', '2020', '2021', '2022']),
    teamlistData: PropTypes.array, 
    isLoading: PropTypes.bool,
    sortedValue: PropTypes.string,
    months: PropTypes.array
}

export default Teamlist;