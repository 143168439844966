import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Breadcrumb } from 'antd';

import useNavigation from "hooks/useNavigation";

import styles from './../styles';

const AdminBreadcrumbs = ({title, secondLevelTitle, secondLevelTitleLink}) => {

    const [activeNavItem, { getAdminMenu }] = useNavigation();

    return (
        <Breadcrumb style={styles.breadcrumb}>
            <Breadcrumb.Item 
                dropdownProps={{ trigger: 'click' }} 
                menu={{ 
                    items: getAdminMenu({}), 
                    selectedKeys: activeNavItem 
                }}
                >
                <a href="">Admin</a>
            </Breadcrumb.Item>
            {secondLevelTitle && (
                <Breadcrumb.Item>
                    <Link to={secondLevelTitleLink}>{secondLevelTitle}</Link>
                </Breadcrumb.Item>
            )}
            <Breadcrumb.Item>{title}</Breadcrumb.Item>
        </Breadcrumb>
    )
}

AdminBreadcrumbs.defaultProps = {
    secondLevelTitle: null,
    secondLevelTitleLink: "/admin"
}

AdminBreadcrumbs.propTypes = {
    title: PropTypes.string.isRequired,
    secondLevelTitle: PropTypes.string,
    secondLevelTitleLink: PropTypes.string
}

export default AdminBreadcrumbs;