import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Form, Select, InputNumber, Modal, message, Tabs, List, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import useAdmin from "hooks/useAdmin";

import { api } from 'api';
import { getErrorMessage } from 'helpers/feedbackMessageHelper';

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

const PointsDeduction = ({
    isVisible, 
    handleCancel, 
    itemBeingEdited, 
    handleUpdateUserListData, 
    handleDeleteDeductionFromUserListData
}) => {

    const [activeTabKey, setActiveTabKey] = useState(1);

    const { adminData } = useAdmin();

    const gameweeks = Array.from(Array(adminData.getGameweek()), (_, i) => i+1);

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const submitValues = async () => {
        form
            .validateFields()
            .then( async (values) => {

                setLoading(true);

                const { addOrDeduct, reason, amount, gameweek } = values;

                const teamId = itemBeingEdited.team.id;

                try {
                    const response = await api.admin.addPointsDeduction({
                        addOrDeduct, 
                        reason,
                        amount, 
                        gameweek, 
                        teamId
                    });

                    const addOrDeductVerb = addOrDeduct == 'add' ? 'Addition' : 'Deduction';

                    // add to existing userData
                    handleUpdateUserListData(response.data);

                    message.success(`${addOrDeductVerb} successfully applied.`);
                    setLoading(false);
                    form.resetFields();

                } catch (error) {
                    console.log('error', error);
                    const errorMessage = error.response ? error.response.data.error : 'generic';
                    message.error(getErrorMessage(errorMessage));
                    setLoading(false);
                }
                
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    const handleDeleteDeduction = async(deductionId) => {
        setLoading(true);

        try {
            await api.admin.deletePointsDeduction(deductionId);

            handleDeleteDeductionFromUserListData(deductionId);

            message.success("Successfully deleted.");
            setLoading(false);

        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
            setLoading(false);
        }
    }

    const changeActiveTabKey = (tabKey) => {
        setActiveTabKey(tabKey);
    }

    return (        
            <Modal 
                title="Points Deductions/Additions"
                open={isVisible}
                onCancel={handleCancel}
                okText="Submit"
                cancelText="Close"
                footer={activeTabKey === 1 && [
                    <Button
                        key="cancel"
                        disabled={loading}
                        onClick={handleCancel}
                        >
                        Cancel
                    </Button>,
                    <Button 
                        key="submit" 
                        type="primary" 
                        loading={loading} 
                        onClick={submitValues}
                        >
                        Submit
                    </Button>,
                ]}
                >

                <Tabs
                    defaultActiveKey="1" 
                    onChange={changeActiveTabKey}
                    items={[
                        {
                            label: 'Add Points Deduction',
                            key: '1',
                            children: 
                                <Form layout='vertical' form={form}>
                                    <Form.Item 
                                        name="addOrDeduct" 
                                        rules={[{ required: true, message: 'Please select' }]}
                                        label="Add or Deduct"
                                        >
                                        <Select placeholder="Add or Deduct" disabled={loading}>
                                            <Select.Option value="add">Add</Select.Option>
                                            <Select.Option value="deduct">Deduct</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="amount" rules={[{ required: true, message: 'Please select an amount' }]} label="Amount">
                                        <InputNumber 
                                            placeholder="Value"
                                            style={{width: '100%'}}
                                            // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            step={1} 
                                            disabled={loading}
                                            />
                                    </Form.Item>
                                    <Form.Item name="gameweek" rules={[{ required: true, message: 'Please select a gameweek' }]} label="Gameweek">
                                        <Select placeholder="Gameweek" disabled={loading}>
                                            {gameweeks.map(number => (
                                                <Select.Option key={number} value={number}>Gameweek {number}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="reason" rules={[{ required: true, message: 'Please give the reason' }]} label="Reason">
                                        <Input.TextArea placeholder="Reason" disabled={loading} />
                                    </Form.Item>
                                </Form>
                        },
                        {
                            label: 'Existing Points Deductions',
                            key: '2',
                            children:
                                <List
                                    itemLayout="horizontal"
                                    dataSource={
                                        itemBeingEdited?.team?.deductions ? 
                                        itemBeingEdited.team.deductions
                                        : []
                                    }
                                    renderItem={deduction => {

                                        return (
                                            <List.Item
                                                style={{ padding: '12px 0' }}
                                                key={deduction.id}
                                                actions={[
                                                    <div>{`Gameweek ${deduction.gameweek}`}</div>,
                                                    <div>
                                                        <Popconfirm 
                                                            title="Are you sure you want to delete this?"
                                                            okText="Yes" 
                                                            cancelText="No" 
                                                            onConfirm={() => handleDeleteDeduction(deduction.id)}
                                                            >
                                                            <Button 
                                                                size="small" 
                                                                type="primary"
                                                                shape="circle" 
                                                                icon={<DeleteOutlined />}
                                                                danger
                                                                />
                                                        </Popconfirm>
                                                    </div>
                                                ]}
                                                >
                                                <List.Item.Meta
                                                    title={`${deduction.amount} points`}
                                                    description={deduction.reason}
                                                />
                                            </List.Item>
                                        )
                                    }}
                                />
                        }
                    ]}
                    >
                </Tabs>
                
            </Modal>
    )
}

PointsDeduction.defaultProps = {
    itemBeingEdited: {}
}

PointsDeduction.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    itemBeingEdited: PropTypes.object,
    handleUpdateUserListData: PropTypes.func.isRequired,
    handleDeleteDeductionFromUserListData: PropTypes.func.isRequired
}

export default PointsDeduction;