import React, { useState, useEffect, useRef } from 'react';
import {  message, Tooltip, Switch, Table, Modal } from 'antd';
import { WalletOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';

import { PageTitle, Loading } from 'components';
import { api } from 'api';
import { getErrorMessage } from 'helpers/feedbackMessageHelper';

import { EditControls, EditableCell, EditableRow, GetColumnSearchProps } from 'components/FccTable/components';
import { AdminBreadcrumbs }  from './../components';

import PointsDeduction from './partials/PointsDeduction';

import useUser from "hooks/useUser";

function ManageUsers() {

    const [modal, contextHolder] = Modal.useModal();

    const [loading, setLoading] = useState(true);
    const [pointsDeductionModalIsVisible, setPointsDeductionModalIsVisible] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [userListData, setUserListData] = useState([]);
    const [itemBeingEdited, setItemBeingEdited] = useState(null);

    const { userData } = useUser();

    const searchInput = useRef(null);

    const getUsers = async () => {
        try {
            const result = await api.admin.getUsers();

            result.data.map(user => {
                user['teamName'] = user.team ? user.team.name : null;
            });

            setUserListData(result.data);

        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));

            setLoading(false);
        }

        setLoading(false);
    }

    const saveUser = async (userId, editedRowData) => {

        setIsUpdating(true);

        try {
            editedRowData.selected = true;

            const editedDataToUpdate = [...userListData];

            const index = editedDataToUpdate.findIndex(item => userId === item.id);

            if (index > -1) {
                const item = editedDataToUpdate[index];
                const editedRow = { ...item, ...editedRowData };

                editedDataToUpdate.splice(index, 1, editedRow);
            }

            setUserListData(editedDataToUpdate);

            await api.admin.updateUser(userId, editedRowData);

            message.success("User successfully updated");
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setIsUpdating(false);
    }

    const deleteUserTeam = async (userId) => {

        setIsUpdating(true);

        try {
            const deletedDataToUpdate = userListData;

            const index = deletedDataToUpdate.findIndex(item => userId === item.id);

            if (index > -1) {
                deletedDataToUpdate.splice(index, 1);
            }

            setUserListData(deletedDataToUpdate);

            await api.team.deleteTeam(userId);

            message.success("Team successfully deleted.");
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setIsUpdating(false);
    };

    const handleDeleteModal = async (userId) => {
        modal.confirm({
            title: 'Are you sure you want to delete this team?',
            icon: <ExclamationCircleFilled />,
            onOk: () => deleteUserTeam(userId),
            okType: 'danger'
        });
    }

    const handleMakeAdmin = async (userId, isAdmin) => {

        if (userId === userData.id) {
            message.warning("Cannot toggle your own privileges.");
            return;
        }

        setIsUpdating(true);

        try {
            await api.admin.updateUser(userId, { admin: isAdmin });

            const editedDataToUpdate = [...userListData];

            const index = editedDataToUpdate.findIndex(item => userId === item.id);

            if (index > -1) {
                const item = editedDataToUpdate[index];
                const editedRow = { ...item, ...{ admin: isAdmin } };

                editedDataToUpdate.splice(index, 1, editedRow);
            }

            setUserListData(editedDataToUpdate);

            message.success("User successfully updated.");
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setIsUpdating(false);
    }

    const applyPointsDeduction = (item) => {
        setPointsDeductionModalIsVisible(true);

        setItemBeingEdited(item);
    }

    const handleUpdateUserListData = (responseData) => {
        const itemBeingEditedUpdated = itemBeingEdited;
        itemBeingEditedUpdated.team.deductions.push(responseData);

        setItemBeingEdited(itemBeingEditedUpdated);

        const editedDataToUpdate = [...userListData];

        const index = editedDataToUpdate.findIndex(item => responseData.id == item.id);

        if (index > -1) {
            const item = editedDataToUpdate[index];
            const editedRow = { ...item, ...responseData };

            editedDataToUpdate.splice(index, 1, editedRow);
        }

        setUserListData(editedDataToUpdate);
    }

    const handleDeleteDeductionFromUserListData = (deductionId) => {
        const dataToUpdate = userListData;

        const userIndex = dataToUpdate.findIndex(item => itemBeingEdited.id === item.id);
        const deductionIndex = dataToUpdate[userIndex]['team']['deductions'].findIndex(item => deductionId === item.id);

        if (deductionIndex > -1) {
            dataToUpdate[userIndex]['team']['deductions'].splice(deductionIndex, 1);
        }

        setUserListData(dataToUpdate);
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ['ascend', 'descend', 'ascend'],
            onCell: (record) => ({
                record,
                dataIndex: 'name',
                editable: false,
            }),
            ...GetColumnSearchProps({
                dataIndex: 'name',
                label: 'Name',
                searchInput: searchInput,
                handleReset: (clearFilters) => handleReset(clearFilters),
            }),
            shouldCellUpdate: () => false
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
            sortDirections: ['ascend', 'descend', 'ascend'],
            onCell: (record) => ({
                record,
                dataIndex: 'email',
                editable: false
            }),
            shouldCellUpdate: () => false
        },
        {
            title: 'Team Name',
            dataIndex: 'teamName',
            key: 'teamName',
            render: (_, record) => {
                return record.teamName ? record.teamName : "** No Team Created **";
            },
            onCell: (record) => ({
                record,
                inputType: 'text',
                dataIndex: 'teamName',
                required: false,
                editable: record.teamName,
                handleSave: saveUser
            })
        },
        {
            title: 'Is Admin',
            dataIndex: 'admin',
            key: 'admin',
            render: (_, record) => {
                return (
                    <>
                        <Tooltip title="Toggle to give admin privileges">
                            <Switch 
                                size="small" 
                                checkedChildren="Admin" 
                                unCheckedChildren="Not Admin" 
                                defaultChecked={record.admin}
                                onChange={() => handleMakeAdmin(record.id, !record.admin)}
                                disabled={record.id === userData.id || isUpdating}
                                />
                        </Tooltip>
                    </>
                );
            }
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            render: (_, record) => {
                return (
                    <EditControls 
                        isUpdating={isUpdating}
                        additionalActions={[
                            {
                                title: 'Apply Points Deduction',
                                label: 'Apply Points Deduction',
                                icon: <WalletOutlined />,
                                disabled: ! record.team || isUpdating,
                                onClick: () => applyPointsDeduction(record),
                            },
                            {
                                title: 'Delete Team',
                                label: 'Delete Team',
                                icon: <DeleteOutlined />,
                                onClick: () => handleDeleteModal(record.id),
                                danger: true,
                                disabled: ! record.team
                            }
                        ]}
                        />
                )
                
            },
            onCell: () => ({
                editable: false
            })
        }
    ];

    const handleReset = (clearFilters) => {
        clearFilters();
    };

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <>
            {contextHolder}
            <PageTitle title="Manage Users" breadcrumbs={<AdminBreadcrumbs title="Manage Users" />} />

            {loading ? <Loading /> : (
                <>
                    <Table 
                        components={{
                            body: {
                                cell: EditableCell,
                                row: EditableRow
                            },
                        }}
                        columns={columns} 
                        dataSource={[...userListData]} 
                        rowKey={record => record.id} 
                        pagination={{ pageSize: 20, size: "small" }}
                        bordered
                        scroll={{ x: true }}
                        showSorterTooltip={false}
                        loading={isUpdating}
                        sticky
                        />
                    <PointsDeduction
                        isVisible={pointsDeductionModalIsVisible} 
                        handleCancel={() => setPointsDeductionModalIsVisible(false)}
                        itemBeingEdited={itemBeingEdited}
                        handleDeleteDeductionFromUserListData={handleDeleteDeductionFromUserListData}
                        handleUpdateUserListData={handleUpdateUserListData}
                    />
                </>
            )}
        </>
    )
}

export default ManageUsers;