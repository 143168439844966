import React, { useContext } from 'react';
import { Row, Col, Input } from 'antd';
import { TagOutlined } from '@ant-design/icons';

import { CreateTeamContext } from '../../CreateTeam';
import { reducerActions } from '../reducer';

const TeamName = () => {

    const { state, dispatch } = useContext(CreateTeamContext);

    const handleChange = (e) => {

        let teamName = e.target.value;
        dispatch({
            type: reducerActions.setTeamName, 
            payload: teamName
        });
    }

    return (
        <Row type="flex"align="middle">
            <Col xs={24}>
                <Input
                    value={state.teamName}
                    prefix={<TagOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Team Name"
                    onChange={handleChange}
                    status={!state.teamName.length ? "error" : "none"}
                    />
            </Col>
        </Row>
    )
}

export default TeamName;